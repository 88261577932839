import { combineReducers } from "redux";
import {
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview
} from "./image";
import {
  fullPageSpinnerActive,
  notificationActive,
  confirmationPopupActive
} from "./toggle";

import { companies, company } from "./company";
import { authUser, user, users } from "./user";
import { articles, article } from "./article";
import { highlights, highlight } from "./highlight";
import { appSettings, appSetting } from "./appSetting";
import { variantOptions, variantOption } from "./variantOption";
import {
  productOrders,
  productOrder,
  productOrderSummaryByStatus
} from "./productOrder";
import { promotionTypes, promotionType } from "./promotionType";
import { highlightActions, highlightAction } from "./highlightAction";
import { companyProfileTypes, companyProfileType } from "./companyProfileType";
import { productStatuses, productStatus } from "./productStatus";
import { orderStatuses, orderStatus } from "./orderStatus";
import { contacts, contact } from "./contact";
import { products, product } from "./product";
import { parentProducts, parentProduct } from "./parentProduct";
import { customers, customer } from "./customer";
import { customerTiers, customerTier } from "./customerTier";
import { roles } from "./role";
import { category, categories } from "./category";
import { delivery, deliveries } from "./delivery";
import { productCategory, productCategories } from "./productCategory";
import { paymentGateway, paymentGateways } from "./paymentGateway";
import { currency, currencies } from "./currency";
import { tag, tags, createTag, detachTag } from "./tag";
import { filterTag, filterTags, createFilterTag } from "./filterTag";
import { group, groups, createGroup } from "./group";
import { about } from "./about";
import { otpCount } from "./otp";
import { setting } from "./setting";

export default combineReducers({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  fullPageSpinnerActive,
  notificationActive,
  confirmationPopupActive,
  companies,
  company,
  authUser,
  user,
  users,
  articles,
  article,
  appSettings,
  appSetting,
  roles,
  category,
  categories,
  tag,
  tags,
  delivery,
  deliveries,
  currency,
  currencies,
  customerTier,
  customerTiers,
  createTag,
  detachTag,
  filterTag,
  filterTags,
  createFilterTag,
  about,
  setting,
  contacts,
  contact,
  customers,
  customer,
  products,
  product,
  parentProducts,
  parentProduct,
  otpCount,
  highlights,
  highlight,
  productOrders,
  productOrder,
  productCategory,
  productCategories,
  productOrderSummaryByStatus,
  promotionTypes,
  promotionType,
  companyProfileTypes,
  companyProfileType,
  productStatuses,
  productStatus,
  orderStatuses,
  orderStatus,
  group,
  groups,
  createGroup,
  highlightActions,
  highlightAction,
  variantOption,
  variantOptions,
  paymentGateway,
  paymentGateways
});
