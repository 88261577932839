export const FETCH_AUTH_USER = "FETCH_AUTH_USER";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USERS = "FETCH_USERS";
export const DELETE_USER = "DELETE_USER";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";

export function fetchAuthUser(params = {}) {
  params = Object.assign({}, { partials: `permissions` }, params);
  return {
    type: FETCH_AUTH_USER,
    auth: true,
    payload: {
      url: `resource/me`,
      method: "GET",
      params
    }
  };
}

export function fetchUser(id, params = {}) {
  params = Object.assign({}, { partials: "companies(children)" }, params);
  return {
    type: FETCH_USER,
    auth: true,
    payload: {
      url: `resource/users/${id}`,
      method: "GET",
      params
    }
  };
}

export function fetchUsers(params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_USERS,
    auth: true,
    payload: {
      url: "resource/users",
      method: "GET",
      params
    }
  };
}

export function deleteUser(id, data = {}) {
  return {
    type: DELETE_USER,
    auth: true,
    payload: {
      url: `resource/users/${id}`,
      method: "DELETE",
      data
    }
  };
}

export function createUser(data = {}) {
  return {
    type: CREATE_USER,
    auth: true,
    payload: {
      url: "resource/users",
      method: "POST",
      data
    }
  };
}

export function updateUser(id, data = {}) {
  return {
    type: UPDATE_USER,
    auth: true,
    payload: {
      url: `resource/users/${id}`,
      method: "PUT",
      data
    }
  };
}
