/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import isEmpty from "lodash.isempty";

const CurrenciesTable = ({ currencies, companyURL, onClickDelete }) => {
  const TableOrNotFound = () => {
    if (currencies.loading) {
      return "is Loading";
    }
    if (isEmpty(currencies.data)) {
      return (
        <p className="is-size-2 has-text-centered has-text-grey">
          Found Nothing
        </p>
      );
    }
    return (
      <table className="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Price</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {currencies.data.map(currency => (
            <tr key={currency.id}>
              <td>{currency.id}</td>
              <td>{currency.name}</td>
              <td>{currency.price}</td>

              <td className="is-icon">
                <p className="buttons">
                  <Link
                    to={`/${companyURL}/currencies/${currency.id}/edit`}
                    className="button  is-link is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-edit" />
                    </span>
                    <span>Edit</span>
                  </Link>
                  <a
                    onClick={onClickDelete(currency.id)}
                    to={`/${companyURL}/currencies/${currency.id}/delete`}
                    className="button is-danger is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-trash" />
                    </span>
                    <span>Delete</span>
                  </a>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <section className="section">
      <TableOrNotFound />
    </section>
  );
};
export default CurrenciesTable;
