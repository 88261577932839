import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchContacts, deleteContact } from "actions/contact";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Table from "./Table";
import Pagination from "components/Pagination";
import { InstantSearch, SearchBox, PoweredBy } from "components/algolia-dom";
import MagicConnector from "components/algolia-dom/MagicConnector";
import HitComponent from "./HitComponent";
import ListTable from "components/ListTable";

class ContactIndex extends Component {
  excludeRecords = [];
  refreshSearch = false;

  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchContacts({ company_url });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchContacts({ company_url, page });
  };

  handleContactDelete = id => event => {
    this.refreshSearch = false;
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      onClickConfirm: () => {
        this.deleteContactProcess(id);
      }
    });
  };

  deleteContactProcess(id) {
    const {
      toggleNotification,
      toggleconfirmationPopup,
      deleteContact,
      match
    } = this.props;
    const { companyURL: company_url } = match.params;
    deleteContact(id, { company_url })
      .then(() => {
        this.props.fetchContacts({ company_url });
        this.excludeRecords.push(id);
        this.refreshSearch = true;
        toggleNotification(true, {
          infoClassName: "is-success",
          message: "Delete Contact Success"
        });
        toggleconfirmationPopup(false, {});
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
        toggleconfirmationPopup(false, {});
      });
  }

  render() {
    const { contacts, setting, match } = this.props;
    const { companyURL } = match.params;

    return (
      <div>
        <div className="box">
          <ListTable
            onPaginationClick={this.onPaginationClick}
            data={contacts}
          >
            <Table
              contacts={contacts}
              companyURL={companyURL}
              onClickDelete={this.handleContactDelete}
            />
          </ListTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  authUser,
  contacts,
  confirmationPopupActive,
  setting
}) => ({
  authUser,
  contacts,
  confirmationPopupActive,
  setting
});

export default connect(mapStateToProps, {
  fetchContacts,
  deleteContact,
  toggleconfirmationPopup,
  toggleNotification
})(ContactIndex);
