import React, { Component } from "react";

import Nav from "./Nav";
import Breadcrumb from "./Breadcrumb";
import Sidebar from "./Sidebar";
import FullPageSpinner from "./FullPageSpinner";
import ConfirmationPopup from "./ConfirmationPopup";
import Notification from "components/Notification";

class DashboardLayout extends Component {
  state = {
    hideSidebar: false,
    hideProfileBox: true
  };

  handleToggleClick = event => item => {
    this.setState({
      [item]: !this.state[item]
    });
  };

  render() {
    const {
      match,
      children,
      className,
      authUser,
      setting,
      activeCompany,
      fullPageSpinnerActive,
      confirmationPopupActive,
      notificationActive,
      onClickNotificationDelete,
      onClickConfirmationPopupCancel
    } = this.props;

    console.log(activeCompany)
    const { hideSidebar, hideProfileBox } = this.state;

    return (
      <div className={className}>
        <FullPageSpinner active={fullPageSpinnerActive} />
        <ConfirmationPopup
          {...confirmationPopupActive}
          onClickCancel={onClickConfirmationPopupCancel}
        />
        <Notification
          {...notificationActive}
          onClickDelete={onClickNotificationDelete}
        />
        <Nav
          handleToggleClick={this.handleToggleClick}
          hideProfileBox={hideProfileBox}
          authUser={authUser}
          activeCompany={activeCompany}
          handleCompanyLinkClick={this.handleCompanyLinkClick}
        />
        <div className="columns">
          <Sidebar
            activeCompany={activeCompany}
            setting={setting}
            hide={hideSidebar}
            className="column is-one-fifth"
          />
          <div className="column">
            <section className="section">
              <Breadcrumb url={match.url} />
              {children}
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardLayout;
