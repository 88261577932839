import React, { useCallback, useState, memo } from "react";
import { useDropzone } from "react-dropzone";
import { importProducts } from "api/importProducts";
import ProcessingIcon from "./ProcessingIcon";

export default memo(({ icon, params, afterImported }) => {
  const [processing, setProcessing] = useState(false);
  const [processorId, setProcessorId] = useState(0);

  const onDrop = useCallback(acceptedFiles => {
    setProcessing(true);
    importProducts(acceptedFiles[0], params).then(r => {
      setProcessorId(r.data.id);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <a className={`button`} {...getRootProps()}>
      {processing ? (
        <ProcessingIcon
          processorId={processorId}
          setProcessorId={setProcessorId}
          params={params}
          processing={processing}
          setProcessing={setProcessing}
          callback={afterImported}
        />
      ) : (
        <span className="icon">
          <i className={`fas ${icon}`} />
        </span>
      )}
      <p>Import</p>
      <input {...getInputProps()} />
    </a>
  );
});
