export const TOGGLE_FULL_PAGE_SPINNER = "TOGGLE_FULL_PAGE_SPINNER";
export const TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION";
export const TOGGLE_CONFIRMATION_POPUP = "TOGGLE_CONFIRMATION_POPUP";

export function toggleFullPageSpinner(flag) {
  return {
    type: TOGGLE_FULL_PAGE_SPINNER,
    flag
  };
}

export function toggleNotification(flag, meta = {}) {
  return {
    type: TOGGLE_NOTIFICATION,
    flag,
    meta
  };
}

export function toggleconfirmationPopup(flag, meta = {}) {
  return {
    type: TOGGLE_CONFIRMATION_POPUP,
    flag,
    meta
  };
}
