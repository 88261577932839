import React, { memo } from "react";
export default memo(({ currentPage, onClick, lastPage }) => (
  <a
    {...{
      className: "pagination-next",
      disabled: currentPage >= lastPage,
      onClick: currentPage >= lastPage ? undefined : onClick(++currentPage)
    }}
  >
    Next page
  </a>
));
