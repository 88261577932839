export const FETCH_ROLES = "FETCH_ROLES";

export function fetchRoles(params = {}) {
  params = Object.assign({}, {}, params);
  return {
    type: FETCH_ROLES,
    auth: true,
    payload: {
      url: "resource/roles/all",
      method: "GET",
      params
    }
  };
}
