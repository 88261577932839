import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  UPLOAD_IMAGE,
  INCREASE_UPLOADED_PERCENTAGE,
  SET_PREVIEW_IMAGE,
  REMOVE_UPLOADED_IMAGE
} from "actions/image";

export function uploadedImages(state = {}, { type, payload, meta }) {
  switch (type) {
    case `${UPLOAD_IMAGE}_${PENDING}`:
      return Object.assign({}, state, {
        [meta.name]: {
          loading: true,
          error: null
        }
      });
    case `${UPLOAD_IMAGE}_${FULFILLED}`:
      return Object.assign({}, state, {
        [meta.name]: {
          data: payload.data,
          loading: false,
          error: null
        }
      });
    case `${UPLOAD_IMAGE}_${REJECTED}`:
      return Object.assign({}, state, {
        [meta.name]: {
          loading: false,
          error: payload
        }
      });
    case REMOVE_UPLOADED_IMAGE:
      const newState = Object.assign({}, state);
      delete newState[meta.name];
      return newState;
    default:
      return state;
  }
}

export function uploadedImagesPercentage(state = {}, { type, meta, percentage }) {
  switch (type) {
    case INCREASE_UPLOADED_PERCENTAGE:
      return Object.assign({}, state, {
        [meta.name]: percentage
      });
    case REMOVE_UPLOADED_IMAGE:
      const newState = Object.assign({}, state);
      delete newState[meta.name];
      return newState;

    default:
      return state;
  }
}

export function uploadedImagesPreview(state = {}, { type, meta, url }) {
  switch (type) {
    case SET_PREVIEW_IMAGE:
      return Object.assign({}, state, {
        [meta.name]: url
      });
    case REMOVE_UPLOADED_IMAGE:
      const newState = Object.assign({}, state);
      delete newState[meta.name];
      return newState;
    default:
      return state;
  }
}
