import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchCustomerTiers, deleteCustomerTier } from "actions/customerTier";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Table from "./Table";
import Pagination from "components/Pagination";

class CustomerTierIndex extends Component {
  excludeRecords = [];
  refreshSearch = false;

  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchCustomerTiers({ company_url });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchCustomerTiers({ company_url, page });
  };

  handleCustomerTierDelete = id => event => {
    this.refreshSearch = false;
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      onClickConfirm: () => {
        this.deleteCustomerTierProcess(id);
      }
    });
  };

  deleteCustomerTierProcess(id) {
    const {
      toggleNotification,
      toggleconfirmationPopup,
      deleteCustomerTier,
      match
    } = this.props;
    const { companyURL: company_url } = match.params;
    deleteCustomerTier(id, { company_url })
      .then(() => {
        this.props.fetchCustomerTiers({ company_url });
        this.excludeRecords.push(id);
        this.refreshSearch = true;
        toggleNotification(true, {
          infoClassName: "is-success",
          message: "Delete CustomerTier Success"
        });
        toggleconfirmationPopup(false, {});
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
        toggleconfirmationPopup(false, {});
      });
  }

  render() {
    const { customerTiers, match } = this.props;
    const { companyURL } = match.params;

    return (
      <div>
        <div className="box">
          <React.Fragment>
            <Table
              customerTiers={customerTiers}
              companyURL={companyURL}
              onClickDelete={this.handleCustomerTierDelete}
            />
            <Pagination
              onClick={this.onPaginationClick}
              currentPage={customerTiers?.current_page}
              lastPage={customerTiers?.last_page}
              range="4"
              modifyClasses="is-right"
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  authUser,
  customerTiers,
  confirmationPopupActive,
  setting
}) => ({
  authUser,
  customerTiers,
  confirmationPopupActive,
  setting
});

export default connect(mapStateToProps, {
  fetchCustomerTiers,
  deleteCustomerTier,
  toggleconfirmationPopup,
  toggleNotification
})(CustomerTierIndex);
