/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import isEmpty from "lodash.isempty";

const CustomersTable = ({ customers, companyURL, onClickDelete }) => {
  const TableOrNotFound = () => {
    const sumOrders = orders =>
      orders?.length > 0
        ? orders
            .map(o => o.amount)
            .reduce((a, b) => parseFloat(a) + parseFloat(b))
        : 0;

    if (customers.loading) {
      return "is Loading";
    }
    if (isEmpty(customers.data)) {
      return (
        <p className="is-size-2 has-text-centered has-text-grey">
          Found Nothing
        </p>
      );
    }
    return (
      <table className="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Customer Tier</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Amount</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {customers.data.map(customer => (
            <tr key={customer.id}>
              <td>{customer.id}</td>
              <td>{customer.customer_tier?.name}</td>
              <td>{customer.name}</td>
              <td>{customer.phone}</td>
              <td style={{ textAlign: "right" }}>
                {sumOrders(customer.orders)}
              </td>

              <td className="is-icon">
                <p className="buttons">
                  <Link
                    to={`/${companyURL}/customers/${customer.id}/orders`}
                    className="button  is-link is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-money-check" />
                    </span>
                    <span>Purchase History</span>
                  </Link>
                  <Link
                    to={`/${companyURL}/customers/${customer.id}/edit`}
                    className="button  is-link is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-edit" />
                    </span>
                    <span>Edit</span>
                  </Link>
                  <a
                    onClick={onClickDelete(customer.id)}
                    to={`/${companyURL}/customers/${customer.id}/delete`}
                    className="button is-danger is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-trash" />
                    </span>
                    <span>Delete</span>
                  </a>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <section className="section">
      <TableOrNotFound />
    </section>
  );
};
export default CustomersTable;
