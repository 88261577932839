import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import { FETCH_TAG, FETCH_TAGS, DELETE_TAG, CREATE_TAG, DETACH_TAG } from "actions/tag";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function tag(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_TAG}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_TAG}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_TAG}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function tags(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_TAGS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_TAGS}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_TAGS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deleteTag(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_TAG}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_TAG}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_TAG}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createTag(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${CREATE_TAG}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_TAG}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_TAG}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function detachTag(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DETACH_TAG}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DETACH_TAG}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DETACH_TAG}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
