import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_CUSTOMERS,
  FETCH_CUSTOMER,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER
} from "../actions/customer";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function customers(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_CUSTOMERS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_CUSTOMERS}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_CUSTOMERS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function customer(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_CUSTOMER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_CUSTOMER}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_CUSTOMER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createCustomer(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${CREATE_CUSTOMER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_CUSTOMER}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_CUSTOMER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateCustomer(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_CUSTOMER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_CUSTOMER}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_CUSTOMER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deleteCustomer(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_CUSTOMER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_CUSTOMER}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_CUSTOMER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
