import React, { Component } from "react";
import { connect } from "react-redux";

import {
  fetchPaymentGateways,
  fetchPaymentGatewaysWithPagination,
  deletePaymentGateway
} from "actions/paymentGateway";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Pagination from "components/Pagination";
import Table from "./Table";

class PaymentGatewayIndex extends Component {
  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchPaymentGatewaysWithPagination({ company_url });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchPaymentGatewaysWithPagination({
      company_url,
      page
    });
  };

  handlePaymentGatewayDelete = id => event => {
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      title: "Remove PaymentGateway",
      body: "Are you sure you want to remove this paymentGateway from company?",
      onClickConfirm: () => {
        this.deletePaymentGatewayProcess(id);
      }
    });
  };

  deletePaymentGatewayProcess(id) {
    const {
      toggleNotification,
      toggleconfirmationPopup,
      deletePaymentGateway,
      match
    } = this.props;
    const { companyURL: company_url } = match.params;
    deletePaymentGateway(id, { company_url })
      .then(() => {
        this.props.fetchPaymentGatewaysWithPagination({
          company_url,
          partials: "parent"
        });
        toggleNotification(true, {
          infoClassName: "is-success",
          message: "Delete PaymentGateway Success"
        });
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
      })
      .then(() => toggleconfirmationPopup(false, {}));
  }

  render() {
    const { paymentGateways, match } = this.props;
    const { companyURL } = match.params;

    return (
      <div>
        <div className="box">
          <Table
            paymentGateways={paymentGateways}
            companyURL={companyURL}
            onClickDelete={this.handlePaymentGatewayDelete}
          />
          <Pagination
            onClick={this.onPaginationClick}
            currentPage={paymentGateways.current_page}
            lastPage={paymentGateways.last_page}
            range="4"
            modifyClasses="is-right"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ paymentGateways, confirmationPopupActive }) => ({
  paymentGateways,
  confirmationPopupActive
});

export default connect(mapStateToProps, {
  fetchPaymentGateways,
  fetchPaymentGatewaysWithPagination,
  deletePaymentGateway,
  toggleconfirmationPopup,
  toggleNotification
})(PaymentGatewayIndex);
