import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_CONTACTS,
  FETCH_CONTACT,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT
} from "../actions/contact";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function contacts(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_CONTACTS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_CONTACTS}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_CONTACTS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function contact(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_CONTACT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_CONTACT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_CONTACT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createContact(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${CREATE_CONTACT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_CONTACT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_CONTACT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateContact(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_CONTACT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_CONTACT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_CONTACT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deleteContact(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_CONTACT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_CONTACT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_CONTACT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
