import React, { memo } from "react";

export default memo(
  ({ flag, meta: { infoClassName, message }, onClickDelete }) => {
    return flag ? (
      <div className={`${infoClassName} notification is-fixed`}>
        <button className="delete" onClick={onClickDelete} />
        {message}
      </div>
    ) : null;
  }
);
