import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import isEmpty from "lodash.isempty";

import Layout from "layouts/Dashboard";
import authUtil from "utils/auth";
import alertUtil from "utils/alert";
import FourOThree from "pages/403";
import FiveHundred from "pages/500";
import { fetchAuthUser } from "actions/user";
import { fetchSetting } from "actions/setting";
import { toggleNotification, toggleconfirmationPopup } from "actions/toggle";
import LoadingBar from "components/LoadingBar";

class AuthRoute extends Component {
  componentDidMount() {
    const { companyURL: company_url } = this.props.computedMatch.params;
    if (authUtil.isTokenValid()) {
      this.props.fetchAuthUser({ company_url });
      this.props.fetchSetting({ company_url });
    }
  }

  checkAuthUserAuthorization() {
    if (!authUtil.isTokenValid()) {
      authUtil.removeToken();
      return;
    }
    const { data, loading, error } = this.props.authUser;
    if (error) {
      if (error.response && error.response.status === 403) {
        return "403";
      }
      return "500";
    }
    if (loading || isEmpty(data)) {
      return "loading";
    }
    if (!isEmpty(data)) {
      return "auth";
    }
    alertUtil.unexpectedError();
    authUtil.removeToken();
  }

  onClickNotificationDelete = () => {
    this.props.toggleNotification(false, {});
  };

  onClickConfirmationPopupCancel = () => {
    this.props.toggleconfirmationPopup(false, {});
  };

  AuthRouteComponent() {
    const {
      fullPageSpinnerActive,
      confirmationPopupActive,
      notificationActive,
      toggleNotification,
      className,
      authUser,
      setting,
      component: Component,
      ...rest
    } = this.props;

    const activeCompany = authUser.data.companies.find((company) => {
      return company.url === this.props.computedMatch.params.companyURL;
    });

    return (
      <Layout
        match={this.props.computedMatch}
        authUser={authUser}
        setting={setting}
        activeCompany={activeCompany}
        className={className}
        fullPageSpinnerActive={fullPageSpinnerActive}
        confirmationPopupActive={confirmationPopupActive}
        notificationActive={notificationActive}
        onClickNotificationDelete={this.onClickNotificationDelete}
        onClickConfirmationPopupCancel={this.onClickConfirmationPopupCancel}
      >
        <Route
          render={(props) => (
            <Component {...props} activeCompany={activeCompany} />
          )}
          {...rest}
        />
      </Layout>
    );
  }

  render() {
    const {
      computedMatch: {
        params: { companyURL },
      },
    } = this.props;

    switch (this.checkAuthUserAuthorization()) {
      case "403":
        return <FourOThree />;
      case "500":
        return <FiveHundred />;
      case "auth":
        return this.AuthRouteComponent();
      case "loading":
        return <LoadingBar />;
      default:
        const pathname = companyURL ? `/${companyURL}/login` : "/login";
        return (
          <Redirect
            to={{
              pathname,
              state: { from: this.props.location },
            }}
          />
        );
    }
  }
}

export default connect(
  ({
    authUser,
    setting,
    fullPageSpinnerActive,
    confirmationPopupActive,
    notificationActive,
  }) => ({
    authUser,
    setting,
    fullPageSpinnerActive,
    confirmationPopupActive,
    notificationActive,
  }),
  {
    fetchAuthUser,
    fetchSetting,
    toggleNotification,
    toggleconfirmationPopup,
  }
)(AuthRoute);
