import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchProduct, updateProduct } from "actions/product";
import { fetchCurrencies } from "actions/currency";
import { fetchPromotionTypes } from "actions/promotionType";
import { fetchProductStatuses } from "actions/productStatus";
import { fetchVariantOptions } from "actions/variantOption";
import { fetchTags } from "actions/tag";
import { fetchFilterTags } from "actions/filterTag";
import { fetchProductCategories } from "actions/productCategory";
import { fetchCustomerTiers } from "actions/customerTier";
import { fetchGroups } from "actions/group";
import {
  uploadImage,
  setUploadedImagePreview,
  removeUploadedImage,
} from "actions/image";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "pages/Products/Form";
import dateUtil from "utils/date";

class ProductEdit extends Component {
  componentWillUnmount() {
    this.props.removeUploadedImage("cover");
    this.props.removeUploadedImage("logo");
  }

  componentDidMount() {
    const {
      activeCompany,
      match,
      fetchProduct,
      fetchCurrencies,
      fetchPromotionTypes,
      fetchProductStatuses,
      fetchVariantOptions,
      fetchCustomerTiers,
    } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.productId;
    fetchProduct(id, { company_url });
    fetchCurrencies({ company_url });
    fetchPromotionTypes({ company_url });
    fetchProductStatuses({ company_url });
    fetchVariantOptions({ company_url });
    fetchCustomerTiers({ company_url });
  }

  shapedDefaultValuesStructure() {
    const {
      expired_at,
      released_at,
      tags,
      groups,
      filter_tags,
      attachments,
      currencies,
      promotionTypes,
      productStatuses,
      product_categories,
      product_variants,
      ...rest
    } = this.props.product.data;
    return Object.assign(
      {},
      {
        ...rest,
        attachments: attachments,
        released_at: dateUtil.phpFormatToJS(released_at),
        expired_at: dateUtil.phpFormatToJS(expired_at),
        tags: tags ? tags.map(({ name }) => name) : [],
        groups: groups ? groups.map(({ name }) => name) : [],
        filterTags: filter_tags ? filter_tags.map(({ name }) => name) : [],
        currencies: currencies || [],
        promotionTypes: promotionTypes || [],
        productCategories: product_categories,
        product_variants: product_variants
          ? product_variants.map(({ variant_options, ...rest }) => ({
              ...rest,
              variant_options: variant_options.map(
                ({ id, pivot: { value } }) => ({ id, value })
              ),
            }))
          : [],
      }
    );
  }

  updateProduct(id, data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      updateProduct,
      history,
    } = this.props;

    toggleFullPageSpinner(true);

    updateProduct(id, data)
      .then(() => {
        toggleNotification(true, {
          message: "Updated Product Success",
          infoClassName: "is-success",
        });
        history.push(`/${activeCompany.url}/products`);
      })
      .catch((e) => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger",
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = (data) => {
    const { activeCompany, match } = this.props;
    const id = match.params.productId;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.updateProduct(id, data);
  };

  render() {
    const {
      uploadedImagesPreview,
      uploadedImagesPercentage,
      uploadedImages,
      removeUploadedImage,
      uploadImage,
      setUploadedImagePreview,
      product,
      tags,
      groups,
      filterTags,
      productCategories,
      fetchTags,
      fetchGroups,
      fetchFilterTags,
      fetchProductCategories,
      activeCompany,
      setting,
      currencies,
      promotionTypes,
      variantOptions,
      productStatuses,
      customerTiers,
    } = this.props;

    if (product.loading) {
      return "loading";
    }
    const defaultValues = this.shapedDefaultValuesStructure();
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        uploadImage={uploadImage}
        uploadedImages={uploadedImages}
        setUploadedImagePreview={setUploadedImagePreview}
        removeUploadedImage={removeUploadedImage}
        uploadedImagesPreview={uploadedImagesPreview}
        uploadedImagesPercentage={uploadedImagesPercentage}
        defaultValues={defaultValues}
        hideNotifyUser={true}
        tags={tags}
        groups={groups}
        filterTags={filterTags}
        customerTiers={customerTiers}
        productCategories={productCategories}
        fetchTags={fetchTags}
        fetchGroups={fetchGroups}
        setting={setting}
        currencies={currencies}
        productStatuses={productStatuses}
        promotionTypes={promotionTypes}
        fetchFilterTags={fetchFilterTags}
        fetchProductCategories={fetchProductCategories}
        activeCompany={activeCompany}
        variantOptions={variantOptions}
      />
    );
  }
}

const mapStateToProps = ({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  product,
  tags,
  groups,
  filterTags,
  setting,
  currencies,
  promotionTypes,
  productCategories,
  productStatuses,
  variantOptions,
  customerTiers,
}) => ({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  product,
  tags,
  groups,
  filterTags,
  setting,
  currencies,
  promotionTypes,
  productStatuses,
  productCategories,
  variantOptions,
  customerTiers,
});

export default connect(mapStateToProps, {
  fetchProduct,
  updateProduct,
  uploadImage,
  removeUploadedImage,
  setUploadedImagePreview,
  toggleFullPageSpinner,
  toggleNotification,
  fetchTags,
  fetchFilterTags,
  fetchCurrencies,
  fetchPromotionTypes,
  fetchProductStatuses,
  fetchProductCategories,
  fetchVariantOptions,
  fetchGroups,
  fetchCustomerTiers,
})(ProductEdit);
