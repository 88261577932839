import React, { Component } from "react";
import { connect } from "react-redux";

import {
  fetchParentProducts,
  deleteParentProduct
} from "actions/parentProduct";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Table from "./Table";
import Pagination from "components/Pagination";
import MagicConnector from "components/algolia-dom/MagicConnector";
import ProcessButton from "components/Process/ProcessButton";
import ImportButton from "components/Process/ImportButton";
import { exportProducts } from "api/exportProducts";
import { getDownloadResultUrl } from "api/fetchExportStatus";
import SearchForm from "components/SearchForm";

class ParentProductIndex extends Component {
  excludeRecords = [];
  refreshSearch = false;

  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchParentProducts({ company_url });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchParentProducts({ company_url, page });
  };

  handleParentProductDelete = id => event => {
    this.refreshSearch = false;
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      onClickConfirm: () => {
        this.deleteParentProductProcess(id);
      }
    });
  };

  onSearchFormSubmit = data => {
    const { companyURL: company_url } = this.props.match.params;
    const search = {};
    if (data.search_term) {
      search[data.search_by] = data.search_term;
    }
    this.props.fetchParentProducts({ company_url, ...search });
  };

  afterImported = r => {
    if (r.success) {
      toggleNotification(true, {
        infoClassName: "is-success",
        message: "Import ParentProduct Success"
      });
      this.props.fetchParentProducts({
        company_url: this.props.match.params.companyURL
      });
    } else {
      toggleNotification(true, {
        infoClassName: "is-danger",
        message: "Import ParentProduct Failed"
      });
      window.open(
        getDownloadResultUrl(r.downloadResult, {
          company_url: this.props.match.params.companyURL,
          ...this.props.match.params
        })
      );
    }
  };

  deleteParentProductProcess(id) {
    const {
      toggleNotification,
      toggleconfirmationPopup,
      deleteParentProduct,
      match
    } = this.props;
    const { companyURL: company_url } = match.params;
    deleteParentProduct(id, { company_url })
      .then(() => {
        this.props.fetchParentProducts({ company_url });
        this.excludeRecords.push(id);
        this.refreshSearch = true;
        toggleNotification(true, {
          infoClassName: "is-success",
          message: "Delete ParentProduct Success"
        });
        toggleconfirmationPopup(false, {});
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
        toggleconfirmationPopup(false, {});
      });
  }

  render() {
    const {
      products,
      setting,
      match: { params }
    } = this.props;
    const { companyURL } = params;

    return (
      <div>
        <div className="box">
          <SearchForm
            onSearchFormSubmit={this.onSearchFormSubmit}
            options={[{ label: "By Title", value: "title" }]}
          />
          <Table
            products={products}
            companyURL={companyURL}
            onClickDelete={this.handleParentProductDelete}
          />
          <Pagination
            onClick={this.onPaginationClick}
            currentPage={products.current_page}
            lastPage={products.last_page}
            range="4"
            modifyClasses="is-right"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  authUser,
  parentProducts: products,
  confirmationPopupActive,
  setting
}) => ({
  authUser,
  products,
  confirmationPopupActive,
  setting
});

export default connect(mapStateToProps, {
  fetchParentProducts,
  deleteParentProduct,
  toggleconfirmationPopup,
  toggleNotification
})(ParentProductIndex);
