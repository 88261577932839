/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import { Field } from "react-form";

export default memo(
  ({
    onChange,
    onClickRemove,
    field,
    preview,
    uploadPercentage,
    label,
    required,
    size = 360,
    ...rest
  }) => {
    const validate = value => ({
      error:
        required && !value
          ? "Please select a file"
          : null || (value && value.size > 1048576 * 2)
          ? "image size can't be bigger than 2MB"
          : null
    });

    return (
      <Field field={field} validate={validate}>
        {fieldApi => {
          const { error, setValue, value } = fieldApi;
          preview = preview
            ? preview
            : value && typeof value === "object"
            ? value[size] || value.original
            : value;

          return (
            <div className="field">
              {preview && (
                <div className="uploaded-file-preview">
                  <img src={preview} alt="" />
                  <a
                    className="button is-outlined is-small is-danger"
                    onClick={e => {
                      setValue(undefined);
                      onClickRemove(e);
                    }}
                  >
                    <span className="icon">
                      <i className="fas fa-times" />
                    </span>
                  </a>
                </div>
              )}
              <label className="label">{label}</label>
              {uploadPercentage && uploadPercentage !== 100 && (
                <progress
                  className="progress is-info"
                  value={uploadPercentage}
                  max="100"
                />
              )}
              <div className="file">
                <label className="file-label">
                  <input
                    {...rest}
                    accept="image/png, image/jpeg"
                    className="file-input"
                    type="file"
                    onChange={e => {
                      const value = e.target.files[0];
                      if (value === undefined) {
                        return;
                      }

                      setValue(value);

                      if (!validate(value).error) {
                        onChange(e);
                      }
                    }}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload" />
                    </span>
                    <span className="file-label">Choose a file…</span>
                  </span>
                </label>
              </div>
              {error && <p className="help is-danger">{error}</p>}
            </div>
          );
        }}
      </Field>
    );
  }
);
