import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_PRODUCT_ORDERS,
  FETCH_PRODUCT_ORDER,
  FETCH_PRODUCT_ORDER_SUMMARY_BY_STATUS,
  DELETE_PRODUCT_ORDER,
  ARCHIVE_PRODUCT_ORDER,
} from "actions/productOrder";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false,
};

const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false,
};

export function productOrderSummaryByStatus(
  state = MULTI_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${FETCH_PRODUCT_ORDER_SUMMARY_BY_STATUS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_PRODUCT_ORDER_SUMMARY_BY_STATUS}_${FULFILLED}`:
      return Object.assign({}, state, payload, {
        loading: false,
        error: null,
      });
    case `${FETCH_PRODUCT_ORDER_SUMMARY_BY_STATUS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}

export function productOrders(
  state = MULTI_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${FETCH_PRODUCT_ORDERS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_PRODUCT_ORDERS}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null,
      });
    case `${FETCH_PRODUCT_ORDERS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}

export function productOrder(
  state = SINGLE_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${FETCH_PRODUCT_ORDER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_PRODUCT_ORDER}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${FETCH_PRODUCT_ORDER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}

export function deleteArticle(
  state = SINGLE_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${DELETE_PRODUCT_ORDER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_PRODUCT_ORDER}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${DELETE_PRODUCT_ORDER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}

export function archiveArticle(
  state = SINGLE_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${ARCHIVE_PRODUCT_ORDER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${ARCHIVE_PRODUCT_ORDER}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${ARCHIVE_PRODUCT_ORDER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
