export const FETCH_SETTING = "FETCH_SETTING";
export const UPDATE_SETTING = "UPDATE_SETTING";

export function fetchSetting(params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_SETTING,
    auth: true,
    payload: {
      url: "resource/setting",
      method: "GET",
      params
    }
  };
}

export function updateSetting(data = {}) {
  return {
    type: UPDATE_SETTING,
    auth: true,
    payload: {
      url: "resource/setting",
      method: "PUT",
      data
    }
  };
}
