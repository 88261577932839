export const FETCH_PAYMENT_GATEWAY = "FETCH_PAYMENT_GATEWAY";
export const FETCH_PAYMENT_GATEWAYS = "FETCH_PAYMENT_GATEWAYS";
export const DELETE_PAYMENT_GATEWAY = "DELETE_PAYMENT_GATEWAY";
export const CREATE_PAYMENT_GATEWAY = "CREATE_PAYMENT_GATEWAY";
export const UPDATE_PAYMENT_GATEWAY = "UPDATE_PAYMENT_GATEWAY";

export function fetchPaymentGateway(id, params = {}) {
  params = Object.assign({}, params, { partials: "gateway" });
  return {
    type: FETCH_PAYMENT_GATEWAY,
    auth: true,
    payload: {
      url: `resource/payment-gateways/${id}`,
      method: "GET",
      params
    }
  };
}

export function fetchPaymentGateways(params = {}) {
  params = Object.assign({}, params, { partials: "gateway" });
  return {
    type: FETCH_PAYMENT_GATEWAYS,
    auth: true,
    payload: {
      url: "resource/payment-gateways/all",
      method: "GET",
      params
    }
  };
}

export function fetchPaymentGatewaysWithPagination(params = {}) {
  params = Object.assign({}, params, { partials: "gateway" });
  return {
    type: FETCH_PAYMENT_GATEWAYS,
    auth: true,
    payload: {
      url: "resource/payment-gateways",
      method: "GET",
      params
    }
  };
}

export function deletePaymentGateway(id, data = {}) {
  return {
    type: DELETE_PAYMENT_GATEWAY,
    auth: true,
    payload: {
      url: `resource/payment-gateways/${id}`,
      method: "DELETE",
      data
    }
  };
}

export function createPaymentGateway(data = {}) {
  return {
    type: CREATE_PAYMENT_GATEWAY,
    auth: true,
    payload: {
      url: "resource/payment-gateways",
      method: "POST",
      data
    }
  };
}

export function updatePaymentGateway(id, data = {}) {
  return {
    type: UPDATE_PAYMENT_GATEWAY,
    auth: true,
    payload: {
      url: `resource/payment-gateways/${id}`,
      method: "PUT",
      data
    }
  };
}
