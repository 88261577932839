export const FETCH_FILTER = "FETCH_FILTER";
export const FETCH_FILTERS = "FETCH_FILTERS";
export const DELETE_FILTER = "DELETE_FILTER";
export const CREATE_FILTER = "CREATE_FILTER";
export const DETACH_FILTER = "DETACH_FILTER";

export function fetchFilterTag(id, params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_FILTER,
    auth: true,
    payload: {
      url: `resource/filters/${id}`,
      method: "GET",
      params
    }
  };
}

export function fetchFilterTags(params = {}, cancelToken) {
  params = Object.assign({}, params);
  return {
    type: FETCH_FILTERS,
    auth: true,
    payload: {
      url: "resource/filters",
      method: "GET",
      params,
      cancelToken
    }
  };
}

export function deleteFilterTag(id, data = {}) {
  return {
    type: DELETE_FILTER,
    auth: true,
    payload: {
      url: `resource/filters/${id}`,
      method: "DELETE",
      data
    }
  };
}

export function createFilterTag(data = {}) {
  return {
    type: CREATE_FILTER,
    auth: true,
    payload: {
      url: "resource/filters",
      method: "POST",
      data
    }
  };
}
