import React, { memo } from "react";

export default memo(({ currentRefinement, refine, nbPages }) => (
  <a
    {...{
      className: "pagination-next",
      disabled: currentRefinement >= nbPages,
      onClick:
        currentRefinement >= nbPages
          ? undefined
          : () => refine(++currentRefinement)
    }}
  >
    Next page
  </a>
));
