import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchParentProduct } from "actions/parentProduct";
import { fetchCurrencies } from "actions/currency";
import { createProduct } from "actions/product";
import { fetchPromotionTypes } from "actions/promotionType";
import { fetchProductStatuses } from "actions/productStatus";
import { fetchVariantOptions } from "actions/variantOption";
import { fetchTags } from "actions/tag";
import { fetchFilterTags } from "actions/filterTag";
import { fetchProductCategories } from "actions/productCategory";
import { fetchCustomerTiers } from "actions/customerTier";
import { fetchGroups } from "actions/group";
import {
  uploadImage,
  setUploadedImagePreview,
  removeUploadedImage
} from "actions/image";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "pages/ParentProducts/Form";
import dateUtil from "utils/date";

class ParentProductEdit extends Component {
  componentWillUnmount() {
    this.props.removeUploadedImage("cover");
    this.props.removeUploadedImage("logo");
  }

  componentDidMount() {
    const {
      activeCompany,
      match,
      fetchParentProduct,
      fetchCurrencies,
      fetchPromotionTypes,
      fetchProductStatuses,
      fetchVariantOptions,
      fetchCustomerTiers
    } = this.props;
    const company_id = activeCompany.parent_id;
    const company_url = activeCompany.url;
    const id = match.params.productId;
    fetchParentProduct(id, { company_url });
    fetchCurrencies({ company_id });
    fetchPromotionTypes({ company_id });
    fetchProductStatuses({ company_id });
    fetchVariantOptions({ company_id });
    fetchCustomerTiers({ company_id });
  }

  shapedDefaultValuesStructure() {
    const {
      expired_at,
      released_at,
      tags,
      groups,
      filter_tags,
      attachments,
      currencies,
      promotionTypes,
      productStatuses,
      product_categories,
      product_variants,
      ...rest
    } = this.props.product.data;
    return Object.assign(
      {},
      {
        ...rest,
        attachments: attachments,
        released_at: dateUtil.phpFormatToJS(released_at),
        expired_at: dateUtil.phpFormatToJS(expired_at),
        tags: tags ? tags.map(({ name }) => name) : [],
        groups: groups ? groups.map(({ name }) => name) : [],
        filterTags: filter_tags ? filter_tags.map(({ name }) => name) : [],
        currencies: currencies || [],
        promotionTypes: promotionTypes || [],
        productCategories: product_categories,
        product_variants: product_variants
          ? product_variants.map(({ variant_options, ...rest }) => ({
              ...rest,
              variant_options: variant_options.map(
                ({ id, pivot: { value } }) => ({ id, value })
              )
            }))
          : []
      }
    );
  }

  createProduct(data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      createProduct,
      history
    } = this.props;

    toggleFullPageSpinner(true);

    createProduct(data)
      .then(() => {
        toggleNotification(true, {
          message: "Created Product Success",
          infoClassName: "is-success"
        });
        history.push(`/${activeCompany.url}/products`);
      })
      .catch(e => {
        toggleNotification(true, {
          message: e.response.data.message,
          infoClassName: "is-danger"
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = data => {
    const { activeCompany, match } = this.props;
    const { id, company_id, ...d } = data;
    data = Object.assign({}, { ...d, parent_id: id }, { company_url: activeCompany.url });
    this.createProduct(data);
  };

  render() {
    const {
      uploadedImagesPreview,
      uploadedImagesPercentage,
      uploadedImages,
      removeUploadedImage,
      uploadImage,
      setUploadedImagePreview,
      product,
      tags,
      groups,
      filterTags,
      productCategories,
      fetchTags,
      fetchGroups,
      fetchFilterTags,
      fetchProductCategories,
      activeCompany,
      setting,
      currencies,
      promotionTypes,
      variantOptions,
      productStatuses,
      customerTiers
    } = this.props;

    if (product.loading) {
      return "loading";
    }
    const defaultValues = this.shapedDefaultValuesStructure();
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        uploadImage={uploadImage}
        uploadedImages={uploadedImages}
        setUploadedImagePreview={setUploadedImagePreview}
        removeUploadedImage={removeUploadedImage}
        uploadedImagesPreview={uploadedImagesPreview}
        uploadedImagesPercentage={uploadedImagesPercentage}
        defaultValues={defaultValues}
        tags={tags}
        groups={groups}
        filterTags={filterTags}
        customerTiers={customerTiers}
        productCategories={productCategories}
        fetchTags={fetchTags}
        fetchGroups={fetchGroups}
        setting={setting}
        currencies={currencies}
        productStatuses={productStatuses}
        promotionTypes={promotionTypes}
        fetchFilterTags={fetchFilterTags}
        fetchProductCategories={fetchProductCategories}
        activeCompany={activeCompany}
        variantOptions={variantOptions}
      />
    );
  }
}

const mapStateToProps = ({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  parentProduct: product,
  tags,
  groups,
  filterTags,
  setting,
  currencies,
  promotionTypes,
  productCategories,
  productStatuses,
  variantOptions,
  customerTiers
}) => ({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  product,
  tags,
  groups,
  filterTags,
  setting,
  currencies,
  promotionTypes,
  productStatuses,
  productCategories,
  variantOptions,
  customerTiers
});

export default connect(mapStateToProps, {
  fetchParentProduct,
  createProduct,
  uploadImage,
  removeUploadedImage,
  setUploadedImagePreview,
  toggleFullPageSpinner,
  toggleNotification,
  fetchTags,
  fetchFilterTags,
  fetchCurrencies,
  fetchPromotionTypes,
  fetchProductStatuses,
  fetchProductCategories,
  fetchVariantOptions,
  fetchGroups,
  fetchCustomerTiers
})(ParentProductEdit);
