import React, { Component } from "react";
import { connect } from "react-redux";

import {
  fetchVariantOptions,
  deleteVariantOption
} from "actions/variantOption";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Table from "./Table";
import ListTable from "components/ListTable";

class VariantOptionIndex extends Component {
  excludeRecords = [];
  refreshSearch = false;

  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchVariantOptions({ company_url });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchVariantOptions({ company_url, page });
  };

  onSearchFormSubmit = data => {
    const { companyURL: company_url } = this.props.match.params;
    const search = {};
    if (data.search_term) {
      search[data.search_by] = data.search_term;
    }
    this.props.fetchVariantOptions({ company_url, ...search });
  };

  handleVariantOptionDelete = id => event => {
    this.refreshSearch = false;
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      onClickConfirm: () => {
        this.deleteVariantOptionProcess(id);
      }
    });
  };

  deleteVariantOptionProcess(id) {
    const {
      toggleNotification,
      toggleconfirmationPopup,
      deleteVariantOption,
      match
    } = this.props;
    const { companyURL: company_url } = match.params;
    deleteVariantOption(id, { company_url })
      .then(() => {
        this.props.fetchVariantOptions({ company_url });
        this.excludeRecords.push(id);
        this.refreshSearch = true;
        toggleNotification(true, {
          infoClassName: "is-success",
          message: "Delete VariantOption Success"
        });
        toggleconfirmationPopup(false, {});
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
        toggleconfirmationPopup(false, {});
      });
  }

  render() {
    const { variantOptions, setting, match } = this.props;
    const { companyURL } = match.params;

    return (
      <div>
        <div className="box">
          <ListTable
            onPaginationClick={this.onPaginationClick}
            options={[{ label: "By Name", value: "name" }]}
            data={variantOptions}
          >
            <Table
              variantOptions={variantOptions}
              companyURL={companyURL}
              onClickDelete={this.handleVariantOptionDelete}
            />
          </ListTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  authUser,
  variantOptions,
  confirmationPopupActive,
  setting
}) => ({
  authUser,
  variantOptions,
  confirmationPopupActive,
  setting
});

export default connect(mapStateToProps, {
  fetchVariantOptions,
  deleteVariantOption,
  toggleconfirmationPopup,
  toggleNotification
})(VariantOptionIndex);
