import { parseISO, lightFormat, isDate } from "date-fns";

const dateUtils = {
  phpFormatToJS: date =>
    date ? lightFormat(isDate(date) ? date : parseISO(date), "yyyy-MM-dd") : "",
  ISOToDateTime: date =>
    date
      ? lightFormat(isDate(date) ? date : parseISO(date), "yyyy-MM-dd HH:mm:ss")
      : ""
};
export default dateUtils;
