import React, { memo } from "react";
import { Link } from "react-router-dom";

export default memo(() => (
  <nav className="navbar is-info">
    <div className="navbar-brand">
      <Link to="/" className="navbar-item">
        <span className="has-text-light">UTBA Admin Panel</span>
      </Link>
    </div>
  </nav>
));
