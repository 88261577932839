import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchUsers, deleteUser } from "actions/user";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Table from "./Table";

class UserIndex extends Component {
  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchUsers({ company_url });
  }

  handleUserDelete = id => event => {
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      title: "Remove User",
      body: "Are you sure you want to remove this user from company?",
      onClickConfirm: () => {
        this.deleteUserProcess(id);
      }
    });
  };

  deleteUserProcess(id) {
    const { toggleNotification, toggleconfirmationPopup, deleteUser, match } = this.props;
    const { companyURL: company_url } = match.params;
    deleteUser(id, { company_url })
      .then(() => {
        this.props.fetchUsers({ company_url });
        toggleNotification(true, { infoClassName: "is-success", message: "Delete User Success" });
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
      })
      .then(() => toggleconfirmationPopup(false, {}));
  }

  render() {
    const { users, match } = this.props;
    const { companyURL } = match.params;

    return (
      <div>
        <div className="box">
          <Table users={users} companyURL={companyURL} onClickDelete={this.handleUserDelete} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser, users, articles, confirmationPopupActive }) => ({
  authUser,
  users,
  articles,
  confirmationPopupActive
});

export default connect(
  mapStateToProps,
  { fetchUsers, deleteUser, toggleconfirmationPopup, toggleNotification }
)(UserIndex);
