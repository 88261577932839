import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_VARIANT_OPTIONS,
  FETCH_VARIANT_OPTION,
  CREATE_VARIANT_OPTION,
  UPDATE_VARIANT_OPTION,
  DELETE_VARIANT_OPTION
} from "../actions/variantOption";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function variantOptions(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_VARIANT_OPTIONS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_VARIANT_OPTIONS}_${FULFILLED}`:
      return Object.assign({}, state, payload, {
        loading: false,
        error: null
      });
    case `${FETCH_VARIANT_OPTIONS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function variantOption(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_VARIANT_OPTION}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_VARIANT_OPTION}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_VARIANT_OPTION}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createVariantOption(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${CREATE_VARIANT_OPTION}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_VARIANT_OPTION}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_VARIANT_OPTION}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateVariantOption(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_VARIANT_OPTION}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_VARIANT_OPTION}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_VARIANT_OPTION}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deleteVariantOption(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_VARIANT_OPTION}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_VARIANT_OPTION}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_VARIANT_OPTION}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
