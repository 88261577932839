import dateUtils from "utils/date";
import auth from "utils/auth";
import axios from "axios";
export * from "./fetchExportStatus";

const headers = () => {
  return {
    Accept: "application/json",
    Authorization: `Bearer ${auth.getToken()}`
  };
};

export const exportCustomers = params => {
  if (params.date) {
    params = Object.assign(
      {},
      {
        partials: `customerTier|orders(status_id=5!&createdAtGt=${dateUtils.ISOToDateTime(
          params.date[0]
        )}&createdAtLt=${dateUtils.ISOToDateTime(params.date[1])})`,
        orderBy: "id:desc"
      },
      params
    );
  } else {
    params = Object.assign(
      {},
      { partials: `customerTier|orders(status_id=5)`, orderBy: "id:desc" },
      params
    );
  }
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/resource/customers/process/export`,
    {},
    {
      params,
      headers: headers()
    }
  );
};
