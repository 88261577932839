import React, { Component } from "react";
import { Field } from "react-form";
import loadScript from "simple-load-script";

class LocationField extends Component {
  constructor(props) {
    super(props);
    this.$map = React.createRef();
  }

  componentDidMount() {
    if (window.google) {
      this.initMap();
    } else {
      const script = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
      loadScript(script)
        .then(() => {
          this.initMap();
        })
        .catch(err => {
          console.log({ message: "unable to load google map", err });
        });
    }
  }

  initMap() {
    if (!this.$map.current) {
      return;
    }

    let { location } = this.props.formApi.values;
    const { LatLng } = window.google.maps;
    if (location) {
      location = location.split(",");
      this.renderMap(new LatLng(location[0], location[1]));
    } else if (!navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) =>
        this.renderMap(coords)
      );
    } else {
      this.renderMap(new LatLng(16.8661, 96.1951));
    }
  }

  renderMap(lalng) {
    const { Map, Marker, Animation } = window.google.maps;

    this.$map.current.style.height = "300px";
    const map = new Map(this.$map.current, {
      zoom: 15,
      center: lalng
    });

    const marker = new Marker({
      position: lalng,
      map: map,
      animation: Animation.DROP
    });

    map.addListener("click", ({ latLng }) => {
      this.props.formApi.setValue(
        "location",
        `${latLng.lat().toFixed(6)}, ${latLng.lng().toFixed(6)}`
      );
      marker.setPosition(latLng);
    });
  }

  render() {
    const { label, formApi, onChange, onBlur, field, ...rest } = this.props;
    return (
      <React.Fragment>
        <Field field={field}>
          {({ setValue, setTouched, value }) => {
            return (
              <div className="field">
                <label className="label">{label}</label>
                <p className="control">
                  <input
                    value={value || ""}
                    className="input"
                    onChange={({ target }) => {
                      setValue(target.value);
                    }}
                    onBlur={() => {
                      setTouched();
                    }}
                    {...rest}
                  />
                </p>
              </div>
            );
          }}
        </Field>
        <div ref={this.$map} />
      </React.Fragment>
    );
  }
}
export default LocationField;
