import React, { memo } from "react";
import "./style.css";

export default memo(() => {
  return (
    <div className="loading-bar">
      <div className="blue-bar" />
    </div>
  );
});
