export const FETCH_HIGHLIGHT_ACTIONS = "FETCH_HIGHLIGHT_ACTIONS";
export const FETCH_HIGHLIGHT_ACTION = "FETCH_HIGHLIGHT_ACTION";

export function fetchHighlightActions(params = {}) {
  params = Object.assign({}, { orderBy: "id:desc" }, params);
  return {
    type: FETCH_HIGHLIGHT_ACTIONS,
    auth: true,
    payload: {
      url: "resource/highlight-actions",
      method: "GET",
      params,
    },
  };
}

export function fetchHighlightAction(id, params = {}) {
  return {
    type: FETCH_HIGHLIGHT_ACTION,
    auth: true,
    payload: {
      url: `resource/highlight-actions/${id}`,
      method: "GET",
      params,
    },
  };
}
