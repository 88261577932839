import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchSetting, updateSetting } from "actions/setting";
import { fetchCompanyProfileTypes } from "actions/companyProfileType";
import {
  uploadImage,
  setUploadedImagePreview,
  removeUploadedImage
} from "actions/image";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "./Form";

class Setting extends Component {
  componentWillUnmount() {
    this.props.removeUploadedImage("cover");
    this.props.removeUploadedImage("logo");
  }

  componentDidMount() {
    const {
      activeCompany: { url },
      fetchCompanyProfileTypes
    } = this.props;
    fetchCompanyProfileTypes({ company_url: url });
  }

  updateSetting(data) {
    const {
      toggleNotification,
      toggleFullPageSpinner,
      activeCompany,
      updateSetting,
      fetchSetting
    } = this.props;

    toggleFullPageSpinner(true);

    updateSetting(data)
      .then(() => {
        toggleNotification(true, {
          message: "Updated Setting Success",
          infoClassName: "is-success"
        });
      })
      .catch(e => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger"
        });
      })
      .then(() => {
        const company_url = activeCompany.url;
        fetchSetting({ company_url });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = data => {
    data = Object.assign({}, data, {
      company_url: this.props.activeCompany.url
    });
    this.updateSetting(data);
  };

  shapedDefaultValuesStructure(data) {
    return data;
  }

  render() {
    const {
      setting,
      activeCompany,
      companyProfileTypes,
      uploadedImagesPreview,
      uploadedImagesPercentage,
      uploadedImages,
      removeUploadedImage,
      uploadImage,
      setUploadedImagePreview
    } = this.props;

    if (setting.loading || companyProfileTypes.loading) {
      return "loading";
    }

    const defaultValues = this.shapedDefaultValuesStructure(setting.data);
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        company={activeCompany}
        uploadImage={uploadImage}
        uploadedImages={uploadedImages}
        setUploadedImagePreview={setUploadedImagePreview}
        removeUploadedImage={removeUploadedImage}
        uploadedImagesPreview={uploadedImagesPreview}
        uploadedImagesPercentage={uploadedImagesPercentage}
        companyProfileTypes={companyProfileTypes}
        defaultValues={defaultValues}
      />
    );
  }
}

const mapStateToProps = ({
  setting,
  companyProfileTypes,
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview
}) => ({
  setting,
  companyProfileTypes,
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview
});

export default connect(mapStateToProps, {
  fetchSetting,
  fetchCompanyProfileTypes,
  updateSetting,
  toggleFullPageSpinner,
  toggleNotification,
  uploadImage,
  removeUploadedImage,
  setUploadedImagePreview
})(Setting);
