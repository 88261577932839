import React from "react";

export default function PaymentInfo({ data }) {
  return (
    <>
      <div>
        Paid with: <b>{data.payment_gateway.gateway.title}</b>
      </div>
      <div>
        Paid on: <b>{data.paid_at}</b>
      </div>
      <div>
        Transaction ID: <b>{data.payment_id}</b>
      </div>
    </>
  );
}
