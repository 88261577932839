import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import { FETCH_ABOUT, UPDATE_ABOUT } from "actions/about";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};

export function about(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_ABOUT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_ABOUT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_ABOUT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateAbout(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_ABOUT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_ABOUT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_ABOUT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
