import React from "react";
import Select from "./Select";
import InputText from "components/form-fields/InputText";
import TextArea from "components/form-fields/TextArea";

export default ({ variantOptions, formApi, fieldApi, field, ...props }) => (
  <div className="box">
    <a
      href="#/"
      className="button is-danger is-outlined is-small is-pulled-right"
      onClick={e => {
        e.preventDefault();
        const [, key] = field;
        formApi.removeValue("addresses", key);
      }}
    >
      <span>Delete</span>
      <span className="icon is-small">
        <i className="fas fa-times fa-lg" />
      </span>
    </a>
    {!fieldApi.getValue("is_default") && (
      <a
        href="#/"
        className="button is-danger is-outlined is-small is-pulled-right"
        style={{ marginRight: 10 }}
        onClick={e => {
          e.preventDefault();
          const [, key] = field;
          formApi.setValue(
            "addresses",
            formApi.values.addresses.map((a, k) => {
              a.is_default = key == k;
              return a;
            })
          );
        }}
      >
        <span>Make Default</span>
        <span className="icon is-small">
          <i className="fas fa-times fa-lg" />
        </span>
      </a>
    )}
    <div className="columns">
      <div className="column">
        <InputText field="name" label="Name" placeholder="Name" required />
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <InputText field="label" placeholder="Label" label="Label" required />
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <InputText field="phone" placeholder="Phone" label="Phone" required />
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <TextArea
          field="address"
          placeholder="Address"
          label="Address"
          required
        />
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <TextArea field="note" placeholder="Note" label="Note" />
      </div>
    </div>
  </div>
);
