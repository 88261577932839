import React, { Component } from "react";
import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DateFilter from "./DateFilter";
import { fetchProductOrderSummaryByStatus } from "actions/productOrder";
import { fetchOtpCount } from "actions/otp";
import { connect } from "react-redux";
import { startOfWeek, endOfWeek } from "date-fns";
import { fetchCompany } from "actions/company";

class Dashboard extends Component {
  range = {
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date())
  };

  render() {
    const {
      otpCount,
      productOrderSummaryByStatus,
      fetchProductOrderSummaryByStatus,
      fetchOtpCount,
      activeCompany: {
        url: company_url,
        disk_capacity: diskCapacity,
        used_space: usedSpace,
        sms_credits
      }
    } = this.props;

    const used_percent = Math.round((usedSpace / diskCapacity) * 100);
    const used_MB = Math.ceil(usedSpace * 0.001);
    const capacity_MB = Math.ceil(diskCapacity * 0.001);

    return (
      <React.Fragment>
        <div className="columns">
          <DateFilter
            summary={productOrderSummaryByStatus}
            otpCount={otpCount}
            range={this.range}
            company_url={company_url}
            fetchProductOrderSummaryByStatus={fetchProductOrderSummaryByStatus}
            fetchOtpCount={fetchOtpCount}
          />
        </div>
        <div className="columns">
          <div
            className="column"
            style={{
              width: "200px",
              textAlign: "center",
              height: 200,
              flexGrow: 0.2
            }}
          >
            <CircularProgressbar
              value={sms_credits}
              maxValue={100000}
              text={`${sms_credits}`}
              initialAnimation={true}
            />
            SMS Credits
          </div>
          <div
            className="column"
            style={{
              width: "200px",
              textAlign: "center",
              height: 400,
              flexGrow: 0.2
            }}
          >
            <CircularProgressbar
              percentage={used_percent}
              text={`${used_percent}%`}
              initialAnimation={true}
            />
            {capacity_MB} MB/{used_MB} MB
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  company,
  productOrderSummaryByStatus,
  otpCount
}) => ({
  company,
  productOrderSummaryByStatus,
  otpCount
});

export default connect(mapStateToProps, {
  fetchProductOrderSummaryByStatus,
  fetchOtpCount,
  fetchCompany
})(Dashboard);
