import React, { Component } from "react";
import { Form } from "react-form";

import InputText from "components/form-fields/InputText";
import Select from "components/form-fields/Select";

class CategoryForm extends Component {
  render() {
    const {
      props: { defaultValues }
    } = this;

    return (
      <Form
        onSubmit={this.props.handleFormSubmit}
        defaultValues={defaultValues}
      >
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <InputText
                    required
                    field="name"
                    label="Name"
                    placeholder="Name"
                  />
                </div>
                <div className="column">
                  <Select
                    field="freezed"
                    placeholder="Is feezed?"
                    label="Freezed"
                    options={[
                      { label: "No", value: 0 },
                      { label: "Yes", value: 1 }
                    ]}
                  />
                </div>
              </div>
            </div>

            <button className="button is-fullwidth is-info is-medium">
              Submit
            </button>
          </form>
        )}
      </Form>
    );
  }
}
export default CategoryForm;
