import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_CURRENCIES,
  FETCH_CURRENCY,
  CREATE_CURRENCY,
  UPDATE_CURRENCY,
  DELETE_CURRENCY
} from "../actions/currency";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function currencies(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_CURRENCIES}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_CURRENCIES}_${FULFILLED}`:
      return Object.assign({}, state, payload, {
        loading: false,
        error: null
      });
    case `${FETCH_CURRENCIES}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function currency(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_CURRENCY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_CURRENCY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_CURRENCY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createCurrency(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${CREATE_CURRENCY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_CURRENCY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_CURRENCY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateCurrency(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_CURRENCY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_CURRENCY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_CURRENCY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deleteCurrency(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_CURRENCY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_CURRENCY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_CURRENCY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
