/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import isEmpty from "lodash.isempty";

const TagsTable = ({ tags, companyURL, onClickDelete }) => {
  const TableOrNotFound = () => {
    if (tags.loading) {
      return "is Loading";
    }
    if (isEmpty(tags.data)) {
      return (
        <p className="is-size-2 has-text-centered has-text-grey">
          Found Nothing
        </p>
      );
    }
    return (
      <table className="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Has Categories</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {tags.data.map(tag => (
            <tr key={tag.id}>
              <td>{tag.id}</td>
              <td>{tag.name}</td>
              <td>{tag.categories ? tag.categories.length : "No"}</td>

              <td className="is-icon">
                <p className="buttons">
                  <a
                    onClick={onClickDelete(tag.id)}
                    className="button is-danger is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-trash" />
                    </span>
                    <span>Delete</span>
                  </a>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <section className="section">
      <TableOrNotFound />
    </section>
  );
};
export default TagsTable;
