import React, { memo } from "react";
export default memo(({ currentPage, onClick }) => (
  <a
    {...{
      className: "pagination-previous",
      disabled: currentPage <= 1,
      onClick: currentPage <= 1 ? undefined : onClick(--currentPage)
    }}
  >
    Previous page
  </a>
));
