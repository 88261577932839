import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchCategories, deleteCategory } from "actions/category";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Pagination from "components/Pagination";
import Table from "./Table";

class CategoryIndex extends Component {
  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchCategories({ company_url, partials: "tags" });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchCategories({ company_url, partials: "tags", page });
  };

  handleCategoryDelete = id => event => {
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      title: "Remove Category",
      body: "Are you sure you want to remove this category from company?",
      onClickConfirm: () => {
        this.deleteCategoryProcess(id);
      }
    });
  };

  deleteCategoryProcess(id) {
    const { toggleNotification, toggleconfirmationPopup, deleteCategory, match } = this.props;
    const { companyURL: company_url } = match.params;
    deleteCategory(id, { company_url })
      .then(() => {
        this.props.fetchCategories({ company_url });
        toggleNotification(true, { infoClassName: "is-success", message: "Delete Category Success" });
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
      })
      .then(() => toggleconfirmationPopup(false, {}));
  }

  render() {
    const { categories, match } = this.props;
    const { companyURL } = match.params;

    return (
      <div>
        <div className="box">
          <Table categories={categories} companyURL={companyURL} onClickDelete={this.handleCategoryDelete} />
          <Pagination
            onClick={this.onPaginationClick}
            currentPage={categories.current_page}
            lastPage={categories.last_page}
            range="4"
            modifyClasses="is-right"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ categories, articles, confirmationPopupActive }) => ({
  categories,
  articles,
  confirmationPopupActive
});

export default connect(
  mapStateToProps,
  { fetchCategories, deleteCategory, toggleconfirmationPopup, toggleNotification }
)(CategoryIndex);
