import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchCustomerTier, updateCustomerTier } from "actions/customerTier";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "pages/CustomerTiers/Form";

class CustomerTierEdit extends Component {
  componentDidMount() {
    const { activeCompany, match, fetchCustomerTier } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.customerTierId;
    fetchCustomerTier(id, { company_url });
  }

  shapedDefaultValuesStructure() {
    return this.props.customerTier.data;
  }

  updateCustomerTier(id, data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      updateCustomerTier,
      history,
    } = this.props;

    toggleFullPageSpinner(true);

    updateCustomerTier(id, data)
      .then(() => {
        toggleNotification(true, {
          message: "Updated CustomerTier Success",
          infoClassName: "is-success",
        });
        history.push(`/${activeCompany.url}/customer-tiers`);
      })
      .catch((e) => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger",
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = (data) => {
    const { activeCompany, match } = this.props;
    const id = match.params.customerTierId;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.updateCustomerTier(id, data);
  };

  render() {
    const { customerTier, activeCompany } = this.props;

    if (customerTier.loading) {
      return "loading";
    }
    const defaultValues = this.shapedDefaultValuesStructure();
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        defaultValues={defaultValues}
        activeCompany={activeCompany}
      />
    );
  }
}

const mapStateToProps = ({ customerTier }) => ({ customerTier });

export default connect(mapStateToProps, {
  fetchCustomerTier,
  updateCustomerTier,
  toggleFullPageSpinner,
  toggleNotification,
})(CustomerTierEdit);
