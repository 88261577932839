import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_PARENT_PRODUCTS,
  FETCH_PARENT_PRODUCT,
  CREATE_PARENT_PRODUCT,
  UPDATE_PARENT_PRODUCT,
  DELETE_PARENT_PRODUCT
} from "../actions/parentProduct";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function parentProducts(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_PARENT_PRODUCTS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_PARENT_PRODUCTS}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_PARENT_PRODUCTS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload.message
      });
    default:
      return state;
  }
}

export function parentProduct(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_PARENT_PRODUCT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_PARENT_PRODUCT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_PARENT_PRODUCT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createProduct(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  console.log('asdfasdf')
  switch (type) {
    case `${CREATE_PARENT_PRODUCT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_PARENT_PRODUCT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_PARENT_PRODUCT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateProduct(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_PARENT_PRODUCT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_PARENT_PRODUCT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_PARENT_PRODUCT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload.message
      });
    default:
      return state;
  }
}

export function deleteProduct(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_PARENT_PRODUCT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_PARENT_PRODUCT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_PARENT_PRODUCT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
