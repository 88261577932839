import React, { Component } from "react";
import { connect } from "react-redux";

import { createCustomerTier } from "actions/customerTier";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "pages/CustomerTiers/Form";

class CustomerTierCreate extends Component {
  handleFormSubmit = (data) => {
    const { activeCompany } = this.props;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.createCustomerTier(data);
  };

  createCustomerTier(data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      createCustomerTier,
      history,
    } = this.props;

    toggleFullPageSpinner(true);

    createCustomerTier(data)
      .then(() => {
        toggleNotification(true, {
          message: "Created CustomerTier Success",
          infoClassName: "is-success",
        });
        history.push(`/${activeCompany.url}/customer-tiers`);
      })
      .catch((e) => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger",
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  render() {
    const { activeCompany } = this.props;

    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        activeCompany={activeCompany}
      />
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  createCustomerTier,
  toggleFullPageSpinner,
  toggleNotification,
})(CustomerTierCreate);
