export const FETCH_ARTICLES = "FETCH_ARTICLES";
export const FETCH_ARTICLE = "FETCH_ARTICLE";
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";

export function fetchArticles(params = {}) {
  params = Object.assign({}, { partials: "attachments|user", orderBy: "id:desc" }, params);
  return {
    type: FETCH_ARTICLES,
    auth: true,
    payload: {
      url: "resource/articles",
      method: "GET",
      params
    }
  };
}

export function fetchArticle(id, params = {}) {
  params = Object.assign({}, { partials: "attachments|user|tags" }, params);
  return {
    type: FETCH_ARTICLE,
    auth: true,
    payload: {
      url: `resource/articles/${id}`,
      method: "GET",
      params
    }
  };
}

export function createArticle(data = {}) {
  return {
    type: CREATE_ARTICLE,
    auth: true,
    payload: {
      url: "resource/articles",
      method: "POST",
      data
    }
  };
}

export function updateArticle(id, data = {}) {
  return {
    type: UPDATE_ARTICLE,
    auth: true,
    payload: {
      url: `resource/articles/${id}`,
      method: "PUT",
      data
    }
  };
}

export function deleteArticle(id, data = {}) {
  return {
    type: DELETE_ARTICLE,
    auth: true,
    payload: {
      url: `resource/articles/${id}`,
      method: "DELETE",
      data
    }
  };
}
