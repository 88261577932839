import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_CUSTOMER_TIERS,
  FETCH_CUSTOMER_TIER,
  CREATE_CUSTOMER_TIER,
  UPDATE_CUSTOMER_TIER,
  DELETE_CUSTOMER_TIER
} from "../actions/customerTier";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function customerTiers(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_CUSTOMER_TIERS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_CUSTOMER_TIERS}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_CUSTOMER_TIERS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function customerTier(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_CUSTOMER_TIER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_CUSTOMER_TIER}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_CUSTOMER_TIER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createCustomerTier(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${CREATE_CUSTOMER_TIER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_CUSTOMER_TIER}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_CUSTOMER_TIER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateCustomerTier(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_CUSTOMER_TIER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_CUSTOMER_TIER}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_CUSTOMER_TIER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deleteCustomerTier(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_CUSTOMER_TIER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_CUSTOMER_TIER}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_CUSTOMER_TIER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
