import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchDeliveries, deleteDelivery } from "actions/delivery";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Pagination from "components/Pagination";
import Table from "./Table";

class DeliveryIndex extends Component {
  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchDeliveries({ company_url, partials: "zones" });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchDeliveries({ company_url, partials: "zones", page });
  };

  handleDeliveryDelete = id => event => {
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      title: "Remove Delivery",
      body: "Are you sure you want to remove this delivery from company?",
      onClickConfirm: () => {
        this.deleteDeliveryProcess(id);
      }
    });
  };

  deleteDeliveryProcess(id) {
    const { toggleNotification, toggleconfirmationPopup, deleteDelivery, match } = this.props;
    const { companyURL: company_url } = match.params;
    deleteDelivery(id, { company_url })
      .then(() => {
        this.props.fetchDeliveries({ company_url });
        toggleNotification(true, { infoClassName: "is-success", message: "Delete Delivery Success" });
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
      })
      .then(() => toggleconfirmationPopup(false, {}));
  }

  render() {
    const { deliveries, match } = this.props;
    const { companyURL } = match.params;

    return (
      <div>
        <div className="box">
          <Table deliveries={deliveries} companyURL={companyURL} onClickDelete={this.handleDeliveryDelete} />
          <Pagination
            onClick={this.onPaginationClick}
            currentPage={deliveries.current_page}
            lastPage={deliveries.last_page}
            range="4"
            modifyClasses="is-right"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ deliveries, articles, confirmationPopupActive }) => ({
  deliveries,
  articles,
  confirmationPopupActive
});

export default connect(
  mapStateToProps,
  { fetchDeliveries, deleteDelivery, toggleconfirmationPopup, toggleNotification }
)(DeliveryIndex);
