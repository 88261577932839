import React, { memo } from "react";
import range from "lodash.range";

import ImageField from "components/form-fields/ImageField";
import RichTextarea from "components/form-fields/RichTextarea";
import Select from "components/form-fields/Select";
import uniqid from "uniqid";
import HiddenField from "components/form-fields/HiddenField";
import VideoField from "./VideoField";

export default memo(({ formApi, fieldApi, field, photo }) => {
  const isTextFirst = formApi.getValue("text_first") || 0;

  const key = field[1];
  const uniqueImageName = fieldApi.getValue("uid");
  return (
    <div className="box">
      <a
        href="#/"
        className="button is-danger is-outlined is-small is-pulled-right"
        onClick={e => {
          e.preventDefault();
          photo.onImageRemove(uniqueImageName)(e);
          formApi.removeValue("attachments", key);
        }}
      >
        <span>Delete</span>
        <span className="icon is-small">
          <i className="fas fa-times fa-lg" />
        </span>
      </a>

      {isTextFirst ? (
        <div>
          <br />
          <RichTextarea field="text" />
          <VideoField
            field="video"
            label="Video"
            value={fieldApi.values.video}
          />
          <ImageField
            field="photo"
            label="Photo"
            uploadPercentage={photo.uploadedImagesPercentage[uniqueImageName]}
            preview={photo.uploadedImagesPreview[uniqueImageName]}
            onChange={photo.onImageFieldChange(uniqueImageName)}
            onClickRemove={photo.onImageRemove(uniqueImageName)}
          />
        </div>
      ) : (
        <div>
          <VideoField
            field="video"
            label="Video"
            value={fieldApi.values.video}
          />
          <ImageField
            field="photo"
            label="Photo"
            uploadPercentage={photo.uploadedImagesPercentage[uniqueImageName]}
            preview={photo.uploadedImagesPreview[uniqueImageName]}
            onChange={photo.onImageFieldChange(uniqueImageName)}
            onClickRemove={photo.onImageRemove(uniqueImageName)}
          />
          <RichTextarea field="text" />
        </div>
      )}

      <Select
        field="weight"
        placeholder="weight"
        fullwidth={false}
        options={range(-128, 127).map(number => ({
          label: number,
          value: number
        }))}
      />
      <HiddenField field="uid" defaultValue={uniqid.time("attachment_")} />
    </div>
  );
});
