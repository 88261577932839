import React, { Component } from "react";
import { connect } from "react-redux";

import {
  fetchProductCategories,
  fetchProductCategoriesWithPagination,
  deleteProductCategory
} from "actions/productCategory";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Pagination from "components/Pagination";
import Table from "./Table";

class ProductCategoryIndex extends Component {
  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchProductCategoriesWithPagination({ company_url, partials: "parent|status" });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchProductCategoriesWithPagination({
      company_url,
      partials: "parent|status",
      page
    });
  };

  handleProductCategoryDelete = id => event => {
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      title: "Remove ProductCategory",
      body:
        "Are you sure you want to remove this productCategory from company?",
      onClickConfirm: () => {
        this.deleteProductCategoryProcess(id);
      }
    });
  };

  deleteProductCategoryProcess(id) {
    const {
      toggleNotification,
      toggleconfirmationPopup,
      deleteProductCategory,
      match
    } = this.props;
    const { companyURL: company_url } = match.params;
    deleteProductCategory(id, { company_url })
      .then(() => {
        this.props.fetchProductCategoriesWithPagination({ company_url, partials: "parent" });
        toggleNotification(true, {
          infoClassName: "is-success",
          message: "Delete ProductCategory Success"
        });
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
      })
      .then(() => toggleconfirmationPopup(false, {}));
  }

  render() {
    const { productCategories, match } = this.props;
    const { companyURL } = match.params;

    return (
      <div>
        <div className="box">
          <Table
            productCategories={productCategories}
            companyURL={companyURL}
            onClickDelete={this.handleProductCategoryDelete}
          />
          <Pagination
            onClick={this.onPaginationClick}
            currentPage={productCategories.current_page}
            lastPage={productCategories.last_page}
            range="4"
            modifyClasses="is-right"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ productCategories, confirmationPopupActive }) => ({
  productCategories,
  confirmationPopupActive
});

export default connect(mapStateToProps, {
  fetchProductCategories,
  fetchProductCategoriesWithPagination,
  deleteProductCategory,
  toggleconfirmationPopup,
  toggleNotification
})(ProductCategoryIndex);
