import React, { memo } from "react";
import NextPage from "./NextPage";
import PreviousPage from "./PreviousPage";
import Pages from "./Pages";

export default memo(props => {
  if (!props.lastPage || props.lastPage <= 1) {
    return null;
  }
  return (
    <nav className={`pagination ${props.modifyClasses}`}>
      <NextPage {...props} />
      <PreviousPage {...props} />
      <Pages {...props} />
    </nav>
  );
});
