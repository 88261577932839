import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_HIGHLIGHTS,
  FETCH_HIGHLIGHT,
  CREATE_HIGHLIGHT,
  UPDATE_HIGHLIGHT,
  DELETE_HIGHLIGHT
} from "../actions/highlight";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function highlights(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_HIGHLIGHTS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_HIGHLIGHTS}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_HIGHLIGHTS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function highlight(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_HIGHLIGHT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_HIGHLIGHT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_HIGHLIGHT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createHighlight(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${CREATE_HIGHLIGHT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_HIGHLIGHT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_HIGHLIGHT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateHighlight(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_HIGHLIGHT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_HIGHLIGHT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_HIGHLIGHT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deleteHighlight(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_HIGHLIGHT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_HIGHLIGHT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_HIGHLIGHT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
