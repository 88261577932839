import React from "react";
import NextPage from "./NextPage";
import PreviousPage from "./PreviousPage";
import Pages from "./Pages";
import { connectPagination } from "react-instantsearch-dom";

const MyPagination = props => {
  const { canRefine } = props;

  if (!canRefine) {
    return null;
  }

  return (
    <nav className="pagination is-right">
      <NextPage {...props} />
      <PreviousPage {...props} />
      <Pages {...props} />
    </nav>
  );
};

export default connectPagination(MyPagination);
