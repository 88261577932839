import auth from "utils/auth";
import axios from "axios";

export const EXPORT_STATUS_PENDING = "Pending";
export const EXPORT_STATUS_PROCESSING = "Processing";
export const EXPORT_STATUS_SUCCESS = "Success";
export const EXPORT_STATUS_FAILED = "Failed";

const headers = () => {
  return {
    Accept: "application/json",
    Authorization: `Bearer ${auth.getToken()}`,
  };
};

export const getDownloadResultUrl = (url, params) => {
  return `${url}?company_url=${
    params.company_url
  }&access_token=${auth.getToken()}`;
};

export const fetchExportStatus = (id, params) => {
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/resource/processors/${id}`,
    {
      params,
      headers: headers(),
    }
  );
};
