import React, { Component } from "react";
import { Form } from "react-form";

import InputText from "components/form-fields/InputText";
import RichTextarea from "components/form-fields/RichTextarea";

class AboutForm extends Component {
  onSubmit = data => {
    this.props.handleFormSubmit(data);
  };

  render() {
    const {
      props: { defaultValues }
    } = this;

    return (
      <Form onSubmit={this.onSubmit} defaultValues={defaultValues}>
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <div className="box">
              <InputText
                required
                field="title"
                label="Title"
                placeholder="Title"
              />

              <RichTextarea required field="body" />
            </div>
            <button className="button is-fullwidth is-info is-medium">
              Submit
            </button>
          </form>
        )}
      </Form>
    );
  }
}
export default AboutForm;
