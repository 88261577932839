import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchUser, updateUser } from "actions/user";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";
import { fetchRoles } from "actions/role";

import Form from "pages/Users/Form";

class UserEdit extends Component {
  componentDidMount() {
    const { activeCompany, match, fetchUser, fetchRoles } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.userId;
    fetchUser(id, { company_url });
    fetchRoles({ company_url });
  }

  shapedDefaultValuesStructure() {
    const { email, roles } = this.props.user.data;
    const role_id = roles && roles[0] ? roles[0].id : undefined;
    return Object.assign({}, { email, role_id });
  }

  updateUser(id, data) {
    const { toggleNotification, activeCompany, toggleFullPageSpinner, updateUser, history } = this.props;

    toggleFullPageSpinner(true);

    updateUser(id, data)
      .then(() => {
        toggleNotification(true, { message: "Updated User Success", infoClassName: "is-success" });
        history.push(`/${activeCompany.url}/users`);
      })
      .catch(e => {
        toggleNotification(true, { message: e.message, infoClassName: "is-danger" });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = data => {
    const { activeCompany, match } = this.props;
    const id = match.params.userId;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.updateUser(id, data);
  };

  render() {
    const { user, roles } = this.props;

    if (user.loading) {
      return "loading";
    }
    const defaultValues = this.shapedDefaultValuesStructure();
    return <Form defaultValues={defaultValues} handleFormSubmit={this.handleFormSubmit} roles={roles} />;
  }
}

const mapStateToProps = ({ user, roles }) => ({
  user,
  roles
});

export default connect(
  mapStateToProps,
  {
    fetchUser,
    updateUser,
    toggleFullPageSpinner,
    toggleNotification,
    fetchRoles
  }
)(UserEdit);
