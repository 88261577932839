import React, { Component } from "react";
import { connect } from "react-redux";
import dateUtil from "utils/date";

import {
  fetchPaymentGateway,
  updatePaymentGateway
} from "actions/paymentGateway";

import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "../Form";

class PaymentGatewayEdit extends Component {
  componentDidMount() {
    const { activeCompany, match, fetchPaymentGateway } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.paymentGatewayId;
    fetchPaymentGateway(id, { company_url });
  }

  updatePaymentGateway(id, data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      updatePaymentGateway,
      history
    } = this.props;

    toggleFullPageSpinner(true);

    updatePaymentGateway(id, data)
      .then(() => {
        toggleNotification(true, {
          message: "Updated PaymentGateway Success",
          infoClassName: "is-success"
        });
        history.push(`/${activeCompany.url}/payment-gateways`);
      })
      .catch(e => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger"
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = data => {
    const { activeCompany, match } = this.props;
    const id = match.params.paymentGatewayId;

    data = Object.assign({}, data, { company_url: activeCompany.url });

    this.updatePaymentGateway(id, data);
  };

  render() {
    const { paymentGateway } = this.props;

    if (paymentGateway.loading) {
      return "loading";
    }
    return (
      <Form
        defaultValues={paymentGateway.data}
        handleFormSubmit={this.handleFormSubmit}
      />
    );
  }
}

const mapStateToProps = ({ paymentGateway }) => ({
  paymentGateway
});

export default connect(mapStateToProps, {
  fetchPaymentGateway,
  updatePaymentGateway,
  toggleFullPageSpinner,
  toggleNotification
})(PaymentGatewayEdit);
