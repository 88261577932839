import React, { memo } from "react";

export default memo(
  ({ flag, onClickCancel, meta: { onClickConfirm, title, body } }) => {
    return flag ? (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{title || "Delete"}</p>
            <button
              className="delete"
              aria-label="close"
              onClick={onClickCancel}
            />
          </header>
          <section className="modal-card-body">
            {body || "Are you sure?"}
          </section>
          <footer className="modal-card-foot">
            <button className="button" onClick={onClickCancel}>
              Cancel
            </button>
            <button className="button is-danger" onClick={onClickConfirm}>
              {title || "Delete"}
            </button>
          </footer>
        </div>
      </div>
    ) : null;
  }
);
