/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import dateUtils from "utils/date";
import isEmpty from "lodash.isempty";

const ArticlesTable = ({ articles, companyURL, onClickDelete }) => {
  const TableOrNotFound = () => {
    if (articles.loading) {
      return "is Loading";
    }
    if (isEmpty(articles.data)) {
      return (
        <p className="is-size-2 has-text-centered has-text-grey">
          Found Nothing
        </p>
      );
    }
    return (
      <table className="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Promotion</th>
            <th>Weight</th>
            <th>Freezed</th>
            <th>Released at</th>
            <th>Expired at</th>
            <th>Attachment Count</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {articles.data.map(article => (
            <tr key={article.id}>
              <td>{article.id}</td>
              <td>{article.title}</td>
              <td>{article.promotion}</td>
              <td>{article.weight}</td>
              <td>{article.freezed ? "Yes" : "No"}</td>
              <td>{dateUtils.phpFormatToJS(article.released_at)}</td>
              <td>{dateUtils.phpFormatToJS(article.expired_at)}</td>
              <td>{article.attachments ? article.attachments.length : 0}</td>

              <td className="is-icon">
                <p className="buttons">
                  <Link
                    to={`/${companyURL}/articles/${article.id}/edit`}
                    className="button  is-link is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-edit" />
                    </span>
                    <span>Edit</span>
                  </Link>
                  <a
                    onClick={onClickDelete(article.id)}
                    to={`/${companyURL}/articles/${article.id}/delete`}
                    className="button is-danger is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-trash" />
                    </span>
                    <span>Delete</span>
                  </a>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <section className="section">
      <TableOrNotFound />
    </section>
  );
};
export default ArticlesTable;
