export const FETCH_PROMOTION_TYPES = "FETCH_PROMOTION_TYPES";
export const FETCH_PROMOTION_TYPE = "FETCH_PROMOTION_TYPE";

export function fetchPromotionTypes(params = {}) {
  params = Object.assign({}, { orderBy: "id:desc" }, params);
  return {
    type: FETCH_PROMOTION_TYPES,
    auth: true,
    payload: {
      url: "resource/promotion-types",
      method: "GET",
      params,
    },
  };
}

export function fetchPromotionType(id, params = {}) {
  return {
    type: FETCH_PROMOTION_TYPE,
    auth: true,
    payload: {
      url: `resource/promotion-types/${id}`,
      method: "GET",
      params,
    },
  };
}
