import React, { Component } from "react";
import { connect } from "react-redux";

import { createDelivery } from "actions/delivery";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "../Form";

class DeliveryCreate extends Component {
  handleFormSubmit = data => {
    const { activeCompany } = this.props;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.createDelivery(data);
  };

  createDelivery(data) {
    const { toggleNotification, activeCompany, toggleFullPageSpinner, createDelivery, history } = this.props;
    toggleFullPageSpinner(true);
    createDelivery(data)
      .then(() => {
        toggleNotification(true, { message: "Created Delivery Success", infoClassName: "is-success" });
        history.push(`/${activeCompany.url}/deliveries`);
      })
      .catch(e => {
        const message = (e.response && e.response.data.message) || e.message;
        toggleNotification(true, { message, infoClassName: "is-danger" });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  render() {
    return <Form handleFormSubmit={this.handleFormSubmit} />;
  }
}

export default connect(
  () => ({}),
  {
    createDelivery,
    toggleFullPageSpinner,
    toggleNotification
  }
)(DeliveryCreate);
