import { TOGGLE_FULL_PAGE_SPINNER, TOGGLE_NOTIFICATION, TOGGLE_CONFIRMATION_POPUP } from "actions/toggle";

export function fullPageSpinnerActive(state = false, { type, flag }) {
  switch (type) {
    case TOGGLE_FULL_PAGE_SPINNER:
      return flag;
    default:
      return state;
  }
}

export function notificationActive(state = { flag: false, meta: {} }, { type, flag, meta }) {
  switch (type) {
    case TOGGLE_NOTIFICATION:
      return Object.assign({}, state, { flag, meta });
    default:
      return state;
  }
}

export function confirmationPopupActive(state = { flag: false, meta: {} }, { type, flag, meta }) {
  switch (type) {
    case TOGGLE_CONFIRMATION_POPUP:
      return Object.assign({}, state, { flag, meta });
    default:
      return state;
  }
}
