import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_AUTH_USER,
  FETCH_USER,
  FETCH_USERS,
  DELETE_USER,
  CREATE_USER,
  UPDATE_USER,
} from "actions/user";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false,
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false,
};

const processPermissions = (roles) => [
  ...new Set(roles.flatMap((r) => r.permissions.map((p) => p.slug))),
];

export function authUser(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_AUTH_USER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_AUTH_USER}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        {
          data: {
            ...payload.data,
            permissions: processPermissions(payload.data.roles),
          },
        },
        { loading: false, error: null }
      );
    case `${FETCH_AUTH_USER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
export function user(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_USER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_USER}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${FETCH_USER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}

export function users(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_USERS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_USERS}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null,
      });
    case `${FETCH_USERS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}

export function deleteUser(
  state = SINGLE_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${DELETE_USER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_USER}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${DELETE_USER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}

export function createUser(
  state = SINGLE_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${CREATE_USER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_USER}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${CREATE_USER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}

export function updateUser(
  state = SINGLE_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${UPDATE_USER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_USER}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${UPDATE_USER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
