import React from "react";
import ReactPlayer from "react-player";

import { Text } from "react-form";

const Preview = ({ value }) => {
  return value ? (
    <div>
      <ReactPlayer url={value} />
    </div>
  ) : null;
};

const VideoField = ({ label, value, ...rest }) => (
  <div className="field">
    <Preview value={value} />
    <br />
    <label className="label">{label}</label>
    <p className="control">
      <Text className="input" {...rest} type="url" />
    </p>
  </div>
);
export default VideoField;
