import React, { memo } from "react";
import { Form, Text } from "react-form";

export default memo(({ onSearchFormSubmit }) => (
  <Form onSubmit={onSearchFormSubmit} defaultValues={{ search_by: "name" }}>
    {formApi => (
      <form method="post" onSubmit={formApi.submitForm}>
        <div className="field has-addons">
          <p className="control is-expanded">
            <Text
              field="search_term"
              placeholder="Find by name"
              className="input"
              required
            />
          </p>
          <p className="control">
            <button type="submit" className="button is-info">
              Search
            </button>
          </p>
        </div>
      </form>
    )}
  </Form>
));
