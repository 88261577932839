import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_PRODUCTS,
  FETCH_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT
} from "../actions/product";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function products(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_PRODUCTS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_PRODUCTS}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_PRODUCTS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload.message
      });
    default:
      return state;
  }
}

export function product(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_PRODUCT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_PRODUCT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_PRODUCT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createProduct(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  console.log('asdfasdf')
  switch (type) {
    case `${CREATE_PRODUCT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_PRODUCT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_PRODUCT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateProduct(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_PRODUCT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_PRODUCT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_PRODUCT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload.message
      });
    default:
      return state;
  }
}

export function deleteProduct(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_PRODUCT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_PRODUCT}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_PRODUCT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
