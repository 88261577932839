/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import { Link } from "react-router-dom";

import authUtil from "utils/auth";
import userAvatar from "images/user-avatar.png";

export default memo(
  ({ authUser, handleToggleClick, hideProfileBox, activeCompany }) => (
    <nav className="navbar is-info">
      <div className="navbar-brand">
        <div className="navbar-item">
          <a
            className="button"
            onClick={e => handleToggleClick(e)("hideSidebar")}
          >
            <span className="icon is-large">
              <i className="fas fa-bars" />
            </span>
          </a>
        </div>
        <div className="navbar-item">
          <Link to={`/${activeCompany.url}`} className="navbar-item">
            <span className="has-text-light">UTBA Admin Panel</span>
          </Link>
        </div>
        <div className="navbar-burger burger">
          <span />
          <span />
          <span />
        </div>
      </div>
      <div className="navbar-menu is-hidden-touch">
        <div className="navbar-start">
          <Link to={`/${activeCompany.url}`} className="navbar-item is-size-7">
            {activeCompany.name}
          </Link>
        </div>
        <div className="navbar-end">
          <div
            className={`navbar-item has-dropdown ${
              !hideProfileBox ? "is-active" : ""
            }`}
          >
            <a
              className="navbar-link icon-less"
              onClick={e => handleToggleClick(e)("hideProfileBox")}
            >
              <span className="icon">
                <i className="fas fa-2x fa-user-circle" />
              </span>
            </a>
            <div className="box navbar-dropdown is-right">
              <div className="">
                <article className="media">
                  <div className="media-left">
                    <figure className="image is-128x128 is-rounded has-background-light">
                      <img src={userAvatar} alt="" />
                    </figure>
                  </div>
                  <div className="media-content">
                    <div className="content">
                      {!authUser.loading && (
                        <p>
                          <strong>{authUser.data.name}</strong> <br />
                          <small className="has-text-grey">
                            {authUser.data.email}
                          </small>
                          <br />
                          <small className="has-text-grey-light">
                            {authUser.data.roles &&
                              authUser.data.roles.map(role => role.slug)}
                          </small>
                        </p>
                      )}
                    </div>
                    <nav className="level is-mobile">
                      <div className="level-left">
                        <a
                          className="level-item is-small button is-danger"
                          onClick={() => {
                            authUtil.logout();
                          }}
                        >
                          <span>Logout</span>
                          <span className="icon is-small">
                            <i className="fas fa-sign-out-alt" />
                          </span>
                        </a>
                      </div>
                    </nav>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-menu is-active is-hidden-desktop">
        <div className="navbar-item">Mobile Menu</div>
      </div>
    </nav>
  )
);
