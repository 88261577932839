import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchCurrency, updateCurrency } from "actions/currency";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "pages/Currencies/Form";

class CurrencyEdit extends Component {
  componentDidMount() {
    const { activeCompany, match, fetchCurrency } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.currencyId;
    fetchCurrency(id, { company_url });
  }

  shapedDefaultValuesStructure() {
    return this.props.currency.data;
  }

  updateCurrency(id, data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      updateCurrency,
      history,
    } = this.props;

    toggleFullPageSpinner(true);

    updateCurrency(id, data)
      .then(() => {
        toggleNotification(true, {
          message: "Updated Currency Success",
          infoClassName: "is-success",
        });
        history.push(`/${activeCompany.url}/currencies`);
      })
      .catch((e) => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger",
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = (data) => {
    const { activeCompany, match } = this.props;
    const id = match.params.currencyId;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.updateCurrency(id, data);
  };

  render() {
    const { currency, activeCompany } = this.props;

    if (currency.loading) {
      return "loading";
    }
    const defaultValues = this.shapedDefaultValuesStructure();
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        defaultValues={defaultValues}
        activeCompany={activeCompany}
      />
    );
  }
}

const mapStateToProps = ({ currency }) => ({ currency });

export default connect(mapStateToProps, {
  fetchCurrency,
  updateCurrency,
  toggleFullPageSpinner,
  toggleNotification,
})(CurrencyEdit);
