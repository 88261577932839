import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash.isempty";
import Notification from "components/Notification";

import { fetchOpenCompanyByURL } from "actions/company";
import { toggleNotification } from "actions/toggle";

import Nav from "./Nav";
import authUtil from "utils/auth";
import alertUtil from "utils/alert";
import "./styles.css";

import Form from "./Form";

class Login extends React.Component {
  onClickNotificationDelete = () => {
    this.props.toggleNotification(false, {});
  };

  handleOnSubmit = ({ url }) => {
    this.props
      .fetchOpenCompanyByURL(url)
      .then(({ value }) => {
        if (value.status === 200 && !isEmpty(value.data)) {
          authUtil.login({ company_url: url });
          return;
        }
        alertUtil.unexpectedError(value);
      })
      .catch((e) => {
        if (!e.response || e.response.status !== 404) {
          this.props.toggleNotification(true, {
            message: e.message,
            infoClassName: "is-danger",
          });
        }
      });
  };

  render() {
    const {
      match: {
        params: { companyURL },
      },
    } = this.props;

    return (
      <div className="login-page">
        <Nav />
        <Notification
          {...this.props.notificationActive}
          onClickDelete={this.onClickNotificationDelete}
        />
        <div className="columns is-mobile is-content-centered">
          <div className="column" style={{ maxWidth: "45rem" }}>
            <section className="section">
              <div className="box" style={{ padding: "4rem" }}>
                <h3 className="title has-text-centered is-spaced">
                  Sign in to your company
                </h3>
                <p className="subtitle has-text-weight-light has-text-centered is-size-5 has-text-grey">
                  Enter your company's URL
                </p>
                <Form
                  onSubmit={this.handleOnSubmit}
                  company={this.props.company}
                  companyURL={companyURL}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ company, notificationActive }) => ({
  company,
  notificationActive,
});

export default connect(mapStateToProps, {
  fetchOpenCompanyByURL,
  toggleNotification,
})(Login);
