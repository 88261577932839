import React from "react";

export default function DeliveryAddress({ name, label, phone, address, note }) {
  return (
    <>
      <div>
        Label: <b>{label}</b>
      </div>
      <div>
        Name: <b>{name}</b>
      </div>
      <div>
        Phone: <b>{phone}</b>
      </div>
      <div>
        Address: <b>{address}</b>
      </div>
      <div>
        Delivery Note: <b>{address}</b>
      </div>
    </>
  );
}
