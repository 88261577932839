import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchCustomer, updateCustomer } from "actions/customer";
import { fetchOrderStatuses } from "actions/orderStatus";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";
import PurchaseHistory from "pages/Customers/PurchaseHistory";
import StatusFilter from "./StatusFilter";
import ProcessButton from "components/Process/ProcessButton";
import { exportCustomerOrders } from "api/exportCustomerOrders";
class CustomerEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { filter: null };
  }

  componentDidMount() {
    const {
      activeCompany,
      match,
      fetchCustomer,
      fetchOrderStatuses
    } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.customerId;
    fetchCustomer(id, { company_url });
    fetchOrderStatuses({ company_url });
  }

  shapedDefaultValuesStructure() {
    return this.props.customer.data;
  }

  updateCustomer(id, data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      updateCustomer,
      history
    } = this.props;

    toggleFullPageSpinner(true);

    updateCustomer(id, data)
      .then(() => {
        toggleNotification(true, {
          message: "Updated Customer Success",
          infoClassName: "is-success"
        });
        history.push(`/${activeCompany.url}/customers`);
      })
      .catch(e => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger"
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = data => {
    const { activeCompany, match } = this.props;
    const id = match.params.customerId;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.updateCustomer(id, data);
  };

  onStatusFilterChange = e => {
    this.setState({ filter: e.target.value });
    // this.fetchParams.orderStatus = e.target.value;
    // this.fetchParams.page = null;
    // this.fetchProductOrders();
  };

  render() {
    const { customer, orderStatuses } = this.props;
    const {
      match: { params }
    } = this.props;
    const { companyURL } = params;

    if (customer.loading) {
      return "loading";
    }
    const defaultValues = this.shapedDefaultValuesStructure();
    return (
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">{customer?.data.name}</p>
        </header>
        <div class="card-content">
          <h5 class="card-header-sub-title">Purchase History</h5>
          <div className="columns" style={{ marginTop: 20 }}>
            <div className="column is-4">
              Status:{" "}
              <span className="select">
                <StatusFilter
                  label="Status"
                  onChange={this.onStatusFilterChange}
                  fullwidth={false}
                  status={orderStatuses?.data.map(v => ({
                    label: v.status,
                    value: v.id
                  }))}
                />
              </span>
            </div>
            <div className="column is-1">
              <ProcessButton
                label="Export"
                onProcess={exportCustomerOrders}
                params={{ company_url: companyURL, customer_id: customer?.data.id }}
                id={customer.id}
                icon="fa-download"
              />
            </div>
          </div>
          <PurchaseHistory
            orders={
              this.state.filter
                ? customer.data?.orders.filter(
                    o => o.order_status_id == this.state.filter
                  )
                : customer.data?.orders
            }
            orderStatuses={orderStatuses}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ customer, setting, orderStatuses }) => ({
  customer,
  setting,
  orderStatuses
});

export default connect(mapStateToProps, {
  fetchCustomer,
  fetchOrderStatuses,
  updateCustomer,
  toggleFullPageSpinner,
  toggleNotification
})(CustomerEdit);
