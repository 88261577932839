import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchProductOrders, archiveProductOrder } from "actions/productOrder";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";
import capitalize from "lodash.capitalize";

import Table from "./Table";
import Pagination from "components/Pagination";
import { InstantSearch, SearchBox, PoweredBy } from "components/algolia-dom";
import MagicConnector from "components/algolia-dom/MagicConnector";
import HitComponent from "./HitComponent";
import ArchiveFilter from "./ArchiveFilter";
import StatusFilter from "./StatusFilter";
import ProcessButton from "components/Process/ProcessButton";
import { exportProductOrders } from "api/exportProductOrders";
import DateRangeFilter from "./DateRangeFilter";
import utils from "utils/date";
import ListTable from "components/ListTable";

class ProductOrderIndex extends Component {
  orderByASC = {};
  fetchParams = {
    company_url: this.props.match.params.companyURL,
    partials: "customer",
    archive: 0,
    orderStatus: capitalize(this.props.match.params.orderStatus),
    orderBy: undefined,
    page: undefined
  };

  refreshSearch = false;

  componentDidMount() {
    this.fetchProductOrders();
  }

  fetchProductOrders() {
    this.props.fetchProductOrders(this.fetchParams);
  }

  onPaginationClick = page => event => {
    this.fetchParams.page = page;
    this.fetchProductOrders();
  };

  handleProductOrderArchive = data => event => {
    this.refreshSearch = false;
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      title: data.archive ? "Archive" : "Unarchive",
      onClickConfirm: () => {
        this.archiveProductOrderProcess(data);
      }
    });
  };

  archiveProductOrderProcess({ id, archive }) {
    const {
      toggleNotification,
      toggleconfirmationPopup,
      archiveProductOrder,
      match
    } = this.props;
    const { companyURL: company_url } = match.params;
    this.refreshSearch = true;
    archiveProductOrder(id, { company_url, archive })
      .then(() => {
        this.fetchProductOrders();
        toggleNotification(true, {
          infoClassName: "is-success",
          message: `${archive ? "Archive" : "Unarchive"} Order Success`
        });
        toggleconfirmationPopup(false, {});
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
        toggleconfirmationPopup(false, {});
      });
  }

  onSearchFormSubmit = data => {
    const { companyURL: company_url } = this.props.match.params;
    const search = {};
    if (data.search_term) {
      search[data.search_by] = data.search_term;
    }
    this.props.fetchProductOrders({ company_url, ...search });
  };

  onStatusFilterChange = e => {
    this.fetchParams.orderStatus = e.target.value;
    this.fetchParams.page = null;
    this.fetchProductOrders();
  };

  onDateFilterChange = sel => {
    this.fetchParams.orderDate = `${utils.phpFormatToJS(
      sel.startDate
    )},${utils.phpFormatToJS(sel.endDate)}`;
    this.fetchProductOrders();
  };

  handleOrderClick = (field, that) => event => {
    this.orderByASC[field] = !this.orderByASC[field];
    const direction = this.orderByASC[field] ? "asc" : "desc";
    const orderBy = `${field}:${direction}`;
    this.fetchParams.orderBy = orderBy;
    this.fetchProductOrders();
  };

  handleArchiveFilterChange = event => {
    this.fetchParams.archive = event.target.value;
    this.fetchProductOrders();
  };

  render() {
    const { productOrders, setting, match } = this.props;
    const { companyURL } = match.params;

    return (
      <div className="box">
        <ListTable
          onSearchFormSubmit={this.onSearchFormSubmit}
          onPaginationClick={this.onPaginationClick}
          data={productOrders}
          options={[
            { value: "name", label: "By Name" },
            { value: "phone", label: "By Phone" }
          ]}
        >
          <div className="columns">
            <div className="column is-2">
              <ArchiveFilter
                onChange={this.handleArchiveFilterChange}
                defaultValue={this.fetchParams.archive}
              />
            </div>
            <div className="column is-2">
              <StatusFilter
                status={setting.data.order_status}
                defaultValue={this.fetchParams.orderStatus}
                onChange={this.onStatusFilterChange}
              />
            </div>

            <div className="column is-2">
              <DateRangeFilter onChange={this.onDateFilterChange} />
            </div>

            <div className="column is-1">
              <ProcessButton
                label="Export"
                onProcess={exportProductOrders}
                params={{ company_url: companyURL }}
                icon="fa-download"
              />
            </div>
          </div>

          <Table
            orderByASC={this.orderByASC}
            productOrders={productOrders}
            companyURL={companyURL}
            onClickArchive={this.handleProductOrderArchive}
            onClickOrder={this.handleOrderClick}
          />
        </ListTable>
      </div>
    );
  }
}

const mapStateToProps = ({
  authUser,
  productOrders,
  confirmationPopupActive,
  setting
}) => ({
  authUser,
  productOrders,
  confirmationPopupActive,
  setting
});

export default connect(mapStateToProps, {
  fetchProductOrders,
  archiveProductOrder,
  toggleconfirmationPopup,
  toggleNotification
})(ProductOrderIndex);
