import React from "react";
import Select from "./Select";
import InputText from "components/form-fields/InputText";

export default ({
  variantOptions,
  formApi,
  fieldApi,
  parentFieldApi,
  field,
  ...props
}) => (
  <div className="box">
    <a
      href="#/"
      className="button is-danger is-outlined is-small is-pulled-right"
      onClick={(e) => {
        e.preventDefault();
        const [, key] = field;
        parentFieldApi.removeValue("variant_options", key);
      }}
    >
      <span>Delete</span>
      <span className="icon is-small">
        <i className="fas fa-times fa-lg" />
      </span>
    </a>
    <div className="columns">
      <div className="column">
        <Select
          field="id"
          placeholder="Variant Option"
          label="Variant Option"
          options={variantOptions.data.map((v) => ({
            label: v.name,
            value: v.id,
          }))}
        />
      </div>
      <div className="column">
        <InputText field="value" placeholder="Value" label="Value" />
      </div>
    </div>
  </div>
);
