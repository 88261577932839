import React, { Component } from "react";

import Nav from "./Nav";

class FourOThree extends Component {
  render() {
    return (
      <div>
        <Nav />
        <section className="section">
          <div className="columns is-mobile is-content-centered">
            <div className="column" style={{ maxWidth: "45rem" }}>
              <div className="box">
                <div className="notification is-warning">
                  You are not authorized to this company resources. Please,{" "}
                  <strong>Logout</strong> to sign in to the another company.
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default FourOThree;
