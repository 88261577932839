/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Form, NestedField } from "react-form";
import isEmpty from "lodash.isempty";

import InputText from "components/form-fields/InputText";
import Select from "components/form-fields/Select";
import AttachmentFieldGroup from "components/form-fields/AttachmentFieldGroup";
import CustomerTierPriceGroup from "components/form-fields/CustomerTierPriceGroup";
import ProductVariantFieldGroup from "components/form-fields/ProductVariantFieldGroup";
import ImageField from "components/form-fields/ImageField";
import TagField from "components/form-fields/TagField";
import CategoryField from "components/form-fields/CategoryField";
import LocationField from "components/form-fields/LocationField";
import Checkbox from "components/form-fields/Checkbox";
import { CancelToken } from "axios";
import debounce from "lodash.debounce";

class ParentProductVariantForm extends Component {
  constructor(props) {
    super(props);
    this.removedImages = { cover: undefined, logo: undefined };
  }

  onImageRemove = name => event => {
    this.props.removeUploadedImage(name);
    this.removedImages = Object.assign(
      this.removedImages,
      {},
      { [name]: null }
    );
  };

  onImageFieldChange = name => event => {
    const files = event.target.files || event.dataTransfer.files;
    if (!files[0]) return;

    const photo = new Image();
    photo.onload = () => {
      const data = new FormData();
      data.append("file", files[0]);
      this.props.uploadImage(name, data).then(() => {
        this.props.setUploadedImagePreview(name, photo.src);
      });
    };
    photo.src = window.URL.createObjectURL(files[0]);
  };

  onTagsFieldChange = debounce(value => {
    const { activeCompany, fetchTags } = this.props;

    this.searchTagsSource = CancelToken.source();
    fetchTags(
      { company_url: activeCompany.url, search: `name:${value}`, limit: 5 },
      this.searchTagsSource.token
    );
  }, 1000);

  onFiltersFieldChange = debounce(value => {
    const { activeCompany, fetchFilterTags } = this.props;

    this.searchFilterTagsSource = CancelToken.source();
    fetchFilterTags(
      { company_url: activeCompany.url, search: `name:${value}`, limit: 5 },
      this.searchFilterTagsSource.token
    );
  }, 1000);

  onParentProductCategoriesFieldChange = debounce(value => {
    const { activeCompany, fetchParentProductCategories } = this.props;

    this.searchParentProductCategoriesSource = CancelToken.source();
    fetchParentProductCategories(
      {
        company_url: activeCompany.url,
        partials: "parent",
        search: `name:${value}`,
        limit: 5
      },
      this.searchParentProductCategoriesSource.token
    );
  }, 1000);

  onGroupsFieldChange = debounce(value => {
    const { activeCompany, fetchGroups } = this.props;

    this.searchGroupsSource = CancelToken.source();
    fetchGroups(
      { company_url: activeCompany.url, search: `name:${value}`, limit: 10 },
      this.searchGroupsSource.token
    );
  }, 1000);

  onPromotionTypeChange = promotionType => {
    if (promotionType === 1) {
      this.setState({ promotionType });
    }
  };

  onSubmit = data => {
    const { uploadedImages } = this.props;

    const shapedAttachmentDataStructure =
      data.attachments &&
      data.attachments.map(({ photo, uid, ...rest }) => {
        photo = undefined;
        if (uploadedImages[uid]) {
          photo = uploadedImages[uid].data.file;
        } else if (this.removedImages[uid] === null) {
          photo = null;
        }
        return Object.assign({}, { ...rest, photo });
      });

    const shapedParentProductVariantDataStructure =
      data.product_variants &&
      data.product_variants.map(({ cover, logo, uid, ...rest }) => {
        cover = undefined;
        logo = undefined;
        if (uploadedImages[`${uid}_cover`]) {
          cover = uploadedImages[`${uid}_cover`].data.file;
        } else if (this.removedImages[`${uid}_cover`] === null) {
          cover = null;
        }
        if (uploadedImages[`${uid}_logo`]) {
          logo = uploadedImages[`${uid}_logo`].data.file;
        } else if (this.removedImages[`${uid}_logo`] === null) {
          logo = null;
        }
        return Object.assign({}, { ...rest, cover, logo });
      });

    if (Object.keys(uploadedImages).length > 0) {
      const shapedImagesStructure = Object.keys(uploadedImages).reduce(
        (result, key) => {
          console.log(key)
          result[key] = uploadedImages[key].data.file;
          return result;
        },
        {}
      );
      data = Object.assign({}, data, this.removedImages, shapedImagesStructure);
    }

    data = Object.assign({}, data, {
      attachments: shapedAttachmentDataStructure,
      product_variants: shapedParentProductVariantDataStructure
    });

    this.props.handleFormSubmit(data);
  };

  render() {
    const {
      onImageRemove,
      onImageFieldChange,
      onTagsFieldChange,
      onFiltersFieldChange,
      onGroupsFieldChange,
      onParentProductCategoriesFieldChange,
      props: {
        hideNotifyUser,
        activeCompany,
        tags,
        filterTags,
        productCategories,
        groups,
        uploadedImagesPreview,
        uploadedImagesPercentage,
        defaultValues,
        currencies,
        setting,
        customerTiers,
        promotionTypes,
        productStatuses,
        variantOptions
      }
    } = this;

    return (
      <Form onSubmit={this.onSubmit} defaultValues={defaultValues}>
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <div className="box">
              <ImageField
                field="cover"
                label="Cover Photo"
                uploadPercentage={uploadedImagesPercentage.cover}
                preview={uploadedImagesPreview.cover}
                onChange={onImageFieldChange("cover")}
                onClickRemove={onImageRemove("cover")}
              />
              <hr />
              <ImageField
                field="logo"
                label="Logo"
                uploadPercentage={uploadedImagesPercentage.logo}
                preview={uploadedImagesPreview.logo}
                onChange={onImageFieldChange("logo")}
                onClickRemove={onImageRemove("logo")}
              />
            </div>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <InputText
                    required
                    field="title"
                    label="Title"
                    placeholder="Title"
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <InputText
                    field="sku"
                    label="SKU"
                    placeholder="SKU"
                    required
                  />
                </div>
                <div className="column">
                  <InputText
                    field="weight"
                    label="Weight"
                    placeholder="Weight"
                    required
                  />
                </div>
              </div>
              <div className="columns">
                {activeCompany.product_view !== "variant" && (
                  <div className="column">
                    <InputText
                      field="price"
                      label="Price"
                      type="number"
                      placeholder="Price"
                      required
                    />
                  </div>
                )}
                <div className="column">
                  <Select
                    field="currency_id"
                    placeholder={setting.data.currency_label}
                    label={setting.data.currency_label}
                    options={currencies.data.map(v => ({
                      label: v.name,
                      value: v.id
                    }))}
                    required
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <InputText
                    field="promotion"
                    label="Promotion"
                    placeholder="Promotion"
                  />
                </div>
                <div className="column">
                  <InputText
                    field="promotion_params"
                    label="Promotion Params"
                    placeholder="Promotion Params"
                  />
                </div>
                <div className="column">
                  <Select
                    field="promotion_type_id"
                    placeholder="Promotion Type"
                    label="Promotion Type"
                    options={promotionTypes.data.map(v => ({
                      label: v.name,
                      value: v.id
                    }))}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <InputText
                    field="released_at"
                    label="Released at"
                    type="date"
                    placeholder="Released at"
                  />
                </div>
                <div className="column">
                  <InputText
                    field="expired_at"
                    label="Expired at"
                    type="date"
                    placeholder="Expired at"
                  />
                </div>
                <div className="column">
                  <Select
                    field="product_status_id"
                    placeholder="Nothing"
                    label="Status"
                    required
                    options={productStatuses.data.map(v => ({
                      label: v.status,
                      value: v.id
                    }))}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TagField
                    field="tags"
                    label="Tags"
                    type="text"
                    onChange={onTagsFieldChange}
                    tags={tags}
                    formApi={formApi}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TagField
                    field="filterTags"
                    label="Filters"
                    type="text"
                    onChange={onFiltersFieldChange}
                    tags={filterTags}
                    formApi={formApi}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TagField
                    field="groups"
                    label="Groups"
                    type="text"
                    onChange={onGroupsFieldChange}
                    tags={groups}
                    formApi={formApi}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <CategoryField
                    field="productCategories"
                    label="ParentProduct Categories"
                    type="text"
                    onChange={onParentProductCategoriesFieldChange}
                    tags={productCategories}
                    formApi={formApi}
                    disableNewTag={true}
                  />
                </div>
              </div>
            </div>
            <div className="box is-clearfix">
              {formApi.values.product_prices &&
                formApi.values.product_prices.map((price, key) => (
                  <NestedField
                    component={CustomerTierPriceGroup}
                    customerTiers={customerTiers}
                    currencies={currencies}
                    setting={setting}
                    formApi={formApi}
                    field={["product_prices", key]}
                    key={key}
                  />
                ))}
              <p className="title is-6">
                <a
                  className="button is-info"
                  onClick={() => {
                    formApi.addValue("product_prices", {});
                  }}
                >
                  <span>Add Customer Tier Price</span>
                  <span className="icon is-small">
                    <i className="fas fa-plus fa-lg" />
                  </span>
                </a>
              </p>
            </div>
            {activeCompany.product_view === "variant" && (
              <div className="box is-clearfix">
                {formApi.values.product_variants &&
                  formApi.values.product_variants.map((variant, key) => (
                    <NestedField
                      component={ProductVariantFieldGroup}
                      photo={{
                        onImageFieldChange,
                        onImageRemove,
                        uploadedImagesPercentage,
                        uploadedImagesPreview
                      }}
                      variantOptions={variantOptions}
                      currencies={currencies}
                      productStatuses={productStatuses}
                      customerTiers={customerTiers}
                      setting={setting}
                      promotionTypes={promotionTypes}
                      formApi={formApi}
                      field={["product_variants", key]}
                      key={key}
                    />
                  ))}
                <p className="title is-6">
                  <a
                    className="button is-info"
                    onClick={() => {
                      formApi.addValue("product_variants", {});
                    }}
                  >
                    <span>Add ParentProduct Variant</span>
                    <span className="icon is-small">
                      <i className="fas fa-plus fa-lg" />
                    </span>
                  </a>
                </p>
              </div>
            )}
            <div className="box">
              <div className="columns">
                <div className="column is-half-desktop">
                  <LocationField
                    formApi={formApi}
                    field="location"
                    label="Location"
                  />
                </div>
              </div>
            </div>
            <div className="box is-clearfix">
              <Select
                fullwidth={false}
                field="text_first"
                placeholder="Priority"
                label="Priority"
                options={[
                  { label: "Image first", value: 0 },
                  { label: "Text first", value: 1 }
                ]}
              />

              {formApi.values.attachments &&
                formApi.values.attachments.map((attachment, key) => (
                  <NestedField
                    photo={{
                      onImageFieldChange,
                      onImageRemove,
                      uploadedImagesPreview,
                      uploadedImagesPercentage
                    }}
                    component={AttachmentFieldGroup}
                    formApi={formApi}
                    field={["attachments", key]}
                    key={key}
                  />
                ))}
              <p className="title is-6">
                <a
                  className="button is-info"
                  onClick={() => {
                    const { attachments } = formApi.values;
                    const weight = isEmpty(attachments)
                      ? 1
                      : attachments[attachments.length - 1].weight + 1;
                    formApi.addValue("attachments", { weight });
                  }}
                >
                  <span>Add Text/Photo</span>
                  <span className="icon is-small">
                    <i className="fas fa-plus fa-lg" />
                  </span>
                </a>
              </p>
            </div>
            {!hideNotifyUser && (
              <div className="box">
                <div className="columns">
                  <div className="column is-half-desktop">
                    <Checkbox
                      formApi={formApi}
                      label="Notify customers?"
                      field="push_notification"
                    />
                  </div>
                </div>
              </div>
            )}
            <button className="button is-fullwidth is-info is-medium">
              Submit
            </button>
          </form>
        )}
      </Form>
    );
  }
}

export default ParentProductVariantForm;
