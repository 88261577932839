/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import isEmpty from "lodash.isempty";
import dateUtils from "utils/date";

const ParentProductsTable = ({ products, companyURL, onClickDelete }) => {
  const TableOrNotFound = () => {
    if (products.loading) {
      return "is Loading";
    }
    if (isEmpty(products.data)) {
      return (
        <p className="is-size-2 has-text-centered has-text-grey">
          Found Nothing
        </p>
      );
    }
    return (
      <table className="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>SKU</th>
            <th>Stock</th>
            <th>Used</th>
            <th>Pormotion</th>
            <th>Weight</th>
            <th>Released at</th>
            <th>Expired at</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {products.data.map(product => (
            <tr key={product.id}>
              <td>{product.id}</td>
              <td>{product.title}</td>
              <td>{product.sku}</td>
              <td>{product.stock}</td>
              <td>{product.used}</td>
              <td>{product.promotion}</td>
              <td>{product.weight}</td>
              <td>{dateUtils.phpFormatToJS(product.released_at)}</td>
              <td>{dateUtils.phpFormatToJS(product.expired_at)}</td>
              <td>{product.status}</td>

              <td className="is-icon">
                <p className="buttons">
                  <Link
                    to={`/${companyURL}/parent-products/${product.id}/edit`}
                    className="button  is-link is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-edit" />
                    </span>
                    <span>Copy</span>
                  </Link>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <section className="section">
      <TableOrNotFound />
    </section>
  );
};
export default ParentProductsTable;
