import { createStore, applyMiddleware, compose } from "redux";
import promiseMiddleware from "redux-promise-middleware";

import logger from "redux-logger";
import fetchAPIMiddleware from "./fetch-api-middleware";
import rootReducer from "reducers";

const initialState = {};
const enhancers = [];
const middlewares = [fetchAPIMiddleware, promiseMiddleware()];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
  const devToolsExtension = window.devToolsExtension;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers
);

const store = createStore(rootReducer, initialState, composedEnhancers);

export default store;
