/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Form, Text } from "react-form";
import HiddenField from "components/form-fields/HiddenField";
import isEmpty from "lodash.isempty";
import find from "lodash.find";
import remove from "lodash.remove";

const tagShouldDo = (payload, categoryId, action) =>
  !isEmpty(payload.data) &&
  !payload.loading &&
  categoryId === payload.category_id &&
  action === payload.action;

const tagUndefined = (data, { data: { id, name } }) =>
  find(data, { id, name }) === undefined;

const Tags = ({ data, categoryId, createTag, detachTag, onClickRemove }) => {
  if (
    tagShouldDo(createTag, categoryId, "add") &&
    tagUndefined(data, createTag)
  ) {
    data.push(createTag.data);
  }

  if (
    tagShouldDo(detachTag, categoryId, "detach") &&
    !tagUndefined(data, detachTag)
  ) {
    remove(
      data,
      ({ id, name }) => id === detachTag.data.id && name === detachTag.data.name
    );
  }

  return data.map(({ id, name }) => {
    return (
      <div key={id} className="control">
        <div className="tags has-addons">
          <span className="tag is-white">{name}</span>
          <a // eslint-disable-line jsx-a11y/anchor-has-content
            onClick={onClickRemove(id, categoryId)}
            className="tag is-delete"
            aria-hidden
          />
        </div>
      </div>
    );
  });
};

const SuggestionList = ({ active, tags, handleSuggestionListClick }) => {
  return !isEmpty(tags) && active ? (
    <ul className={`input-suggestion`}>
      {tags.map(({ id, name }) => (
        <li key={id} onClick={handleSuggestionListClick(name)}>
          {name}
        </li>
      ))}
    </ul>
  ) : null;
};

let activeSuggestList = false;
let performCategoryId;

const InputForm = ({
  onSubmit,
  categoryId,
  createTag,
  handleTagChange,
  suggestTags
}) => {
  const realLoading = createTag.loading && categoryId === createTag.category_id;
  return (
    <Form
      onSubmit={(...params) => {
        activeSuggestList = false;
        onSubmit(...params);
      }}
    >
      {formApi => (
        <form onSubmit={formApi.submitForm} className="field has-addons">
          <div
            className={`control is-expanded input-suggestion-wrapper ${realLoading &&
              "is-loading"}`}
          >
            <Text
              required
              className="input"
              field="name"
              readOnly={realLoading}
              placeholder="Tag"
              onChange={value => {
                performCategoryId = categoryId;

                if (value.length >= 1) {
                  activeSuggestList = true;
                  handleTagChange(value);
                } else {
                  activeSuggestList = false;
                }
              }}
            />
            <SuggestionList
              active={activeSuggestList && performCategoryId === categoryId}
              tags={suggestTags.data}
              handleSuggestionListClick={name => event => {
                activeSuggestList = false;
                formApi.setValue("name", name);
              }}
            />

            <HiddenField field="category_id" defaultValue={categoryId} />
          </div>
          <div className="control">
            <button className="button is-dark" disabled={realLoading}>
              Add
            </button>
          </div>
        </form>
      )}
    </Form>
  );
};

const CategorizeList = ({
  data,
  suggestTags,
  onSubmit,
  createTag,
  detachTag,
  onClickRemove,
  handleTagChange
}) => {
  return data.map(({ id, name, tags }) => (
    <div key={id} className="column is-6-tablet is-4-desktop is-3-fullhd">
      <article className="message">
        <div className="message-header">
          <p>{name}</p>
        </div>
        <div className="message-body">
          <InputForm
            onSubmit={onSubmit}
            categoryId={id}
            createTag={createTag}
            handleTagChange={handleTagChange}
            suggestTags={suggestTags}
          />
          <div className="field is-grouped is-grouped-multiline">
            {tags && (
              <Tags
                data={tags}
                categoryId={id}
                createTag={createTag}
                detachTag={detachTag}
                onClickRemove={onClickRemove}
              />
            )}
          </div>
        </div>
      </article>
    </div>
  ));
};
export default CategorizeList;
