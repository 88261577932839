export const FETCH_PRODUCT_STATUSES = "FETCH_PRODUCT_STATUSES";
export const FETCH_PRODUCT_STATUS = "FETCH_PRODUCT_STATUS";

export function fetchProductStatuses(params = {}) {
  params = Object.assign({}, { orderBy: "id:desc" }, params);
  return {
    type: FETCH_PRODUCT_STATUSES,
    auth: true,
    payload: {
      url: "resource/product-status",
      method: "GET",
      params,
    },
  };
}

export function fetchProductStatus(id, params = {}) {
  return {
    type: FETCH_PRODUCT_STATUS,
    auth: true,
    payload: {
      url: `resource/product-status/${id}`,
      method: "GET",
      params,
    },
  };
}
