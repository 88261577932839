import decode from "jwt-decode";

const authUtils = {
  oauthBaseURL: process.env.REACT_APP_OAUTH_BASE_URL,
  apiBaseURL: process.env.REACT_APP_API_BASE_URL,
  clientID: process.env.REACT_APP_API_CLIENT_ID,
  storage: window.localStorage,
  tokenKey: "access_token",
  referrerKey: "referrer",
  companyURLKey: "company_url",

  isTokenValid: function () {
    const token = this.getToken();

    if (!token) {
      return false;
    }

    try {
      const { exp } = decode(token);
      if (exp < new Date().getTime() / 1000) {
        return false;
      }
    } catch (error) {
      return false;
    }
    return true;
  },

  logout: function () {
    this.removeToken();
    window.location.replace(`${this.oauthBaseURL}/logout`);
  },
  login: function (state = undefined) {
    const query = new URLSearchParams({
      client_id: this.clientID,
      response_type: "token",
      state: JSON.stringify(state),
    }).toString();

    window.location.replace(`${this.oauthBaseURL}/authorize?${query}`);
  },
  getToken: function () {
    return this.storage.getItem(this.tokenKey);
  },
  removeToken: function () {
    this.storage.removeItem(this.tokenKey);
  },
  setToken: function (token) {
    this.storage.setItem(this.tokenKey, token);
  },

  getReferrer: function () {
    return this.storage.getItem(this.referrerKey) || "/";
  },
  setReferrer: function (referrer) {
    this.storage.setItem(this.referrerKey, referrer);
  },

  getCompanyURL: function () {
    return this.storage.getItem(this.companyURLKey) || "/";
  },
  setCompanyURL: function (companyURL) {
    this.storage.setItem(this.companyURLKey, companyURL);
  },
};
export default authUtils;
