import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import AuthRoute from "./AuthRoute";

import Login from "pages/Login";
import LoginCallback from "pages/LoginCallback";
import NotFound from "pages/NotFound";

import Dashboard from "pages/Dashboard";

import ArticleIndex from "pages/Articles/Index";
import ArticleEdit from "pages/Articles/Edit";
import ArticleCreate from "pages/Articles/Create";

import HighlightIndex from "pages/Highlights/Index";
import HighlightEdit from "pages/Highlights/Edit";
import HighlightCreate from "pages/Highlights/Create";

import AppSettingIndex from "pages/AppSettings/Index";
import AppSettingEdit from "pages/AppSettings/Edit";
import AppSettingCreate from "pages/AppSettings/Create";

import UserIndex from "pages/Users/Index";
import UserEdit from "pages/Users/Edit";
import UserCreate from "pages/Users/Create";

import CategoryIndex from "pages/Categories/Index";
import CategoryEdit from "pages/Categories/Edit";
import CategoryCreate from "pages/Categories/Create";

import DeliveryIndex from "pages/Deliveries/Index";
import DeliveryEdit from "pages/Deliveries/Edit";
import DeliveryCreate from "pages/Deliveries/Create";

import PushNotificationIndex from "pages/PushNotifications/Index";
import PushNotificationEdit from "pages/PushNotifications/Edit";
import PushNotificationCreate from "pages/PushNotifications/Create";

import TagIndex from "pages/Tags/Index";
import TagCategorize from "pages/Tags/Categorize";

import ContactIndex from "pages/Contacts/Index";
import ContactEdit from "pages/Contacts/Edit";
import ContactCreate from "pages/Contacts/Create";

import CurrencyIndex from "pages/Currencies/Index";
import CurrencyEdit from "pages/Currencies/Edit";
import CurrencyCreate from "pages/Currencies/Create";

import CustomerTierIndex from "pages/CustomerTiers/Index";
import CustomerTierEdit from "pages/CustomerTiers/Edit";
import CustomerTierCreate from "pages/CustomerTiers/Create";

import ParentProductIndex from "pages/ParentProducts/Index";
import ParentProductEdit from "pages/ParentProducts/Edit";
import ParentProductCreate from "pages/ParentProducts/Create";

import ProductIndex from "pages/Products/Index";
import ProductEdit from "pages/Products/Edit";
import ProductCreate from "pages/Products/Create";

import VariantOptionIndex from "pages/VariantOptions/Index";
import VariantOptionEdit from "pages/VariantOptions/Edit";
import VariantOptionCreate from "pages/VariantOptions/Create";

import ProductCategoryIndex from "pages/ProductCategories/Index";
import ProductCategoryEdit from "pages/ProductCategories/Edit";
import ProductCategoryCreate from "pages/ProductCategories/Create";

import PaymentGatewayIndex from "pages/PaymentGateways/Index";
import PaymentGatewayEdit from "pages/PaymentGateways/Edit";

import CustomerIndex from "pages/Customers/Index";
import CustomerEdit from "pages/Customers/Edit";
import CustomerShow from "pages/Customers/Show";

import ProductOrderIndex from "pages/ProductOrders/Index";
import ProductOrderShow from "pages/ProductOrders/Show";

import Setting from "pages/Setting";
import About from "pages/About";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route exact path="/login" component={Login} />
        <Route exact path="/:companyURL/login" component={Login} />

        <AuthRoute exact path="/:companyURL" component={Dashboard} />

        <AuthRoute
          exact
          path="/:companyURL/articles"
          component={ArticleIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/articles/create"
          component={ArticleCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/articles/:articleId/edit"
          component={ArticleEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/highlights"
          component={HighlightIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/highlights/create"
          component={HighlightCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/highlights/:highlightId/edit"
          component={HighlightEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/app-settings"
          component={AppSettingIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/app-settings/create"
          component={AppSettingCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/app-settings/:appSettingId/edit"
          component={AppSettingEdit}
        />

        <AuthRoute exact path="/:companyURL" component={UserIndex} />
        <AuthRoute exact path="/:companyURL/users" component={UserIndex} />
        <AuthRoute
          exact
          path="/:companyURL/users/create"
          component={UserCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/users/:userId/edit"
          component={UserEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/categories"
          component={CategoryIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/categories/create"
          component={CategoryCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/categories/:categoryId/edit"
          component={CategoryEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/deliveries"
          component={DeliveryIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/deliveries/create"
          component={DeliveryCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/deliveries/:deliveryId/edit"
          component={DeliveryEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/customer-tiers"
          component={CustomerTierIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/customer-tiers/create"
          component={CustomerTierCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/customer-tiers/:customerTierId/edit"
          component={CustomerTierEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/product-categories"
          component={ProductCategoryIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/product-categories/create"
          component={ProductCategoryCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/product-categories/:productCategoryId/edit"
          component={ProductCategoryEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/payment-gateways"
          component={PaymentGatewayIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/payment-gateways/:paymentGatewayId/edit"
          component={PaymentGatewayEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/variant-options"
          component={VariantOptionIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/variant-options/create"
          component={VariantOptionCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/variant-options/:variantOptionId/edit"
          component={VariantOptionEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/push-notifications"
          component={PushNotificationIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/push-notifications/create"
          component={PushNotificationCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/push-notifications/:pushNotificationId/edit"
          component={PushNotificationEdit}
        />

        <AuthRoute exact path="/:companyURL/tags" component={TagIndex} />
        <AuthRoute
          exact
          path="/:companyURL/tags/categorize"
          component={TagCategorize}
        />

        <AuthRoute
          exact
          path="/:companyURL/currencies"
          component={CurrencyIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/currencies/create"
          component={CurrencyCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/currencies/:currencyId/edit"
          component={CurrencyEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/contacts"
          component={ContactIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/contacts/create"
          component={ContactCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/contacts/:contactId/edit"
          component={ContactEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/products"
          component={ProductIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/products/create"
          component={ProductCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/products/:productId/edit"
          component={ProductEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/parent-products"
          component={ParentProductIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/parent-products/create"
          component={ParentProductCreate}
        />
        <AuthRoute
          exact
          path="/:companyURL/parent-products/:productId/edit"
          component={ParentProductEdit}
        />

        <AuthRoute
          exact
          path="/:companyURL/customers"
          component={CustomerIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/customers/:customerId/edit"
          component={CustomerEdit}
        />
        <AuthRoute
          exact
          path="/:companyURL/customers/:customerId/orders"
          component={CustomerShow}
        />

        <AuthRoute
          exact
          path="/:companyURL/product-orders"
          component={ProductOrderIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/product-orders/status/:orderStatus"
          component={ProductOrderIndex}
        />
        <AuthRoute
          exact
          path="/:companyURL/product-orders/:productOrderId/show"
          component={ProductOrderShow}
        />

        <AuthRoute exact path="/:companyURL/setting" component={Setting} />
        <AuthRoute exact path="/:companyURL/about" component={About} />

        <Route exact path="/login/oauth/callback" component={LoginCallback} />
        <AuthRoute path="/:companyURL" component={NotFound} />
      </Switch>
    </Router>
  );
};
export default Routes;
