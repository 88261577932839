import React from "react";
import { Text } from "react-form";

const InputTextField = ({ label, required, ...rest }) => {
  return (
    <div className="field">
      <label className="label">
        {label}
        {required && <span className="required">*</span>}
      </label>
      <p className="control">
        <Text className="input" required={required} {...rest} />
      </p>
      {/* <p className={`help ${helpClass}`}>{helpMessage}</p> */}
    </div>
  );
};

export default InputTextField;
