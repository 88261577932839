/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { Highlight } from "react-instantsearch-dom";
import { Link } from "react-router-dom";

import TagsHighlight from "components/algolia-dom/TagsHighlight";

const HitComponent = ({ hit, companyURL, onClickDelete }) => {
  return (
    <div className="content is-small">
      <h1>
        <Highlight attribute="title" hit={hit} tagName="span" />
      </h1>
      <h3
        style={{
          marginTop: "-10px"
        }}
        className="has-text-grey"
      >
        <Highlight attribute="sku" hit={hit} tagName="span" />
      </h3>

      <div className="tags are-medium">
        <TagsHighlight attribute="tags" hit={hit} />
      </div>
      <p className="buttons">
        <Link
          to={`/${companyURL}/products/${hit.id}/edit`}
          className="button  is-link is-small"
        >
          <span className="icon">
            <i className="fas fa-edit" />
          </span>
          <span>Edit</span>
        </Link>
        <a
          onClick={onClickDelete(hit.id)}
          to={`/${companyURL}/products/${hit.id}/delete`}
          className="button is-danger is-small"
        >
          <span className="icon">
            <i className="fas fa-trash" />
          </span>
          <span>Delete</span>
        </a>
      </p>
      <hr />
    </div>
  );
};
export default HitComponent;
