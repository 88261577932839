import React, { memo } from "react";
import PaginationLink from "./PaginationLink";
import PaginationEllipsis from "./PaginationEllipsis";

export default memo(({ onClick, currentPage, lastPage, range }) => {
  const list = [];
  range = range || 4;
  const halfOfRange = Math.floor(range / 2);

  list.push(
    <PaginationLink
      key="1"
      page="1"
      onClick={onClick}
      isCurrent={1 === currentPage}
    />
  );

  if (currentPage > range && currentPage - range !== 1) {
    list.push(<PaginationEllipsis key="start-ellipsis" />);
  }

  for (
    let index = currentPage - halfOfRange;
    index <= currentPage + halfOfRange;
    index++
  ) {
    if (index > 1 && index < lastPage) {
      list.push(
        <PaginationLink
          key={index}
          page={index}
          onClick={onClick}
          isCurrent={index === currentPage}
        />
      );
    }
  }

  if (currentPage < lastPage - range + 1 && currentPage + range !== lastPage) {
    list.push(<PaginationEllipsis key="end-ellipsis" />);
  }

  list.push(
    <PaginationLink
      key={lastPage}
      page={lastPage}
      onClick={onClick}
      isCurrent={lastPage === currentPage}
    />
  );

  return <ul className="pagination-list">{list}</ul>;
});
