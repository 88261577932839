import React, { useState, memo } from "react";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.less";
import "rsuite/Stack/styles/index.less";

export default ({ onChange, range = {} }) => {
  const [selectionRange, setSelectionRange] = useState(range);

  const onSelectionChange = range => {
    setSelectionRange(range);
    onChange(range);
  };

  return (
    <React.Fragment>
      <DateRangePicker
        hoverRange="month"
        ranges={[selectionRange]}
        showDateDisplay={false}
        onChange={onSelectionChange}
      />
    </React.Fragment>
  );
};
