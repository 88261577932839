export const FETCH_GROUP = "FETCH_GROUP";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const DELETE_GROUP = "DELETE_GROUP";
export const CREATE_GROUP = "CREATE_GROUP";
export const DETACH_GROUP = "DETACH_GROUP";

export function fetchGroup(id, params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_GROUP,
    auth: true,
    payload: {
      url: `resource/groups/${id}`,
      method: "GET",
      params
    }
  };
}

export function fetchGroups(params = {}, cancelToken) {
  params = Object.assign({}, params);
  return {
    type: FETCH_GROUPS,
    auth: true,
    payload: {
      url: "resource/groups",
      method: "GET",
      params,
      cancelToken
    }
  };
}

export function deleteGroup(id, data = {}) {
  return {
    type: DELETE_GROUP,
    auth: true,
    payload: {
      url: `resource/groups/${id}`,
      method: "DELETE",
      data
    }
  };
}

export function createGroup(data = {}) {
  return {
    type: CREATE_GROUP,
    auth: true,
    payload: {
      url: "resource/groups",
      method: "POST",
      data
    }
  };
}
