/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";

export default memo(({ productOrder, onClickArchive }) => {
  const archive = productOrder.archived_at !== null;
  return (
    <a
      onClick={onClickArchive({ id: productOrder.id, archive: !archive })}
      className={`button is-small ${archive ? "is-success" : "is-warning"}`}
    >
      <span className="icon">
        <i className={`fas fa-${archive ? "undo" : "archive"}`} />
      </span>
      <span>{archive ? "Unarchive" : "Archive"} </span>
    </a>
  );
});
