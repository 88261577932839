import dateUtils from "utils/date";
export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";

export function fetchCustomers(params = {}) {
  if (params.date) {
    params = Object.assign(
      {},
      {
        partials: `customerTier|orders(status_id=5!&createdAtGt=${dateUtils.ISOToDateTime(
          params.date[0]
        )}&createdAtLt=${dateUtils.ISOToDateTime(params.date[1])})`,
        orderBy: "id:desc"
      },
      params
    );
  } else {
    params = Object.assign(
      {},
      { partials: `customerTier|orders(status_id=5)`, orderBy: "id:desc" },
      params
    );
  }
  return {
    type: FETCH_CUSTOMERS,
    auth: true,
    payload: {
      url: "resource/customers",
      method: "GET",
      params
    }
  };
}

export function fetchCustomer(id, params = {}) {
  params = Object.assign({}, { partials: "addresses|orders" }, params);
  return {
    type: FETCH_CUSTOMER,
    auth: true,
    payload: {
      url: `resource/customers/${id}`,
      method: "GET",
      params
    }
  };
}

export function createCustomer(data = {}) {
  return {
    type: CREATE_CUSTOMER,
    auth: true,
    payload: {
      url: "resource/customers",
      method: "POST",
      data
    }
  };
}

export function updateCustomer(id, data = {}) {
  return {
    type: UPDATE_CUSTOMER,
    auth: true,
    payload: {
      url: `resource/customers/${id}`,
      method: "PUT",
      data
    }
  };
}

export function deleteCustomer(id, data = {}) {
  return {
    type: DELETE_CUSTOMER,
    auth: true,
    payload: {
      url: `resource/customers/${id}`,
      method: "DELETE",
      data
    }
  };
}
