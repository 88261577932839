import React from "react";
import formatCurrency from "utils/format-currency";

const ProductImage = ({ image, size = 144 }) => {
  if (!image) {
    return null;
  }

  const src = image[size] ?? image["original"];
  return <img src={src} alt="" />;
};

const Rows = ({ baskets }) => {
  return baskets.map(
    ({
      id,
      quantity,
      unit_price,
      product,
      product_variant,
      promotion,
      amount,
      sku,
    }) => {
      return (
        <tr key={id}>
          <td>
            <p>{sku}</p>
            <ProductImage image={product.logo} />
          </td>
          <td>
            <p>{product.title}</p>
            <p>{product_variant?.title}</p>
          </td>
          <td>{unit_price}</td>
          <td>{quantity}</td>
          <td>{promotion ?? "-"}</td>
          <td>{formatCurrency(amount)}</td>
        </tr>
      );
    }
  );
};

const ProductOrderBasketsTable = ({ loading, baskets }) => {
  if (loading) {
    return "loading...";
  }

  if (baskets) {
    return (
      <table className="table" style={{ minWidth: "40%", marginTop: 10 }}>
        <thead>
          <tr>
            <td>SKU</td>
            <td>Title</td>
            <td>Unit Price</td>
            <td>Quantity</td>
            <td>Discount</td>
            <td>Amount</td>
          </tr>
        </thead>
        <tbody>
          <Rows baskets={baskets} />
        </tbody>
      </table>
    );
  }
  return null;
};
export default ProductOrderBasketsTable;
