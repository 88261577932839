import React, { memo } from "react";
import spinner from "images/full-load-spinner.gif";

export default memo(({ active }) => {
  const containerStyles = {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    zIndex: "9999",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100vw",
    position: "fixed",
    margin: "0"
  };

  const spinnerStyles = {
    position: "absolute",
    display: "inline-block",
    right: "10vw",
    bottom: "10vh"
  };

  return active ? (
    <div style={containerStyles}>
      <div style={spinnerStyles}>
        <p>
          <span style={{ position: "relative", top: "-8px", left: "-8px" }}>
            Loading...{" "}
          </span>
          <img src={spinner} width="30" alt="" />
        </p>
      </div>
    </div>
  ) : null;
});
