import React from "react";
import Pagination from "components/Pagination";
import SearchForm from "components/SearchForm";

export default ({
  onSearchFormSubmit,
  children,
  data = [],
  options = [],
  onPaginationClick = () => {}
}) => {
  return (
    <>
      {onSearchFormSubmit && (
        <SearchForm onSearchFormSubmit={onSearchFormSubmit} options={options} />
      )}
      {children}
      <Pagination
        onClick={onPaginationClick}
        currentPage={data.current_page}
        lastPage={data.last_page}
        range="4"
        modifyClasses="is-right"
      />
    </>
  );
};
