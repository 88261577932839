import React, { Component } from "react";

import Nav from "./Nav";

class FiveHundred extends Component {
  render() {
    return (
      <div>
        <Nav />
        <section className="section">
          <div className="columns is-mobile is-content-centered">
            <div className="column" style={{ maxWidth: "45rem" }}>
              <div className="box">
                <div className="notification is-danger">Internal Error</div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default FiveHundred;
