export const FETCH_CUSTOMER_TIERS = "FETCH_CUSTOMER_TIERS";
export const FETCH_CUSTOMER_TIER = "FETCH_CUSTOMER_TIER";
export const CREATE_CUSTOMER_TIER = "CREATE_CUSTOMER_TIER";
export const UPDATE_CUSTOMER_TIER = "UPDATE_CUSTOMER_TIER";
export const DELETE_CUSTOMER_TIER = "DELETE_CUSTOMER_TIER";

export function fetchCustomerTiers(params = {}) {
  params = Object.assign({}, { orderBy: "id:desc" }, params);
  return {
    type: FETCH_CUSTOMER_TIERS,
    auth: true,
    payload: {
      url: "resource/customer-tiers",
      method: "GET",
      params
    }
  };
}

export function fetchCustomerTier(id, params = {}) {
  return {
    type: FETCH_CUSTOMER_TIER,
    auth: true,
    payload: {
      url: `resource/customer-tiers/${id}`,
      method: "GET",
      params
    }
  };
}

export function createCustomerTier(data = {}) {
  return {
    type: CREATE_CUSTOMER_TIER,
    auth: true,
    payload: {
      url: "resource/customer-tiers",
      method: "POST",
      data
    }
  };
}

export function updateCustomerTier(id, data = {}) {
  return {
    type: UPDATE_CUSTOMER_TIER,
    auth: true,
    payload: {
      url: `resource/customer-tiers/${id}`,
      method: "PUT",
      data
    }
  };
}

export function deleteCustomerTier(id, data = {}) {
  return {
    type: DELETE_CUSTOMER_TIER,
    auth: true,
    payload: {
      url: `resource/customer-tiers/${id}`,
      method: "DELETE",
      data
    }
  };
}
