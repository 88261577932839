import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import { FETCH_OTP_COUNT } from "../actions/otp";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};

export function otpCount(
  state = SINGLE_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${FETCH_OTP_COUNT}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_OTP_COUNT}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${FETCH_OTP_COUNT}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
