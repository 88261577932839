import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import { FETCH_COMPANIES, FETCH_COMPANY } from "actions/company";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function companies(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_COMPANIES}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_COMPANIES}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, { loading: false, error: null });
    case `${FETCH_COMPANIES}_${REJECTED}`:
      return Object.assign({}, state, { loading: false, error: payload });
    default:
      return state;
  }
}

export function company(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_COMPANY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_COMPANY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_COMPANY}_${REJECTED}`:
      return Object.assign({}, state, { loading: false, error: payload });
    default:
      return state;
  }
}
