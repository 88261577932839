import React from "react";
import authUtil from "utils/auth";
import alertUtil from "utils/alert";

function Callback({ location, history }) {
  const params = new URLSearchParams(location.hash.substr(1));
  const access_token = params.get("access_token");
  const { company_url } = JSON.parse(params.get("state"));
  const redirectTo = `/${company_url}`;

  try {
    authUtil.setToken(access_token);
  } catch (error) {
    alertUtil.unexpectedError(error);
  } finally {
    history.push(redirectTo);
  }
  return <p className="is-size-6 loading-text">Redirect...</p>;
}

export default Callback;
