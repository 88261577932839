import React, { Component } from "react";
import { connect } from "react-redux";

import { createHighlight } from "actions/highlight";
import { fetchHighlightActions } from "actions/highlightAction";
import { fetchTags } from "actions/tag";
import { fetchFilterTags } from "actions/filterTag";
import { fetchGroups } from "actions/group";
import { fetchProductCategories } from "actions/productCategory";
import {
  uploadImage,
  setUploadedImagePreview,
  removeUploadedImage
} from "actions/image";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";
import Form from "pages/Highlights/Form";

class HighlightCreate extends Component {
  componentWillUnmount() {
    this.props.removeUploadedImage("cover");
    this.props.removeUploadedImage("logo");
  }

  componentDidMount() {
    const { activeCompany, fetchHighlightActions } = this.props;
    const company_url = activeCompany.url;
    fetchHighlightActions({ company_url });
  }

  handleFormSubmit = data => {
    const { activeCompany } = this.props;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.createHighlight(data);
  };

  createHighlight(data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      createHighlight,
      history
    } = this.props;
    toggleFullPageSpinner(true);
    createHighlight(data)
      .then(() => {
        toggleNotification(true, {
          message: "Created Highlight Success",
          infoClassName: "is-success"
        });
        history.push(`/${activeCompany.url}/highlights`);
      })
      .catch(e => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger"
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  render() {
    const {
      uploadedImagesPreview,
      uploadedImagesPercentage,
      uploadedImages,
      removeUploadedImage,
      uploadImage,
      setUploadedImagePreview,
      fetchTags,
      fetchGroups,
      fetchFilterTags,
      fetchProductCategories,
      productCategories,
      tags,
      groups,
      filterTags,
      activeCompany,
      highlightActions
    } = this.props;
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        uploadImage={uploadImage}
        uploadedImages={uploadedImages}
        setUploadedImagePreview={setUploadedImagePreview}
        removeUploadedImage={removeUploadedImage}
        uploadedImagesPreview={uploadedImagesPreview}
        uploadedImagesPercentage={uploadedImagesPercentage}
        fetchProductCategories={fetchProductCategories}
        productCategories={productCategories}
        tags={tags}
        groups={groups}
        filterTags={filterTags}
        fetchTags={fetchTags}
        fetchGroups={fetchGroups}
        fetchFilterTags={fetchFilterTags}
        activeCompany={activeCompany}
        highlightActions={highlightActions}
        defaultValues={{
          tags: [],
          filterTags: [],
          groups: [],
          productCategories: []
        }} // ::TK HOT FIX
      />
    );
  }
}

const mapStateToProps = ({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  tags,
  groups,
  filterTags,
  productCategories,
  highlightActions
}) => ({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  tags,
  groups,
  productCategories,
  filterTags,
  highlightActions
});

export default connect(mapStateToProps, {
  createHighlight,
  uploadImage,
  removeUploadedImage,
  setUploadedImagePreview,
  toggleFullPageSpinner,
  toggleNotification,
  fetchProductCategories,
  fetchTags,
  fetchHighlightActions,
  fetchFilterTags,
  fetchGroups
})(HighlightCreate);
