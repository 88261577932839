/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import isEmpty from "lodash.isempty";
import ArchiveButton from "./ArchiveButton";
import DateUtils from "utils/date";
import formatCurrency from "utils/format-currency";

const SortIcon = ({ value }) => {
  const icon =
    value === true ? "sort-down" : value === false ? "sort-up" : "sort";

  return (
    <span className="icon has-text-info">
      <i className={`fas fa-${icon}`} />
    </span>
  );
};

const ProductOrdersTable = ({
  productOrders,
  companyURL,
  orderByASC,
  onClickArchive,
  onClickOrder,
}) => {
  const TableOrNotFound = () => {
    if (productOrders.loading) {
      return "is Loading";
    }
    if (isEmpty(productOrders.data)) {
      return (
        <p className="is-size-2 has-text-centered has-text-grey">
          Found Nothing
        </p>
      );
    }
    return (
      <table className="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>ID</th>
            <th>
              <a
                onClick={onClickOrder("created_at")}
                style={{ display: "inline-flex" }}
              >
                <span>Ordered Date</span>
                <SortIcon value={orderByASC.created_at} />
              </a>
            </th>
            <th>Status</th>
            <th>Name</th>
            <th>Phone number</th>
            <th>Amount</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {productOrders.data.map((productOrder) => (
            <tr key={productOrder.id}>
              <td>{productOrder.id}</td>
              <td>{DateUtils.ISOToDateTime(productOrder.created_at)}</td>
              <td>{productOrder.status}</td>
              <td>
                {productOrder.customer ? (
                  <Link
                    to={`/${companyURL}/customers/${productOrder.customer_id}/edit`}
                    className="is-link is-small"
                  >
                    {productOrder.customer.name}
                  </Link>
                ) : (
                  productOrder.name
                )}
              </td>
              <td>
                {productOrder.customer
                  ? productOrder.customer.phone
                  : productOrder.phone_number}
              </td>
              <td>{formatCurrency(productOrder.amount)}</td>

              <td className="is-icon">
                <p className="buttons">
                  <Link
                    to={`/${companyURL}/product-orders/${productOrder.id}/show`}
                    className="button  is-link is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-info-circle" />
                    </span>
                    <span>Full info</span>
                  </Link>
                  <ArchiveButton
                    productOrder={productOrder}
                    customer
                    onClickArchive={onClickArchive}
                  />
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <section className="section">
      <TableOrNotFound />
    </section>
  );
};
export default ProductOrdersTable;
