import React, { useState, useEffect, memo } from "react";
import { DateRangePicker } from "react-date-range";
import DateUtils from "utils/date";
import OrderStatus from "./OrderStatus";
// import "react-date-range/dist/styles.css"; // main style file
// import "react-date-range/dist/theme/default.css"; // theme css file

export default memo(
  ({
    summary,
    range,
    company_url,
    fetchProductOrderSummaryByStatus,
    fetchOtpCount,
    otpCount
  }) => {
    const [selectionRange, setSelectionRange] = useState(range);

    useEffect(() => {
      fetchProductOrderSummaryByStatus({
        company_url,
        start_date: DateUtils.ISOToDateTime(selectionRange.startDate),
        end_date: DateUtils.ISOToDateTime(selectionRange.endDate)
      });
      fetchOtpCount({
        company_url,
        between: `${DateUtils.ISOToDateTime(
          selectionRange.startDate
        )}:${DateUtils.ISOToDateTime(selectionRange.endDate)}`
      });
    }, [
      company_url,
      selectionRange,
      fetchProductOrderSummaryByStatus,
      fetchOtpCount
    ]);

    const onSelectionChange = range => {
      setSelectionRange(range.range1);
    };

    return (
      <React.Fragment>
        <div className="summary-filter column">
          <DateRangePicker
            ranges={[selectionRange]}
            showDateDisplay={false}
            onChange={onSelectionChange}
          />
        </div>
        <OrderStatus company_url={company_url} summary={summary} />
      </React.Fragment>
    );
  }
);
