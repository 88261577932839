/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import InputText from "components/form-fields/InputText";
import Select from "./Select";
import uniqid from "uniqid";
import HiddenField from "components/form-fields/HiddenField";

export default memo(
  ({
    formApi,
    fieldApi,
    parentFieldApi,
    field,
    setting,
    customerTiers,
    currencies
  }) => {
    const [, key] = field;
    return (
      <div className="box">
        <a
          href="#/"
          className="button is-danger is-outlined is-small is-pulled-right"
          onClick={e => {
            e.preventDefault();
            (parentFieldApi ? parentFieldApi : formApi).removeValue(
              "product_prices",
              key
            );
          }}
        >
          <span>Delete</span>
          <span className="icon is-small">
            <i className="fas fa-times fa-lg" />
          </span>
        </a>
        <div className="columns">
          <div className="column">
            <Select
              field="customer_tier_id"
              placeholder="Customer Tier"
              label="Customer Tier"
              options={customerTiers.data.map(v => ({
                label: v.name,
                value: v.id
              }))}
              required
            />
          </div>
          <div className="column">
            <InputText
              field="price"
              label="Price"
              type="number"
              placeholder="Price"
              required
            />
          </div>
          <div className="column">
            <Select
              field="currency_id"
              placeholder={setting.data.currency_label}
              label={setting.data.currency_label}
              options={currencies.data.map(v => ({
                label: v.name,
                value: v.id
              }))}
              required
            />
          </div>
        </div>
        <HiddenField field="uid" defaultValue={uniqid.time("product_price_")} />
      </div>
    );
  }
);
