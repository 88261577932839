export const FETCH_HIGHLIGHTS = "FETCH_HIGHLIGHTS";
export const FETCH_HIGHLIGHT = "FETCH_HIGHLIGHT";
export const CREATE_HIGHLIGHT = "CREATE_HIGHLIGHT";
export const UPDATE_HIGHLIGHT = "UPDATE_HIGHLIGHT";
export const DELETE_HIGHLIGHT = "DELETE_HIGHLIGHT";

export function fetchHighlights(params = {}) {
  params = Object.assign({}, { partials: "attachments|user", orderBy: "id:desc" }, params);
  return {
    type: FETCH_HIGHLIGHTS,
    auth: true,
    payload: {
      url: "resource/highlights",
      method: "GET",
      params
    }
  };
}

export function fetchHighlight(id, params = {}) {
  params = Object.assign({}, { partials: "attachments|user|tags|filterTags|productCategories(parent)|groups|actions" }, params);
  return {
    type: FETCH_HIGHLIGHT,
    auth: true,
    payload: {
      url: `resource/highlights/${id}`,
      method: "GET",
      params
    }
  };
}

export function createHighlight(data = {}) {
  return {
    type: CREATE_HIGHLIGHT,
    auth: true,
    payload: {
      url: "resource/highlights",
      method: "POST",
      data
    }
  };
}

export function updateHighlight(id, data = {}) {
  return {
    type: UPDATE_HIGHLIGHT,
    auth: true,
    payload: {
      url: `resource/highlights/${id}`,
      method: "PUT",
      data
    }
  };
}

export function deleteHighlight(id, data = {}) {
  return {
    type: DELETE_HIGHLIGHT,
    auth: true,
    payload: {
      url: `resource/highlights/${id}`,
      method: "DELETE",
      data
    }
  };
}
