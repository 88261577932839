import React, { memo } from "react";
import trim from "lodash.trim";
import { Link } from "react-router-dom";
import Capitalize from "lodash.capitalize";

export default memo(({ url }) => {
  const breadcrumbs = trim(url, "/").split("/");
  return (
    <nav className="breadcrumb is-small" aria-label="breadcrumbs">
      <ul>
        {breadcrumbs.map((breadcrumb, index) => {
          const url = "/" + breadcrumbs.slice(0, index + 1).join("/");
          breadcrumb =
            index === 0
              ? "Home"
              : breadcrumb
                  .split("-")
                  .map(b => {
                    return Capitalize(b);
                  })
                  .join(" ");

          return (
            <li
              key={breadcrumb}
              className={index === breadcrumbs.length - 1 ? `is-active` : ""}
            >
              <Link to={url}>{breadcrumb}</Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
});
