import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchCustomers, deleteCustomer } from "actions/customer";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Table from "./Table";
import Pagination from "components/Pagination";
import HitComponent from "./HitComponent";
import { InstantSearch, SearchBox, PoweredBy } from "components/algolia-dom";
import MagicConnector from "components/algolia-dom/MagicConnector";
import { getDownloadResultUrl } from "api/fetchExportStatus";
import ProcessButton from "components/Process/ProcessButton";
import { exportCustomers } from "api/exportCustomers";
import DateFilter from "./DateFilter";
import ListTable from "components/ListTable";

class CustomerIndex extends Component {
  excludeRecords = [];
  refreshSearch = false;

  fetchParams = {
    company_url: this.props.match.params.companyURL,
    page: undefined
  };

  refreshSearch = false;

  componentDidMount() {
    this.fetchCustomers();
  }

  fetchCustomers() {
    this.props.fetchCustomers(this.fetchParams);
  }

  onPaginationClick = page => event => {
    this.fetchParams.page = page;
    this.fetchCustomers();
  };

  onSearchFormSubmit = data => {
    const { companyURL: company_url } = this.props.match.params;
    const search = {};
    if (data.search_term) {
      search[data.search_by] = data.search_term;
    }
    this.props.fetchCustomers({ company_url, ...search });
  };

  handleCustomerDelete = id => event => {
    this.refreshSearch = false;
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      onClickConfirm: () => {
        this.deleteCustomerProcess(id);
      }
    });
  };

  afterImported = r => {
    if (r.success) {
      toggleNotification(true, {
        infoClassName: "is-success",
        message: "Import Customer Success"
      });
      this.props.fetchCustomers(this.props.match.params.company_url);
    } else {
      toggleNotification(true, {
        infoClassName: "is-danger",
        message: "Import Customer Failed"
      });
      window.open(
        getDownloadResultUrl(r.downloadResult, this.props.match.params)
      );
    }
  };

  deleteCustomerProcess(id) {
    const {
      toggleNotification,
      toggleconfirmationPopup,
      deleteCustomer,
      match
    } = this.props;
    const { companyURL: company_url } = match.params;
    deleteCustomer(id, { company_url })
      .then(() => {
        this.fetchCustomers();
        this.excludeRecords.push(id);
        this.refreshSearch = true;
        toggleNotification(true, {
          infoClassName: "is-success",
          message: "Delete Customer Success"
        });
        toggleconfirmationPopup(false, {});
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
        toggleconfirmationPopup(false, {});
      });
  }

  onDateFilterChange = range => {
    this.fetchParams.date = range;
    this.fetchParams.page = null;
    this.fetchCustomers();
  };

  render() {
    const {
      customers,
      setting,
      match: { params }
    } = this.props;
    const { companyURL } = params;

    return (
      <div>
        <div className="box">
          <ListTable
            onSearchFormSubmit={this.onSearchFormSubmit}
            onPaginationClick={this.onPaginationClick}
            data={customers}
            options={[
              { value: "name", label: "By Name" },
              { value: "phone", label: "By Phone" }
            ]}
          >
            <div className="columns">
              <div className="column is-3">
                <DateFilter onChange={this.onDateFilterChange} />
              </div>
              <div className="column is-1">
                <ProcessButton
                  label="Export"
                  onProcess={exportCustomers}
                  params={this.fetchParams}
                  icon="fa-download"
                />
              </div>
            </div>
            <Table
              customers={customers}
              companyURL={companyURL}
              date={this.fetchParams.date}
              onClickDelete={this.handleCustomerDelete}
            />
          </ListTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  authUser,
  customers,
  confirmationPopupActive,
  setting
}) => ({
  authUser,
  customers,
  confirmationPopupActive,
  setting
});

export default connect(mapStateToProps, {
  fetchCustomers,
  deleteCustomer,
  toggleconfirmationPopup,
  toggleNotification
})(CustomerIndex);
