import React, { Component } from "react";
import { Form } from "react-form";

import InputText from "components/form-fields/InputText";
import Select from "components/form-fields/Select";

class UserForm extends Component {
  render() {
    const {
      props: { defaultValues, roles }
    } = this;

    if (roles.loading) {
      return "is loading..";
    }

    let roleOptions = roles.data.map(({ id, name }) =>
      Object.assign({}, { label: name, value: id })
    );
    roleOptions.unshift({ label: "Choose role", value: "" });

    const roleIdValidate = value => ({
      error: !value ? "Please select a role in the list." : null
    });

    return (
      <Form
        onSubmit={this.props.handleFormSubmit}
        defaultValues={defaultValues}
      >
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <InputText
                    disabled={defaultValues}
                    required
                    type="email"
                    field="email"
                    label="Email"
                    placeholder="Email"
                  />
                </div>
                <div className="column">
                  <Select
                    validate={roleIdValidate}
                    required
                    field="role_id"
                    placeholder={false}
                    label="Role"
                    options={roleOptions}
                  />
                  {formApi.errors && formApi.errors.role_id && (
                    <p className="help is-danger">{formApi.errors.role_id}</p>
                  )}
                </div>
              </div>
            </div>

            <button className="button is-fullwidth is-info is-medium">
              Submit
            </button>
          </form>
        )}
      </Form>
    );
  }
}
export default UserForm;
