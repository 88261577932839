import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchProductOrder, updateProductOrder } from "actions/productOrder";
import { fetchOrderStatuses } from "actions/orderStatus";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";
import Form from "./Form";

class ProductOrderShow extends Component {
  constructor(props) {
    super(props);
    this.$map = React.createRef();
  }

  componentDidMount() {
    const {
      activeCompany,
      match,
      fetchProductOrder,
      fetchOrderStatuses
    } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.productOrderId;
    fetchOrderStatuses({ company_url });
    fetchProductOrder(id, {
      company_url
    });
  }

  onSubmit = data => {
    const {
      toggleNotification,
      updateProductOrder,
      history,
      activeCompany
    } = this.props;
    updateProductOrder(data.id, data)
      .then(() => {
        toggleNotification(true, {
          message: "Update Order Success",
          infoClassName: "is-success"
        });
        history.push(`/${activeCompany.url}/product-orders`);
      })
      .catch(e => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger"
        });
      })
      .then(() => toggleFullPageSpinner(false));
  };

  render() {
    const {
      productOrder: { data, loading },
      orderStatuses,
      activeCompany
    } = this.props;

    if (loading) {
      return "loading";
    }

    return (
      <Form
        data={data}
        loading={loading}
        orderStatuses={orderStatuses}
        onSubmit={this.onSubmit}
        companyURL={activeCompany.url}
      />
    );
  }
}

const mapStateToProps = ({ productOrder, orderStatuses, tags }) => ({
  productOrder,
  orderStatuses,
  tags
});

export default connect(mapStateToProps, {
  fetchProductOrder,
  fetchOrderStatuses,
  updateProductOrder,
  toggleNotification
})(ProductOrderShow);
