import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchPushNotification, updatePushNotification } from "actions/pushNotification";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "pages/PushNotifications/Form";

class PushNotificationEdit extends Component {
  componentDidMount() {
    const { activeCompany, match, fetchPushNotification } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.pushNotificationId;
    fetchPushNotification(id, { company_url });
  }

  shapedDefaultValuesStructure() {
    return this.props.pushNotification.data;
  }

  updatePushNotification(id, data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      updatePushNotification,
      history,
    } = this.props;

    toggleFullPageSpinner(true);

    updatePushNotification(id, data)
      .then(() => {
        toggleNotification(true, {
          message: "Updated PushNotification Success",
          infoClassName: "is-success",
        });
        history.push(`/${activeCompany.url}/pushNotifications`);
      })
      .catch((e) => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger",
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = (data) => {
    const { activeCompany, match } = this.props;
    const id = match.params.pushNotificationId;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.updatePushNotification(id, data);
  };

  render() {
    const { pushNotification, activeCompany } = this.props;

    if (pushNotification.loading) {
      return "loading";
    }
    const defaultValues = this.shapedDefaultValuesStructure();
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        defaultValues={defaultValues}
        activeCompany={activeCompany}
      />
    );
  }
}

const mapStateToProps = ({ pushNotification }) => ({ pushNotification });

export default connect(mapStateToProps, {
  fetchPushNotification,
  updatePushNotification,
  toggleFullPageSpinner,
  toggleNotification,
})(PushNotificationEdit);
