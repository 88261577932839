export const FETCH_OTP_COUNT = "FETCH_OTP_COUNT";

export function fetchOtpCount(params = {}) {
  params = Object.assign({}, { orderBy: "id:desc" }, params);
  return {
    type: FETCH_OTP_COUNT,
    auth: true,
    payload: {
      url: "resource/otps/count",
      method: "GET",
      params,
    },
  };
}
