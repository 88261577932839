import React from "react";
import { connectStateResults } from "react-instantsearch-dom";
import { Pagination } from "components/algolia-dom";
import isEmpty from "lodash.isempty";

export default connectStateResults(({ props, searchState, searchResults }) => {
  const { query, hits } = searchResults ? searchResults : {};

  if (isEmpty(hits)) {
    return null;
  }
  if (!query) {
    return props.tableComponent;
  }

  let hitResults = isEmpty(props.excludeRecords)
    ? hits
    : hits.filter(({ id }) => {
        return !props.excludeRecords.includes(id);
      });

  hitResults = hitResults.map(hit => {
    const newHitComponent = {
      ...props.hitComponent,
      key: hit.id,
      props: {
        ...props.hitComponent.props,
        hit
      }
    };
    return newHitComponent;
  });

  return (
    <div>
      {hitResults}
      <Pagination />
    </div>
  );
});
