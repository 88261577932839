export const FETCH_PRODUCT_ORDERS = "FETCH_PRODUCT_ORDERS";
export const FETCH_PRODUCT_ORDER = "FETCH_PRODUCT_ORDER";
export const FETCH_PRODUCT_ORDER_SUMMARY_BY_STATUS =
  "FETCH_PRODUCT_ORDER_SUMMARY_BY_STATUS";
export const DELETE_PRODUCT_ORDER = "DELETE_PRODUCT_ORDER";
export const ARCHIVE_PRODUCT_ORDER = "ARCHIVE_PRODUCT_ORDER";
export const UPDATE_PRODUCT_ORDER = "UPDATE_PRODUCT_ORDER";

export function fetchProductOrders(params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_PRODUCT_ORDERS,
    auth: true,
    payload: {
      url: "resource/productOrders",
      method: "GET",
      params
    }
  };
}

export function fetchProductOrderSummaryByStatus(params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_PRODUCT_ORDER_SUMMARY_BY_STATUS,
    auth: true,
    payload: {
      url: "resource/productOrders/summary/status",
      method: "GET",
      params
    }
  };
}

export function fetchProductOrder(id, params = {}) {
  params = Object.assign(
    {},
    { partials: "customer|deliveryZone(delivery)|deliveryAddress|paymentGateway(gateway)|baskets(product|productVariant)" },
    params
  );
  return {
    type: FETCH_PRODUCT_ORDER,
    auth: true,
    payload: {
      url: `resource/productOrders/${id}`,
      method: "GET",
      params
    }
  };
}

export function updateProductOrder(id, data = {}) {
  return {
    type: UPDATE_PRODUCT_ORDER,
    auth: true,
    payload: {
      url: `resource/productOrders/${id}`,
      method: "PUT",
      data
    }
  };
}

export function archiveProductOrder(id, data = {}) {
  return {
    type: ARCHIVE_PRODUCT_ORDER,
    auth: true,
    payload: {
      url: `resource/productOrders/${id}/archive`,
      method: "PUT",
      data
    }
  };
}

export function deleteProductOrder(id, data = {}) {
  return {
    type: DELETE_PRODUCT_ORDER,
    auth: true,
    payload: {
      url: `resource/productOrders/${id}`,
      method: "DELETE",
      data
    }
  };
}
