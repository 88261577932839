/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import InputText from "components/form-fields/InputText";
import uniqid from "uniqid";
import HiddenField from "components/form-fields/HiddenField";
import { NestedField } from "react-form";

export default memo(({ formApi, fieldApi, field }) => {
  const [, key] = field;
  return (
    <div className="box">
      <a
        href="#/"
        className="button is-danger is-outlined is-small is-pulled-right"
        onClick={e => {
          e.preventDefault();
          formApi.removeValue("zones", key);
        }}
      >
        <span>Delete</span>
        <span className="icon is-small">
          <i className="fas fa-times fa-lg" />
        </span>
      </a>
      <div className="columns">
        <div className="column">
          <InputText field="zone" label="Zone" placeholder="Zone" required />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <InputText field="remarks" label="Remarks" placeholder="Remarks" />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <InputText
            type="number"
            field="min_amount"
            label="Min Amount"
            placeholder="Min Amount"
          />
        </div>
        <div className="column">
          <InputText
            type="number"
            field="charges"
            label="Charges"
            placeholder="Charges"
          />
        </div>
      </div>
      <HiddenField field="uid" defaultValue={uniqid.time("zone_")} />
    </div>
  );
});
