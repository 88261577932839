export const FETCH_PRODUCT_CATEGORY = "FETCH_PRODUCT_CATEGORY";
export const FETCH_PRODUCT_CATEGORIES = "FETCH_PRODUCT_CATEGORIES";
export const DELETE_PRODUCT_CATEGORY = "DELETE_PRODUCT_CATEGORY";
export const CREATE_PRODUCT_CATEGORY = "CREATE_PRODUCT_CATEGORY";
export const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY";

export function fetchProductCategory(id, params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_PRODUCT_CATEGORY,
    auth: true,
    payload: {
      url: `resource/product-categories/${id}`,
      method: "GET",
      params
    }
  };
}

export function fetchProductCategories(params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_PRODUCT_CATEGORIES,
    auth: true,
    payload: {
      url: "resource/product-categories/all",
      method: "GET",
      params
    }
  };
}

export function fetchProductCategoriesWithPagination(params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_PRODUCT_CATEGORIES,
    auth: true,
    payload: {
      url: "resource/product-categories",
      method: "GET",
      params
    }
  };
}

export function deleteProductCategory(id, data = {}) {
  return {
    type: DELETE_PRODUCT_CATEGORY,
    auth: true,
    payload: {
      url: `resource/product-categories/${id}`,
      method: "DELETE",
      data
    }
  };
}

export function createProductCategory(data = {}) {
  return {
    type: CREATE_PRODUCT_CATEGORY,
    auth: true,
    payload: {
      url: "resource/product-categories",
      method: "POST",
      data
    }
  };
}

export function updateProductCategory(id, data = {}) {
  return {
    type: UPDATE_PRODUCT_CATEGORY,
    auth: true,
    payload: {
      url: `resource/product-categories/${id}`,
      method: "PUT",
      data
    }
  };
}
