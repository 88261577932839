import React from "react";
import { connectHighlight } from "react-instantsearch-dom";

export default connectHighlight(({ highlight, attribute, hit, highlightProperty }) => {
  const parsedHighlightedValue = highlight({
    hit: hit,
    attribute: attribute,
    highlightProperty: "_highlightResult"
  });

  return parsedHighlightedValue.map((item, i) => {
    return (
      <span className="tag" key={i}>
        {item.map((element, index) =>
          element.isHighlighted ? <mark key={index}>{element.value}</mark> : element.value
        )}
      </span>
    );
  });
});
