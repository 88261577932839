export const FETCH_PARENT_PRODUCTS = "FETCH_PARENT_PRODUCTS";
export const FETCH_PARENT_PRODUCT = "FETCH_PARENT_PRODUCT";
export const CREATE_PARENT_PRODUCT = "CREATE_PARENT_PRODUCT";
export const UPDATE_PARENT_PRODUCT = "UPDATE_PARENT_PRODUCT";
export const DELETE_PARENT_PRODUCT = "DELETE_PARENT_PRODUCT";

export function fetchParentProducts(params = {}) {
  params = Object.assign(
    {},
    { partials: "attachments", orderBy: "id:desc" },
    params
  );
  return {
    type: FETCH_PARENT_PRODUCTS,
    auth: true,
    payload: {
      url: "resource/parent-products",
      method: "GET",
      params,
    },
  };
}

export function fetchParentProduct(id, params = {}) {
  params = Object.assign(
    {},
    {
      partials:
        "attachments|tags|filterTags|groups|productCategories(parent)|productPrices|productVariants(variantOptions|prices)",
    },
    params
  );
  return {
    type: FETCH_PARENT_PRODUCT,
    auth: true,
    payload: {
      url: `resource/parent-products/${id}`,
      method: "GET",
      params,
    },
  };
}

export function createParentProduct(data = {}) {
  return {
    type: CREATE_PARENT_PRODUCT,
    auth: true,
    payload: {
      url: "resource/parent-products",
      method: "POST",
      data,
    },
  };
}

export function updateParentProduct(id, data = {}) {
  return {
    type: UPDATE_PARENT_PRODUCT,
    auth: true,
    payload: {
      url: `resource/parent-products/${id}`,
      method: "PUT",
      data,
    },
  };
}

export function deleteParentProduct(id, data = {}) {
  return {
    type: DELETE_PARENT_PRODUCT,
    auth: true,
    payload: {
      url: `resource/parent-products/${id}`,
      method: "DELETE",
      data,
    },
  };
}
