import React from "react";
import { Form, Text } from "react-form";

import FormMessage from "components/FormMessage";

const LoginForm = ({ onSubmit, company, companyURL }) => {
  const validator = value => ({
    error: !value ? "url can't be empty" : null
  });

  return (
    <Form onSubmit={onSubmit} defaultValues={{ url: companyURL }}>
      {formApi => (
        <form onSubmit={formApi.submitForm}>
          <div className="field has-addons">
            <p className="control ">
              <span className="button is-static is-medium">
                https://utba.com/
              </span>
            </p>
            <p
              className={`control is-expanded ${company.loading &&
                "is-loading"}`}
            >
              <Text
                field="url"
                className="input is-medium"
                type="text"
                placeholder="Company URL"
                validate={validator}
              />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <button
                className="button is-info is-medium is-fullwidth"
                disabled={company.loading}
              >
                <span>Continue</span>
                <span className="icon is-large">
                  <i className="fa fa-long-arrow-alt-right" />
                </span>
              </button>
            </p>
          </div>
          <FormMessage
            show={formApi.errors && formApi.errors.url}
            message={formApi.errors && formApi.errors.url}
            colorClass="is-danger"
          />
          <FormMessage
            show={
              company.error &&
              company.error.response &&
              company.error.response.status === 404
            }
            message="couldn’t find your company."
            colorClass="is-danger"
          />
        </form>
      )}
    </Form>
  );
};
export default LoginForm;
