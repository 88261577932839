import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchCategory, updateCategory } from "actions/category";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "../Form";

class CategoryEdit extends Component {
  componentDidMount() {
    const { activeCompany, match, fetchCategory } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.categoryId;
    fetchCategory(id, { company_url });
  }

  updateCategory(id, data) {
    const { toggleNotification, activeCompany, toggleFullPageSpinner, updateCategory, history } = this.props;

    toggleFullPageSpinner(true);

    updateCategory(id, data)
      .then(() => {
        toggleNotification(true, { message: "Updated Category Success", infoClassName: "is-success" });
        history.push(`/${activeCompany.url}/categories`);
      })
      .catch(e => {
        toggleNotification(true, { message: e.message, infoClassName: "is-danger" });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = data => {
    const { activeCompany, match } = this.props;
    const id = match.params.categoryId;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.updateCategory(id, data);
  };

  render() {
    const { category } = this.props;

    if (category.loading) {
      return "loading";
    }
    const defaultValues = category.data;
    return <Form defaultValues={defaultValues} handleFormSubmit={this.handleFormSubmit} />;
  }
}

const mapStateToProps = ({ category }) => ({
  category
});

export default connect(
  mapStateToProps,
  {
    fetchCategory,
    updateCategory,
    toggleFullPageSpinner,
    toggleNotification
  }
)(CategoryEdit);
