export const FETCH_APP_SETTINGS = "FETCH_APP_SETTINGS";
export const FETCH_APP_SETTING = "FETCH_APP_SETTING";
export const CREATE_APP_SETTING = "CREATE_APP_SETTING";
export const UPDATE_APP_SETTING = "UPDATE_APP_SETTING";
export const DELETE_APP_SETTING = "DELETE_APP_SETTING";

export function fetchAppSettings(params = {}) {
  params = Object.assign({}, { partials: "attachments|user", orderBy: "id:desc" }, params);
  return {
    type: FETCH_APP_SETTINGS,
    auth: true,
    payload: {
      url: "resource/app-settings",
      method: "GET",
      params
    }
  };
}

export function fetchAppSetting(id, params = {}) {
  params = Object.assign({}, { partials: "attachments|user|tags" }, params);
  return {
    type: FETCH_APP_SETTING,
    auth: true,
    payload: {
      url: `resource/app-settings/${id}`,
      method: "GET",
      params
    }
  };
}

export function createAppSetting(data = {}) {
  return {
    type: CREATE_APP_SETTING,
    auth: true,
    payload: {
      url: "resource/app-settings",
      method: "POST",
      data
    }
  };
}

export function updateAppSetting(id, data = {}) {
  return {
    type: UPDATE_APP_SETTING,
    auth: true,
    payload: {
      url: `resource/app-settings/${id}`,
      method: "PUT",
      data
    }
  };
}

export function deleteAppSetting(id, data = {}) {
  return {
    type: DELETE_APP_SETTING,
    auth: true,
    payload: {
      url: `resource/app-settings/${id}`,
      method: "DELETE",
      data
    }
  };
}
