import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchVariantOption, updateVariantOption } from "actions/variantOption";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "pages/VariantOptions/Form";

class VariantOptionEdit extends Component {
  componentDidMount() {
    const { activeCompany, match, fetchVariantOption } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.variantOptionId;
    fetchVariantOption(id, { company_url });
  }

  shapedDefaultValuesStructure() {
    return this.props.variantOption.data;
  }

  updateVariantOption(id, data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      updateVariantOption,
      history,
    } = this.props;

    toggleFullPageSpinner(true);

    updateVariantOption(id, data)
      .then(() => {
        toggleNotification(true, {
          message: "Updated VariantOption Success",
          infoClassName: "is-success",
        });
        history.push(`/${activeCompany.url}/variant-options`);
      })
      .catch((e) => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger",
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = (data) => {
    const { activeCompany, match } = this.props;
    const id = match.params.variantOptionId;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.updateVariantOption(id, data);
  };

  render() {
    const { variantOption, activeCompany } = this.props;

    if (variantOption.loading) {
      return "loading";
    }
    const defaultValues = this.shapedDefaultValuesStructure();
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        defaultValues={defaultValues}
        activeCompany={activeCompany}
      />
    );
  }
}

const mapStateToProps = ({ variantOption }) => ({ variantOption });

export default connect(mapStateToProps, {
  fetchVariantOption,
  updateVariantOption,
  toggleFullPageSpinner,
  toggleNotification,
})(VariantOptionEdit);
