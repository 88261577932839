import React, { Component } from "react";
import { Form, NestedField } from "react-form";

import InputText from "components/form-fields/InputText";
import DeliveryZoneFieldGroup from "./DeliveryZoneFieldGroup";

class DeliveryForm extends Component {
  render() {
    const {
      props: { defaultValues }
    } = this;

    return (
      <Form
        onSubmit={this.props.handleFormSubmit}
        defaultValues={defaultValues}
      >
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <InputText
                    required
                    field="city"
                    label="City"
                    placeholder="City"
                  />
                </div>
                <div className="column">
                  <InputText
                    required
                    field="township"
                    label="Township"
                    placeholder="Township"
                  />
                </div>
              </div>
            </div>
            <div className="box is-clearfix">
              {formApi.values.zones &&
                formApi.values.zones.map((zone, key) => (
                  <NestedField
                    component={DeliveryZoneFieldGroup}
                    // customerTiers={customerTiers}
                    // currencies={currencies}
                    // setting={setting}
                    formApi={formApi}
                    field={["zones", key]}
                    key={key}
                  />
                ))}
            </div>
            <p className="title is-6">
                <a
                  className="button is-info"
                  onClick={() => {
                    formApi.addValue("zones", {});
                  }}
                >
                  <span>Add Zone</span>
                  <span className="icon is-small">
                    <i className="fas fa-plus fa-lg" />
                  </span>
                </a>
              </p>            
            <button className="button is-fullwidth is-info is-medium">
              Submit
            </button>
          </form>
        )}
      </Form>
    );
  }
}
export default DeliveryForm;
