export const FETCH_DELIVERY = "FETCH_DELIVERY";
export const FETCH_DELIVERIES = "FETCH_DELIVERIES";
export const DELETE_DELIVERY = "DELETE_DELIVERY";
export const CREATE_DELIVERY = "CREATE_DELIVERY";
export const UPDATE_DELIVERY = "UPDATE_DELIVERY";

export function fetchDelivery(id, params = {}) {
  params = Object.assign(
    {},
    {
      partials: "zones"
    },
    params
  );
  return {
    type: FETCH_DELIVERY,
    auth: true,
    payload: {
      url: `resource/deliveries/${id}`,
      method: "GET",
      params
    }
  };
}

export function fetchDeliveries(params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_DELIVERIES,
    auth: true,
    payload: {
      url: "resource/deliveries",
      method: "GET",
      params
    }
  };
}

export function deleteDelivery(id, data = {}) {
  return {
    type: DELETE_DELIVERY,
    auth: true,
    payload: {
      url: `resource/deliveries/${id}`,
      method: "DELETE",
      data
    }
  };
}

export function createDelivery(data = {}) {
  return {
    type: CREATE_DELIVERY,
    auth: true,
    payload: {
      url: "resource/deliveries",
      method: "POST",
      data
    }
  };
}

export function updateDelivery(id, data = {}) {
  return {
    type: UPDATE_DELIVERY,
    auth: true,
    payload: {
      url: `resource/deliveries/${id}`,
      method: "PUT",
      data
    }
  };
}
