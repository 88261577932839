import auth from "utils/auth";
import axios from "axios";
export * from "./fetchExportStatus";

const headers = () => {
  return {
    Accept: "application/json",
    Authorization: `Bearer ${auth.getToken()}`,
  };
};

export const importProducts = (file, params) => {
  const formData = new FormData();
  formData.append("importFile", file, file.name);

  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/resource/products/process/import`,
    formData,
    {
      params,
      headers: headers(),
    }
  );
};
