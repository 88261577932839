import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_DELIVERY,
  FETCH_DELIVERIES,
  DELETE_DELIVERY,
  CREATE_DELIVERY,
  UPDATE_DELIVERY
} from "actions/delivery";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function delivery(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_DELIVERY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_DELIVERY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_DELIVERY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deliveries(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_DELIVERIES}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_DELIVERIES}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_DELIVERIES}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deleteDelivery(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_DELIVERY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_DELIVERY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_DELIVERY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createDelivery(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${CREATE_DELIVERY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_DELIVERY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_DELIVERY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateDelivery(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_DELIVERY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_DELIVERY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_DELIVERY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
