/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { Field, Text } from "react-form";
import uniq from "lodash.uniq";
import without from "lodash.without";
import isEmpty from "lodash.isempty";

const TagList = ({ tags, handleTagListDeleteClick }) => {
  return tags
    ? tags.map((tag) => (
        <div className="control" key={tag.id}>
          <div className="tags has-addons">
            <span className="tag">{`${
              tag.parent ? `${tag.parent.name} > ` : ""
            }${tag.name}`}</span>
            <a
              className="tag is-delete"
              onClick={handleTagListDeleteClick(tag)}
            />
          </div>
        </div>
      ))
    : null;
};

const SuggestionList = ({ active, tags, handleSuggestionListClick }) => {
  return !isEmpty(tags) && active ? (
    <ul className={`input-suggestion`}>
      {tags.map((tag) => (
        <li key={tag.id} onClick={handleSuggestionListClick(tag)}>
          {`${tag.parent ? `${tag.parent.name} > ` : ""}${tag.name}`}
        </li>
      ))}
    </ul>
  ) : null;
};

class TagField extends Component {
  state = {
    activeSuggestList: false,
  };

  handleInputChange = (value) => {
    if (value.length >= 1) {
      this.setState({ activeSuggestList: true });
      this.props.onChange(value);
    } else {
      this.setState({ activeSuggestList: false });
    }
  };

  handleInputKeyDown = (formApi, field) => (event) => {
    if (
      !this.props.disableNewTag &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      event.preventDefault();
      this._addTagToTags(formApi, field, event.target.value);
      this.setState({ activeSuggestList: false });
    }
  };

  handleSuggestionListClick = (formApi, field) => (tag) => () => {
    this._addTagToTags(formApi, field, tag);
    this.setState({ activeSuggestList: false });
    formApi.setValue(`tmp_${field}`, null);
  };

  handleTagListDeleteClick = (formApi, field) => (tag) => () => {
    this._deleteTagFromTags(formApi, field, tag);
  };

  _addTagToTags(formApi, field, tag) {
    const values = formApi.values[field] || [];
    if (!values.find((v) => v.id === tag.id)) {
      values.push(tag);
    }
    formApi.setValue(field, values);
    formApi.setValue(`tmp_${field}`, null);
  }

  _deleteTagFromTags(formApi, field, value) {
    const values = formApi.values[field] || [];
    const newValue = [...values.filter(v => v.id !== value.id)]
    formApi.setValue(field, newValue);
  }

  render() {
    const {
      validator,
      field,
      label,
      onChange,
      tags,
      formApi,
      disableNewTag,
      ...rest
    } = this.props;
    const tmpFieldName = `tmp_${field}`;
    return (
      <Field validate={validator} field={field}>
        {(fieldApi) => {
          return (
            <div>
              <div className="field">
                <label className="label">{label}</label>
                <div className="control">
                  <div className="input tags-input">
                    <div className="field is-grouped is-grouped-multiline">
                      <TagList
                        tags={fieldApi.value}
                        handleTagListDeleteClick={this.handleTagListDeleteClick(
                          formApi,
                          field
                        )}
                      />

                      <div className="control input-suggestion-wrapper">
                        <Text
                          field={tmpFieldName}
                          type="text"
                          className="input tag is-small"
                          onChange={this.handleInputChange}
                          onKeyDown={this.handleInputKeyDown(formApi, field)}
                          {...rest}
                        />
                        <SuggestionList
                          active={this.state.activeSuggestList}
                          tags={tags.data}
                          handleSuggestionListClick={this.handleSuggestionListClick(
                            formApi,
                            field
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Field>
    );
  }
}
export default TagField;
