import React, { Component } from "react";
import { connect } from "react-redux";

import { CancelToken } from "axios";

import { fetchCategories } from "actions/category";
import { createTag, detachTag, fetchTags } from "actions/tag";

import { toggleNotification } from "actions/toggle";
import CategorizeList from "./CategorizeList";

class UserCreate extends Component {
  constructor(props) {
    super(props);
    this.category_id = null;
    this.action = null;
  }

  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchCategories({ company_url, partials: "tags" });
  }

  handleOnSubmit = (data, event, formApi) => {
    this.category_id = data.category_id;
    this.action = "add";

    const { companyURL: company_url } = this.props.match.params;
    data = { ...data, company_url };

    this.props
      .createTag(data)
      .then(() => {
        formApi.setValue("name", "");
        this.props.toggleNotification(true, { message: "Add Tag Success", infoClassName: "is-success" });
      })
      .catch(({ message }) => {
        this.props.toggleNotification(true, { message, infoClassName: "is-danger" });
      });
  };

  onClickRemove = (id, category_id) => event => {
    this.category_id = category_id;
    this.action = "detach";
    const { toggleNotification, detachTag, match } = this.props;
    const { companyURL: company_url } = match.params;
    detachTag(id, { company_url, category_id })
      .then(() => {
        toggleNotification(true, { message: "Remove Tag Success", infoClassName: "is-success" });
      })
      .catch(({ message }) => {
        toggleNotification(true, { message, infoClassName: "is-danger" });
      });
  };

  handleTagChange = (value, event) => {
    const { activeCompany, fetchTags } = this.props;

    if (typeof this.searchTagsSource !== typeof undefined) {
      this.searchTagsSource.cancel("Operation canceled due to new request.");
    }

    this.searchTagsSource = CancelToken.source();
    fetchTags(
      { company_url: activeCompany.url, search: `name:${value}`, limit: 5 },
      this.searchTagsSource.token
    );
  };

  render() {
    const { tags, categories, createTagProp, detachTagProp } = this.props;

    if (categories.loading) {
      return "loading...";
    }
    return (
      <div className="box">
        <div className="columns is-multiline">
          <CategorizeList
            suggestTags={tags}
            data={categories.data}
            onSubmit={this.handleOnSubmit}
            createTag={{
              category_id: this.category_id,
              action: this.action,
              ...createTagProp
            }}
            detachTag={{
              category_id: this.category_id,
              action: this.action,
              ...detachTagProp
            }}
            onClickRemove={this.onClickRemove}
            handleTagChange={this.handleTagChange}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ categories, tags, createTag, detachTag }) => ({
    tags,
    categories,
    createTagProp: createTag,
    detachTagProp: detachTag
  }),
  {
    toggleNotification,
    fetchCategories,
    createTag,
    detachTag,
    fetchTags
  }
)(UserCreate);
