import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchArticles, deleteArticle } from "actions/article";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import { SearchBox, InstantSearch, PoweredBy } from "components/algolia-dom";
import HitComponent from "./HitComponent";
import Table from "./Table";
import Pagination from "components/Pagination";
import MagicConnector from "components/algolia-dom/MagicConnector";
import SearchForm from "components/SearchForm";
import ListTable from "components/ListTable";

class ArticleIndex extends Component {
  excludeRecords = [];
  refreshSearch = false;

  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchArticles({ company_url });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchArticles({ company_url, page });
  };

  handleArticleDelete = id => event => {
    this.refreshSearch = false;
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      onClickConfirm: () => {
        this.deleteArticleProcess(id);
      }
    });
  };

  onSearchFormSubmit = data => {
    const { companyURL: company_url } = this.props.match.params;
    const search = {};
    if (data.search_term) {
      search[data.search_by] = data.search_term;
    }
    this.props.fetchArticles({ company_url, ...search });
  };

  deleteArticleProcess(id) {
    const {
      toggleNotification,
      toggleconfirmationPopup,
      deleteArticle,
      match
    } = this.props;
    const { companyURL: company_url } = match.params;

    deleteArticle(id, { company_url })
      .then(() => {
        this.props.fetchArticles({ company_url });
        this.excludeRecords.push(id);
        this.refreshSearch = true;
        toggleNotification(true, {
          infoClassName: "is-success",
          message: "Delete Article Success"
        });
        toggleconfirmationPopup(false, {});
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
        toggleconfirmationPopup(false, {});
      });
  }

  render() {
    const { match, setting, articles } = this.props;
    const { companyURL } = match.params;
    if (setting.loading) {
      return "loading...";
    }

    return (
      <div className="box">
        <ListTable
          onSearchFormSubmit={this.onSearchFormSubmit}
          onPaginationClick={this.onPaginationClick}
          options={[{ label: "By Title", value: "title" }]}
          data={articles}
        >
          <Table
            articles={articles}
            companyURL={companyURL}
            onClickDelete={this.handleArticleDelete}
          />
        </ListTable>
      </div>
    );
  }
}

const mapStateToProps = ({
  authUser,
  articles,
  confirmationPopupActive,
  setting
}) => ({
  authUser,
  articles,
  confirmationPopupActive,
  setting
});

export default connect(mapStateToProps, {
  fetchArticles,
  deleteArticle,
  toggleconfirmationPopup,
  toggleNotification
})(ArticleIndex);
