import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchCurrencies, deleteCurrency } from "actions/currency";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Table from "./Table";
import Pagination from "components/Pagination";
import HitComponent from "./HitComponent";
import { InstantSearch, SearchBox, PoweredBy } from "components/algolia-dom";
import MagicConnector from "components/algolia-dom/MagicConnector";
import ListTable from "components/ListTable";

class CurrencyIndex extends Component {
  excludeRecords = [];
  refreshSearch = false;

  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchCurrencies({ company_url });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchCurrencies({ company_url, page });
  };

  handleCurrencyDelete = id => event => {
    this.refreshSearch = false;
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      onClickConfirm: () => {
        this.deleteCurrencyProcess(id);
      }
    });
  };

  deleteCurrencyProcess(id) {
    const {
      toggleNotification,
      toggleconfirmationPopup,
      deleteCurrency,
      match
    } = this.props;
    const { companyURL: company_url } = match.params;
    deleteCurrency(id, { company_url })
      .then(() => {
        this.props.fetchCurrencies({ company_url });
        this.excludeRecords.push(id);
        this.refreshSearch = true;
        toggleNotification(true, {
          infoClassName: "is-success",
          message: "Delete Currency Success"
        });
        toggleconfirmationPopup(false, {});
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
        toggleconfirmationPopup(false, {});
      });
  }

  render() {
    const { currencies, setting, match } = this.props;
    const { companyURL } = match.params;

    return (
      <div>
        <div className="box">
          <ListTable
            onPaginationClick={this.onPaginationClick}
            data={currencies}
          >
            <Table
              currencies={currencies}
              companyURL={companyURL}
              onClickDelete={this.handleCurrencyDelete}
            />
          </ListTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  authUser,
  currencies,
  confirmationPopupActive,
  setting
}) => ({
  authUser,
  currencies,
  confirmationPopupActive,
  setting
});

export default connect(mapStateToProps, {
  fetchCurrencies,
  deleteCurrency,
  toggleconfirmationPopup,
  toggleNotification
})(CurrencyIndex);
