export const FETCH_COMPANY_PROFILE_TYPES = "FETCH_COMPANY_PROFILE_TYPES";
export const FETCH_COMPANY_PROFILE_TYPE = "FETCH_COMPANY_PROFILE_TYPE";

export function fetchCompanyProfileTypes(params = {}) {
  params = Object.assign({}, { orderBy: "id:desc" }, params);
  return {
    type: FETCH_COMPANY_PROFILE_TYPES,
    auth: true,
    payload: {
      url: "resource/company-profile-types",
      method: "GET",
      params,
    },
  };
}

export function fetchCompanyProfileType(id, params = {}) {
  return {
    type: FETCH_COMPANY_PROFILE_TYPE,
    auth: true,
    payload: {
      url: `resource/company-profile-types/${id}`,
      method: "GET",
      params,
    },
  };
}
