import React, { Component } from "react";
import { connect } from "react-redux";

import { createCategory } from "actions/category";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "../Form";

class CategoryCreate extends Component {
  handleFormSubmit = data => {
    const { activeCompany } = this.props;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.createCategory(data);
  };

  createCategory(data) {
    const { toggleNotification, activeCompany, toggleFullPageSpinner, createCategory, history } = this.props;
    toggleFullPageSpinner(true);
    createCategory(data)
      .then(() => {
        toggleNotification(true, { message: "Created Category Success", infoClassName: "is-success" });
        history.push(`/${activeCompany.url}/categories`);
      })
      .catch(e => {
        const message = (e.response && e.response.data.message) || e.message;
        toggleNotification(true, { message, infoClassName: "is-danger" });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  render() {
    return <Form handleFormSubmit={this.handleFormSubmit} />;
  }
}

export default connect(
  () => ({}),
  {
    createCategory,
    toggleFullPageSpinner,
    toggleNotification
  }
)(CategoryCreate);
