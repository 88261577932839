export const FETCH_TAG = "FETCH_TAG";
export const FETCH_TAGS = "FETCH_TAGS";
export const DELETE_TAG = "DELETE_TAG";
export const CREATE_TAG = "CREATE_TAG";
export const DETACH_TAG = "DETACH_TAG";

export function fetchTag(id, params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_TAG,
    auth: true,
    payload: {
      url: `resource/tags/${id}`,
      method: "GET",
      params
    }
  };
}

export function fetchTags(params = {}, cancelToken) {
  params = Object.assign({}, params);
  return {
    type: FETCH_TAGS,
    auth: true,
    payload: {
      url: "resource/tags",
      method: "GET",
      params,
      cancelToken
    }
  };
}

export function deleteTag(id, data = {}) {
  return {
    type: DELETE_TAG,
    auth: true,
    payload: {
      url: `resource/tags/${id}`,
      method: "DELETE",
      data
    }
  };
}

export function createTag(data = {}) {
  return {
    type: CREATE_TAG,
    auth: true,
    payload: {
      url: "resource/tags",
      method: "POST",
      data
    }
  };
}

export function detachTag(id, data = {}) {
  return {
    type: DETACH_TAG,
    auth: true,
    payload: {
      url: `resource/tags/${id}/detach`,
      method: "PUT",
      data
    }
  };
}
