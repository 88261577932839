import React, { Component } from "react";
import { connect } from "react-redux";

import { createVariantOption } from "actions/variantOption";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "pages/VariantOptions/Form";

class VariantOptionCreate extends Component {
  handleFormSubmit = data => {
    const { activeCompany } = this.props;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.createVariantOption(data);
  };

  createVariantOption(data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      createVariantOption,
      history
    } = this.props;

    toggleFullPageSpinner(true);

    createVariantOption(data)
      .then(() => {
        toggleNotification(true, {
          message: "Created VariantOption Success",
          infoClassName: "is-success"
        });
        history.push(`/${activeCompany.url}/variant-options`);
      })
      .catch(e => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger"
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  render() {
    const { activeCompany } = this.props;

    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        activeCompany={activeCompany}
      />
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  createVariantOption,
  toggleFullPageSpinner,
  toggleNotification
})(VariantOptionCreate);
