export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";

export function fetchCategory(id, params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_CATEGORY,
    auth: true,
    payload: {
      url: `resource/categories/${id}`,
      method: "GET",
      params
    }
  };
}

export function fetchCategories(params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_CATEGORIES,
    auth: true,
    payload: {
      url: "resource/categories/all",
      method: "GET",
      params
    }
  };
}

export function deleteCategory(id, data = {}) {
  return {
    type: DELETE_CATEGORY,
    auth: true,
    payload: {
      url: `resource/categories/${id}`,
      method: "DELETE",
      data
    }
  };
}

export function createCategory(data = {}) {
  return {
    type: CREATE_CATEGORY,
    auth: true,
    payload: {
      url: "resource/categories",
      method: "POST",
      data
    }
  };
}

export function updateCategory(id, data = {}) {
  return {
    type: UPDATE_CATEGORY,
    auth: true,
    payload: {
      url: `resource/categories/${id}`,
      method: "PUT",
      data
    }
  };
}
