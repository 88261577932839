import React, { Component } from "react";
import { connect } from "react-redux";
import dateUtil from "utils/date";

import {
  fetchProductCategory,
  fetchProductCategories,
  updateProductCategory
} from "actions/productCategory";
import {
  uploadImage,
  setUploadedImagePreview,
  removeUploadedImage
} from "actions/image";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";
import { fetchProductStatuses } from "actions/productStatus";

import Form from "../Form";

class ProductCategoryEdit extends Component {
  componentDidMount() {
    const {
      activeCompany,
      match,
      fetchProductCategory,
      fetchProductCategories,
      fetchProductStatuses
    } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.productCategoryId;
    fetchProductCategory(id, { company_url });
    fetchProductCategories({ company_url, excludeId: id });
    fetchProductStatuses({ company_url });
  }

  updateProductCategory(id, data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      updateProductCategory,
      history
    } = this.props;

    toggleFullPageSpinner(true);

    updateProductCategory(id, data)
      .then(() => {
        toggleNotification(true, {
          message: "Updated ProductCategory Success",
          infoClassName: "is-success"
        });
        history.push(`/${activeCompany.url}/product-categories`);
      })
      .catch(e => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger"
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  shapedDefaultValuesStructure() {
    const {
      expired_at,
      released_at,
      ...rest
    } = this.props.productCategory.data;
    return Object.assign(
      {},
      {
        ...rest,
        released_at: dateUtil.phpFormatToJS(released_at),
        expired_at: dateUtil.phpFormatToJS(expired_at)
      }
    );
  }

  handleFormSubmit = data => {
    const { activeCompany, match } = this.props;
    const id = match.params.productCategoryId;

    data = Object.assign({}, data, { company_url: activeCompany.url });

    this.updateProductCategory(id, data);
  };

  render() {
    const {
      productCategory,
      productCategories,
      uploadedImagesPercentage,
      uploadedImagesPreview,
      uploadImage,
      setUploadedImagePreview,
      removeUploadedImage,
      productStatuses,
      uploadedImages
    } = this.props;

    if (productCategory.loading) {
      return "loading";
    }
    const defaultValues = this.shapedDefaultValuesStructure();
    return (
      <Form
        defaultValues={defaultValues}
        handleFormSubmit={this.handleFormSubmit}
        productCategory={productCategory}
        productCategories={productCategories}
        uploadedImagesPercentage={uploadedImagesPercentage}
        uploadedImagesPreview={uploadedImagesPreview}
        uploadImage={uploadImage}
        uploadedImages={uploadedImages}
        setUploadedImagePreview={setUploadedImagePreview}
        removeUploadedImage={removeUploadedImage}
        productStatuses={productStatuses}
      />
    );
  }
}

const mapStateToProps = ({
  productCategory,
  productCategories,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  uploadedImages,
  productStatuses
}) => ({
  productCategory,
  uploadedImages,
  productCategories,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  productStatuses
});

export default connect(mapStateToProps, {
  fetchProductCategory,
  fetchProductCategories,
  fetchProductStatuses,
  updateProductCategory,
  toggleFullPageSpinner,
  toggleNotification,
  uploadImage,
  removeUploadedImage,
  setUploadedImagePreview
})(ProductCategoryEdit);
