import React, { useEffect, useState, memo } from "react";
import {
  fetchExportStatus,
  getDownloadResultUrl,
  EXPORT_STATUS_SUCCESS,
  EXPORT_STATUS_FAILED,
} from "api/fetchExportStatus";

const useExportStatus = (
  processorId,
  params,
  count,
  setCount,
  setProcessing,
  callback
) => {
  useEffect(() => {
    fetchExportStatus(processorId, params).then(({ data }) => {
      if (
        data.status === EXPORT_STATUS_SUCCESS ||
        data.status === EXPORT_STATUS_FAILED
      ) {
        setProcessing(false);
        if (callback) {
          return callback(Object.assign({}, data, {success: data.status === EXPORT_STATUS_SUCCESS}));
        }
        console.log(params)
        window.open(getDownloadResultUrl(data.downloadResult, params));
      } else {
        setTimeout(() => {
          setCount(++count);
        }, 2000);
      }
    });
  });
};

export default memo(({ processorId, params, setProcessing, callback }) => {
  if (processorId) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [count, setCount] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useExportStatus(
      processorId,
      params,
      count,
      setCount,
      setProcessing,
      callback
    );
  }

  return (
    <span className="icon">
      <i className="fas fa-spinner fa-spin" />
    </span>
  );
});
