import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchAbout, updateAbout } from "actions/about";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "./Form";

class About extends Component {
  componentDidMount() {
    const { activeCompany, fetchAbout } = this.props;
    const company_url = activeCompany.url;
    fetchAbout({ company_url });
  }

  updateAbout(data) {
    const { toggleNotification, toggleFullPageSpinner, updateAbout } = this.props;

    toggleFullPageSpinner(true);

    updateAbout(data)
      .then(() => {
        toggleNotification(true, { message: "Updated About Success", infoClassName: "is-success" });
      })
      .catch(e => {
        toggleNotification(true, { message: e.message, infoClassName: "is-danger" });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = data => {
    data = Object.assign({}, data, { company_url: this.props.activeCompany.url });
    this.updateAbout(data);
  };

  render() {
    const { about } = this.props;

    if (about.loading) {
      return "loading";
    }
    const defaultValues = about.data;
    return <Form handleFormSubmit={this.handleFormSubmit} defaultValues={defaultValues} />;
  }
}

const mapStateToProps = ({ about }) => ({
  about
});

export default connect(
  mapStateToProps,
  {
    fetchAbout,
    updateAbout,
    toggleFullPageSpinner,
    toggleNotification
  }
)(About);
