import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchArticle, updateArticle } from "actions/article";
import { fetchTags } from "actions/tag";
import {
  uploadImage,
  setUploadedImagePreview,
  removeUploadedImage
} from "actions/image";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import dateUtil from "utils/date";

import Form from "pages/Articles/Form";

class ArticleEdit extends Component {
  componentWillUnmount() {
    this.props.removeUploadedImage("cover");
    this.props.removeUploadedImage("logo");
  }

  componentDidMount() {
    const { activeCompany, match, fetchArticle } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.articleId;
    fetchArticle(id, { company_url });
  }

  shapedDefaultValuesStructure() {
    const {
      expired_at,
      released_at,
      tags,
      attachments,
      ...rest
    } = this.props.article.data;
    return Object.assign(
      {},
      {
        ...rest,
        released_at: dateUtil.phpFormatToJS(released_at),
        expired_at: dateUtil.phpFormatToJS(expired_at),
        attachments: attachments,
        tags: tags ? tags.map(({ name }) => name) : undefined
      }
    );
  }

  updateArticle(id, data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      updateArticle,
      history
    } = this.props;

    toggleFullPageSpinner(true);

    updateArticle(id, data)
      .then(() => {
        toggleNotification(true, {
          message: "Updated Article Success",
          infoClassName: "is-success"
        });
        history.push(`/${activeCompany.url}/articles`);
      })
      .catch(e => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger"
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = data => {
    const { activeCompany, match } = this.props;
    const id = match.params.articleId;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.updateArticle(id, data);
  };

  render() {
    const {
      uploadedImagesPreview,
      uploadedImagesPercentage,
      uploadedImages,
      removeUploadedImage,
      uploadImage,
      setUploadedImagePreview,
      article,
      tags,
      fetchTags,
      activeCompany
    } = this.props;

    if (article.loading) {
      return "loading";
    }
    const defaultValues = this.shapedDefaultValuesStructure();
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        uploadImage={uploadImage}
        uploadedImages={uploadedImages}
        setUploadedImagePreview={setUploadedImagePreview}
        removeUploadedImage={removeUploadedImage}
        uploadedImagesPreview={uploadedImagesPreview}
        uploadedImagesPercentage={uploadedImagesPercentage}
        defaultValues={defaultValues}
        tags={tags}
        fetchTags={fetchTags}
        activeCompany={activeCompany}
      />
    );
  }
}

const mapStateToProps = ({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  article,
  tags
}) => ({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  article,
  tags
});

export default connect(mapStateToProps, {
  fetchArticle,
  updateArticle,
  uploadImage,
  removeUploadedImage,
  setUploadedImagePreview,
  toggleFullPageSpinner,
  toggleNotification,
  fetchTags
})(ArticleEdit);
