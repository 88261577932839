import React, { Component } from "react";
import { connect } from "react-redux";

import {
  createProductCategory,
  fetchProductCategories
} from "actions/productCategory";
import {
  uploadImage,
  setUploadedImagePreview,
  removeUploadedImage
} from "actions/image";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";
import { fetchProductStatuses } from "actions/productStatus";

import Form from "../Form";

class ProductCategoryCreate extends Component {
  handleFormSubmit = data => {
    const { activeCompany } = this.props;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.createProductCategory(data);
  };

  componentDidMount() {
    const {
      activeCompany,
      fetchProductCategories,
      fetchProductStatuses
    } = this.props;
    const company_url = activeCompany.url;
    fetchProductCategories({ company_url });
    fetchProductStatuses({ company_url });
  }

  createProductCategory(data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      createProductCategory,
      history
    } = this.props;
    toggleFullPageSpinner(true);
    createProductCategory(data)
      .then(() => {
        toggleNotification(true, {
          message: "Created ProductCategory Success",
          infoClassName: "is-success"
        });
        history.push(`/${activeCompany.url}/product-categories`);
      })
      .catch(e => {
        const message = (e.response && e.response.data.message) || e.message;
        toggleNotification(true, { message, infoClassName: "is-danger" });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  render() {
    const {
      productCategories,
      uploadedImagesPercentage,
      uploadedImagesPreview,
      uploadImage,
      uploadedImages,
      setUploadedImagePreview,
      productStatuses,
      removeUploadedImage
    } = this.props;

    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        productCategories={productCategories}
        uploadedImagesPercentage={uploadedImagesPercentage}
        uploadedImagesPreview={uploadedImagesPreview}
        uploadImage={uploadImage}
        uploadedImages={uploadedImages}
        setUploadedImagePreview={setUploadedImagePreview}
        removeUploadedImage={removeUploadedImage}
        productStatuses={productStatuses}
        defaultValues={{ weight: 10000 }}
      />
    );
  }
}

const mapStateToProps = ({
  productCategories,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  uploadedImages,
  productStatuses
}) => ({
  productCategories,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  uploadedImages,
  productStatuses
});

export default connect(mapStateToProps, {
  createProductCategory,
  fetchProductCategories,
  toggleFullPageSpinner,
  toggleNotification,
  uploadImage,
  fetchProductStatuses,
  setUploadedImagePreview,
  removeUploadedImage
})(ProductCategoryCreate);
