import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_APP_SETTINGS,
  FETCH_APP_SETTING,
  CREATE_APP_SETTING,
  UPDATE_APP_SETTING,
  DELETE_APP_SETTING
} from "../actions/appSetting";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function appSettings(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_APP_SETTINGS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_APP_SETTINGS}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_APP_SETTINGS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function appSetting(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_APP_SETTING}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_APP_SETTING}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_APP_SETTING}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createAppSetting(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${CREATE_APP_SETTING}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_APP_SETTING}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_APP_SETTING}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateAppSetting(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_APP_SETTING}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_APP_SETTING}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_APP_SETTING}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deleteAppSetting(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_APP_SETTING}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_APP_SETTING}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_APP_SETTING}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
