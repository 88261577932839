import React, { Component } from "react";
import { connect } from "react-redux";

import { createProduct } from "actions/product";
import { fetchCurrencies } from "actions/currency";
import { fetchPromotionTypes } from "actions/promotionType";
import { fetchProductStatuses } from "actions/productStatus";
import { fetchVariantOptions } from "actions/variantOption";
import { fetchTags } from "actions/tag";
import { fetchFilterTags } from "actions/filterTag";
import { fetchProductCategories } from "actions/productCategory";
import { fetchCustomerTiers } from "actions/customerTier";
import { fetchGroups } from "actions/group";
import { hasFeature } from "utils/feature";
import {
  uploadImage,
  setUploadedImagePreview,
  removeUploadedImage
} from "actions/image";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "pages/Products/Form";

class ProductCreate extends Component {
  componentWillUnmount() {
    this.props.removeUploadedImage("cover");
    this.props.removeUploadedImage("logo");
  }

  componentDidMount() {
    const {
      activeCompany,
      match,
      fetchCurrencies,
      fetchPromotionTypes,
      fetchProductStatuses,
      fetchVariantOptions,
      fetchCustomerTiers
    } = this.props;
    const company_url = activeCompany.url;
    fetchCurrencies({ company_url });
    fetchPromotionTypes({ company_url });
    fetchProductStatuses({ company_url });
    fetchVariantOptions({ company_url });
    fetchCustomerTiers({ company_url });
  }

  handleFormSubmit = data => {
    const { activeCompany } = this.props;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.createProduct(data);
  };

  createProduct(data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      createProduct,
      history
    } = this.props;

    toggleFullPageSpinner(true);

    createProduct(data)
      .then(() => {
        toggleNotification(true, {
          message: "Created Product Success",
          infoClassName: "is-success"
        });
        history.push(`/${activeCompany.url}/products`);
      })
      .catch(e => {
        toggleNotification(true, {
          message: e.response.data.message,
          infoClassName: "is-danger"
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  render() {
    const {
      uploadedImagesPreview,
      uploadedImagesPercentage,
      uploadedImages,
      removeUploadedImage,
      uploadImage,
      setUploadedImagePreview,
      fetchTags,
      fetchFilterTags,
      fetchGroups,
      fetchProductCategories,
      tags,
      groups,
      filterTags,
      productCategories,
      activeCompany,
      currencies,
      setting,
      promotionTypes,
      productStatuses,
      variantOptions,
      customerTiers
    } = this.props;

    const hasPushNotification = hasFeature(activeCompany, 'push_notification')
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        uploadImage={uploadImage}
        uploadedImages={uploadedImages}
        setUploadedImagePreview={setUploadedImagePreview}
        removeUploadedImage={removeUploadedImage}
        uploadedImagesPreview={uploadedImagesPreview}
        uploadedImagesPercentage={uploadedImagesPercentage}
        tags={tags}
        hideNotifyUser={!hasPushNotification}
        customerTiers={customerTiers}
        groups={groups}
        filterTags={filterTags}
        fetchTags={fetchTags}
        fetchGroups={fetchGroups}
        fetchFilterTags={fetchFilterTags}
        fetchProductCategories={fetchProductCategories}
        activeCompany={activeCompany}
        currencies={currencies}
        setting={setting}
        promotionTypes={promotionTypes}
        productStatuses={productStatuses}
        productCategories={productCategories}
        variantOptions={variantOptions}
        defaultValues={{
          push_notification: false,
          tags: [],
          filterTags: [],
          currencies: [],
          promotionTypes: [],
          productCategories: [],
          groups: []
        }} // ::TK HOT FIX
      />
    );
  }
}

const mapStateToProps = ({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  tags,
  currencies,
  filterTags,
  productCategories,
  setting,
  promotionTypes,
  productStatuses,
  variantOptions,
  groups,
  customerTiers
}) => ({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  tags,
  groups,
  filterTags,
  setting,
  currencies,
  promotionTypes,
  productStatuses,
  productCategories,
  variantOptions,
  customerTiers
});

export default connect(mapStateToProps, {
  createProduct,
  uploadImage,
  removeUploadedImage,
  setUploadedImagePreview,
  toggleFullPageSpinner,
  toggleNotification,
  fetchTags,
  fetchFilterTags,
  fetchCurrencies,
  fetchPromotionTypes,
  fetchProductStatuses,
  fetchProductCategories,
  fetchVariantOptions,
  fetchGroups,
  fetchCustomerTiers
})(ProductCreate);
