import React, { Component } from "react";
import { Form } from "react-form";

import InputText from "components/form-fields/InputText";

class VariantOptionForm extends Component {
  constructor(props) {
    super(props);
    this.removedImages = { cover: undefined, logo: undefined };
  }

  onClickRemove = name => event => {
    this.props.removeUploadedImage(name);
    this.removedImages = Object.assign(
      this.removedImages,
      {},
      { [name]: null }
    );
  };

  onSubmit = data => {
    const { uploadedImages } = this.props;

    const shapedAttachmentDataStructure =
      data.attachments &&
      data.attachments.map(({ photo, uid, ...rest }) => {
        photo = undefined;
        if (uploadedImages[uid]) {
          photo = uploadedImages[uid].data.file;
        } else if (this.removedImages[uid] === null) {
          photo = null;
        }
        return Object.assign({}, { ...rest, photo });
      });

    if (uploadedImages) {
      const shapedImagesStructure = Object.keys(uploadedImages).reduce(
        (result, key) => {
          result[key] = uploadedImages[key].data.file;
          return result;
        },
        {}
      );
      data = Object.assign({}, data, this.removedImages, shapedImagesStructure);
    }

    data = Object.assign({}, data, {
      attachments: shapedAttachmentDataStructure
    });

    this.props.handleFormSubmit(data);
  };

  render() {
    const {
      props: { defaultValues }
    } = this;

    return (
      <Form onSubmit={this.onSubmit} defaultValues={defaultValues}>
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <InputText
                    required
                    field="name"
                    label="Name"
                    placeholder="Name"
                  />
                </div>
              </div>
            </div>

            <button className="button is-fullwidth is-info is-medium">
              Submit
            </button>
          </form>
        )}
      </Form>
    );
  }
}
export default VariantOptionForm;
