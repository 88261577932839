import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_ARTICLES,
  FETCH_ARTICLE,
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  DELETE_ARTICLE
} from "../actions/article";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function articles(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_ARTICLES}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_ARTICLES}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_ARTICLES}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function article(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_ARTICLE}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_ARTICLE}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_ARTICLE}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createArticle(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${CREATE_ARTICLE}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_ARTICLE}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_ARTICLE}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateArticle(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_ARTICLE}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_ARTICLE}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_ARTICLE}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deleteArticle(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_ARTICLE}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_ARTICLE}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_ARTICLE}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
