import React, { Component } from "react";
import { connect } from "react-redux";

import { createAppSetting } from "actions/appSetting";
import { fetchTags } from "actions/tag";
import { uploadImage, setUploadedImagePreview, removeUploadedImage } from "actions/image";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "pages/AppSettings/Form";

class AppSettingCreate extends Component {
  componentWillUnmount() {
    this.props.removeUploadedImage("cover");
    this.props.removeUploadedImage("logo");
  }
  handleFormSubmit = data => {
    const { activeCompany } = this.props;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.createAppSetting(data);
  };

  createAppSetting(data) {
    const { toggleNotification, activeCompany, toggleFullPageSpinner, createAppSetting, history } = this.props;
    toggleFullPageSpinner(true);
    createAppSetting(data)
      .then(() => {
        toggleNotification(true, { message: "Created AppSetting Success", infoClassName: "is-success" });
        history.push(`/${activeCompany.url}/app-settings`);
      })
      .catch(e => {
        toggleNotification(true, { message: e.message, infoClassName: "is-danger" });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  render() {
    const {
      uploadedImagesPreview,
      uploadedImagesPercentage,
      uploadedImages,
      removeUploadedImage,
      uploadImage,
      setUploadedImagePreview,
      fetchTags,
      tags,
      activeCompany
    } = this.props;
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        uploadImage={uploadImage}
        uploadedImages={uploadedImages}
        setUploadedImagePreview={setUploadedImagePreview}
        removeUploadedImage={removeUploadedImage}
        uploadedImagesPreview={uploadedImagesPreview}
        uploadedImagesPercentage={uploadedImagesPercentage}
        tags={tags}
        fetchTags={fetchTags}
        activeCompany={activeCompany}
        defaultValues={{ tags: [] }} // ::TK HOT FIX
      />
    );
  }
}

const mapStateToProps = ({ uploadedImages, uploadedImagesPercentage, uploadedImagesPreview, tags }) => ({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview,
  tags
});

export default connect(
  mapStateToProps,
  {
    createAppSetting,
    uploadImage,
    removeUploadedImage,
    setUploadedImagePreview,
    toggleFullPageSpinner,
    toggleNotification,
    fetchTags
  }
)(AppSettingCreate);
