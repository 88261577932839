export const FETCH_ABOUT = "FETCH_ABOUT";
export const UPDATE_ABOUT = "UPDATE_ABOUT";

export function fetchAbout(params = {}) {
  params = Object.assign({}, params);
  return {
    type: FETCH_ABOUT,
    auth: true,
    payload: {
      url: "resource/about",
      method: "GET",
      params
    }
  };
}

export function updateAbout(data = {}) {
  return {
    type: UPDATE_ABOUT,
    auth: true,
    payload: {
      url: "resource/about",
      method: "PUT",
      data
    }
  };
}
