import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchPushNotifications, deletePushNotification } from "actions/pushNotification";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Table from "./Table";
import Pagination from "components/Pagination";
import HitComponent from "./HitComponent";
import { InstantSearch, SearchBox, PoweredBy } from "components/algolia-dom";
import MagicConnector from "components/algolia-dom/MagicConnector";

class PushNotificationIndex extends Component {
  excludeRecords = [];
  refreshSearch = false;

  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchPushNotifications({ company_url });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchPushNotifications({ company_url, page });
  };

  handlePushNotificationDelete = id => event => {
    this.refreshSearch = false;
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      onClickConfirm: () => {
        this.deletePushNotificationProcess(id);
      }
    });
  };

  deletePushNotificationProcess(id) {
    const { toggleNotification, toggleconfirmationPopup, deletePushNotification, match } = this.props;
    const { companyURL: company_url } = match.params;
    deletePushNotification(id, { company_url })
      .then(() => {
        this.props.fetchPushNotifications({ company_url });
        this.excludeRecords.push(id);
        this.refreshSearch = true;
        toggleNotification(true, { infoClassName: "is-success", message: "Delete PushNotification Success" });
        toggleconfirmationPopup(false, {});
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
        toggleconfirmationPopup(false, {});
      });
  }

  render() {
    const { pushNotifications, setting, match } = this.props;
    const { companyURL } = match.params;

    return (
      <div>
        <div className="box">
          <InstantSearch
            indexName="pushNotifications_index"
            refresh={this.refreshSearch}
            companyID={setting.data.company_id}
          >
            <SearchBox />
            <PoweredBy />
            <MagicConnector
              excludeRecords={this.excludeRecords}
              hitComponent={<HitComponent companyURL={companyURL} onClickDelete={this.handlePushNotificationDelete} />}
              tableComponent={
                <React.Fragment>
                  <Table
                    pushNotifications={pushNotifications}
                    companyURL={companyURL}
                    onClickDelete={this.handlePushNotificationDelete}
                  />
                  <Pagination
                    onClick={this.onPaginationClick}
                    currentPage={pushNotifications.current_page}
                    lastPage={pushNotifications.last_page}
                    range="4"
                    modifyClasses="is-right"
                  />
                </React.Fragment>
              }
            />
          </InstantSearch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser, pushNotifications, confirmationPopupActive, setting }) => ({
  authUser,
  pushNotifications,
  confirmationPopupActive,
  setting
});

export default connect(
  mapStateToProps,
  { fetchPushNotifications, deletePushNotification, toggleconfirmationPopup, toggleNotification }
)(PushNotificationIndex);
