import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import { FETCH_ROLES } from "actions/role";

const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function roles(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_ROLES}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_ROLES}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, { loading: false, error: null });
    case `${FETCH_ROLES}_${REJECTED}`:
      return Object.assign({}, state, { loading: false, error: payload });
    default:
      return state;
  }
}
