import React, { memo } from "react";

export default memo(({ onChange, status, defaultValue }) => (
  <div className="field">
    <div className="control has-icons-left">
      <div className="select">
        <select onChange={onChange} defaultValue={defaultValue}>
          <option value="">Order Status</option>
          {status.map((s, index) => (
            <option key={index} value={s}>
              {s}
            </option>
          ))}
        </select>
      </div>
      <div className="icon is-small is-left">
        <i className="fas fa-filter" />
      </div>
    </div>
  </div>
));
