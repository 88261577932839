import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import { FETCH_FILTER, FETCH_FILTERS, DELETE_FILTER, CREATE_FILTER } from "actions/filterTag";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function filterTag(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_FILTER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_FILTER}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_FILTER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function filterTags(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_FILTERS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_FILTERS}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_FILTERS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deleteFilterTag(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_FILTER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_FILTER}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_FILTER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createFilterTag(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${CREATE_FILTER}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_FILTER}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_FILTER}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
