import React from "react";
import { NavLink } from "react-router-dom";
import SidebarMenu from "./SidebarMenu";

const Sidebar = ({ className, hide, activeCompany, setting }) => (
  <div className={`${className} ${hide ? "is-hidden" : ""} `}>
    <section className="section">
      <aside className="menu">
        <SidebarMenu
          company={activeCompany}
          label={setting.loading ? "loading..." : setting.data.product_label}
          feature="products"
          link="products"
        />
        {activeCompany.product_view === "variant" && (
          <SidebarMenu
            company={activeCompany}
            label="Variant Options"
            feature="products"
            link="variant-options"
          />
        )}
        {activeCompany.parent_id && (
          <SidebarMenu
            company={activeCompany}
            label="Parent Products"
            feature="products"
            link="parent-products"
          />
        )}
        <SidebarMenu
          company={activeCompany}
          label="Product Category"
          feature="product_categories"
          link="product-categories"
        />
        <SidebarMenu
          company={activeCompany}
          label="Article"
          feature="articles"
          link="articles"
        />
        <SidebarMenu
          company={activeCompany}
          label="Feature"
          feature="features"
          link="highlights"
        />
        <SidebarMenu
          company={activeCompany}
          label="Payment Gateways"
          feature="payment_gateways"
          link="payment-gateways"
          addNew={false}
        />
        <SidebarMenu
          company={activeCompany}
          label="Tag"
          feature="tags"
          link="tags"
          addNew={false}
          addCategorize
        />
        <SidebarMenu
          company={activeCompany}
          label="Delivery"
          feature="deliveries"
          link="deliveries"
        />
        <SidebarMenu
          company={activeCompany}
          label="Category"
          feature="categories"
          link="categories"
        />
        <SidebarMenu
          company={activeCompany}
          label={
            setting.loading
              ? "loading..."
              : `${setting.data.product_label} Orders`
          }
          feature="product_orders"
          link="product-orders"
          addNew={false}
        />
        <SidebarMenu
          company={activeCompany}
          label="Customer"
          feature="customers"
          link="customers"
          addNew={false}
        />
        <SidebarMenu
          company={activeCompany}
          label="Customer Tier"
          feature="customer_tiers"
          link="customer-tiers"
        />
        {/* <SidebarMenu
          company={activeCompany}
          label="Push Notification"
          feature="push_notification"
          link="push-notifications"
        /> */}
        <SidebarMenu
          company={activeCompany}
          label={setting.loading ? "loading..." : setting.data.currency_label}
          feature="currencies"
          link="currencies"
        />
        <SidebarMenu
          company={activeCompany}
          label={setting.loading ? "loading..." : setting.data.contact_label}
          feature="contacts"
          link="contacts"
        />
        <SidebarMenu
          company={activeCompany}
          label="User"
          feature="users"
          link="users"
        />
        <SidebarMenu
          company={activeCompany}
          label="App Setting"
          feature="app_settings"
          link="app-settings"
        />
        <p className="menu-label">&nbsp;</p>
        <ul className="menu-list">
          <li>
            <NavLink
              to={`/${activeCompany.url}/setting`}
              activeClassName="is-active"
              exact
            >
              Setting
            </NavLink>
            <NavLink
              to={`/${activeCompany.url}/about`}
              activeClassName="is-active"
              exact
            >
              {setting.loading ? "loading..." : setting.data.about_label}
            </NavLink>
          </li>
        </ul>
      </aside>
    </section>
  </div>
);
export default Sidebar;
