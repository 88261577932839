import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchAppSettings, deleteAppSetting } from "actions/appSetting";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import { SearchBox, InstantSearch, PoweredBy } from "components/algolia-dom";
import HitComponent from "./HitComponent";
import Table from "./Table";
import Pagination from "components/Pagination";
import MagicConnector from "components/algolia-dom/MagicConnector";
import ListTable from "components/ListTable";

class AppSettingIndex extends Component {
  excludeRecords = [];
  refreshSearch = false;

  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchAppSettings({ company_url });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchAppSettings({ company_url, page });
  };

  handleAppSettingDelete = id => event => {
    this.refreshSearch = false;
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      onClickConfirm: () => {
        this.deleteAppSettingProcess(id);
      }
    });
  };

  deleteAppSettingProcess(id) {
    const {
      toggleNotification,
      toggleconfirmationPopup,
      deleteAppSetting,
      match
    } = this.props;
    const { companyURL: company_url } = match.params;

    deleteAppSetting(id, { company_url })
      .then(() => {
        this.props.fetchAppSettings({ company_url });
        this.excludeRecords.push(id);
        this.refreshSearch = true;
        toggleNotification(true, {
          infoClassName: "is-success",
          message: "Delete AppSetting Success"
        });
        toggleconfirmationPopup(false, {});
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
        toggleconfirmationPopup(false, {});
      });
  }

  render() {
    const { match, setting, appSettings } = this.props;
    const { companyURL } = match.params;
    if (setting.loading) {
      return "loading...";
    }

    return (
      <div className="box">
        <ListTable
          onPaginationClick={this.onPaginationClick}
          data={appSettings}
        >
          <Table
            appSettings={appSettings}
            companyURL={companyURL}
            onClickDelete={this.handleAppSettingDelete}
          />
        </ListTable>
      </div>
    );
  }
}

const mapStateToProps = ({
  authUser,
  appSettings,
  confirmationPopupActive,
  setting
}) => ({
  authUser,
  appSettings,
  confirmationPopupActive,
  setting
});

export default connect(mapStateToProps, {
  fetchAppSettings,
  deleteAppSetting,
  toggleconfirmationPopup,
  toggleNotification
})(AppSettingIndex);
