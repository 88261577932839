import React, { Component } from "react";
import { connect } from "react-redux";

import { createUser } from "actions/user";
import { fetchRoles } from "actions/role";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "pages/Users/Form";

class UserCreate extends Component {
  componentDidMount() {
    const { activeCompany, fetchRoles } = this.props;
    const company_url = activeCompany.url;
    fetchRoles({ company_url });
  }

  handleFormSubmit = data => {
    const { activeCompany } = this.props;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.createUser(data);
  };

  createUser(data) {
    const { toggleNotification, activeCompany, toggleFullPageSpinner, createUser, history } = this.props;
    toggleFullPageSpinner(true);
    createUser(data)
      .then(() => {
        toggleNotification(true, { message: "Created User Success", infoClassName: "is-success" });
        history.push(`/${activeCompany.url}/users`);
      })
      .catch(e => {
        const message = (e.response && e.response.data.message) || e.message;
        toggleNotification(true, { message, infoClassName: "is-danger" });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  render() {
    return <Form handleFormSubmit={this.handleFormSubmit} roles={this.props.roles} />;
  }
}

export default connect(
  ({ roles }) => ({ roles }),
  {
    createUser,
    toggleFullPageSpinner,
    toggleNotification,
    fetchRoles
  }
)(UserCreate);
