/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Form, NestedField } from "react-form";
import isEmpty from "lodash.isempty";

import InputText from "components/form-fields/InputText";
import Select from "components/form-fields/Select";
import AttachmentFieldGroup from "components/form-fields/AttachmentFieldGroup";
import ImageField from "components/form-fields/ImageField";
import TagField from "components/form-fields/TagField";
import LocationField from "components/form-fields/LocationField";
import CategoryField from "components/form-fields/CategoryField";
import { CancelToken } from "axios";
import debounce from "lodash.debounce";

class HighlightForm extends Component {
  constructor(props) {
    super(props);
    this.removedImages = { cover: undefined, logo: undefined };
    if (this.props.defaultValues.highlight_action_id) {
      const v = this.props.defaultValues.highlight_action_id;
      this.action = this.props.highlightActions.data.find(d => {
        // eslint-disable-next-line eqeqeq
        return d.id == v;
      });
    } else {
      this.action = null;
    }
  }

  onClickRemove = name => event => {
    this.props.removeUploadedImage(name);
    this.removedImages = Object.assign(
      this.removedImages,
      {},
      { [name]: null }
    );
  };

  onImageFieldChange = name => event => {
    const files = event.target.files || event.dataTransfer.files;
    if (!files[0]) return;

    const photo = new Image();
    photo.onload = () => {
      const data = new FormData();
      data.append("file", files[0]);
      this.props.uploadImage(name, data).then(() => {
        this.props.setUploadedImagePreview(name, photo.src);
      });
    };
    photo.src = window.URL.createObjectURL(files[0]);
  };

  onTagsFieldChange = value => {
    const { activeCompany, fetchTags } = this.props;

    if (typeof this.searchTagsSource !== typeof undefined) {
      this.searchTagsSource.cancel("Operation canceled due to new request.");
    }

    this.searchTagsSource = CancelToken.source();
    fetchTags(
      { company_url: activeCompany.url, search: `name:${value}`, limit: 5 },
      this.searchTagsSource.token
    );
  };

  onProductCategoriesFieldChange = debounce(value => {
    const { activeCompany, fetchProductCategories } = this.props;

    this.searchProductCategoriesSource = CancelToken.source();
    fetchProductCategories(
      {
        company_url: activeCompany.url,
        partials: "parent",
        search: `name:${value}`,
        limit: 5
      },
      this.searchProductCategoriesSource.token
    );
  }, 1000);

  onFiltersFieldChange = debounce(value => {
    const { activeCompany, fetchFilterTags } = this.props;

    this.searchFilterTagsSource = CancelToken.source();
    fetchFilterTags(
      { company_url: activeCompany.url, search: `name:${value}`, limit: 5 },
      this.searchFilterTagsSource.token
    );
  }, 1000);

  onGroupsFieldChange = debounce(value => {
    const { activeCompany, fetchGroups } = this.props;

    this.searchGroupsSource = CancelToken.source();
    fetchGroups(
      { company_url: activeCompany.url, search: `name:${value}`, limit: 5 },
      this.searchGroupsSource.token
    );
  }, 1000);

  onSubmit = data => {
    const { uploadedImages } = this.props;

    const shapedAttachmentDataStructure =
      data.attachments &&
      data.attachments.map(({ photo, uid, ...rest }) => {
        photo = undefined;
        if (uploadedImages[uid]) {
          photo = uploadedImages[uid].data.file;
        } else if (this.removedImages[uid] === null) {
          photo = null;
        }
        return Object.assign({}, { ...rest, photo });
      });

    if (uploadedImages) {
      const shapedImagesStructure = Object.keys(uploadedImages).reduce(
        (result, key) => {
          result[key] = uploadedImages[key].data.file;
          return result;
        },
        {}
      );
      data = Object.assign({}, data, this.removedImages, shapedImagesStructure);
    }

    data = Object.assign({}, data, {
      attachments: shapedAttachmentDataStructure
    });

    this.props.handleFormSubmit(data);
  };

  onHighlightActionChange = v => {
    this.action = this.props.highlightActions.data.find(d => {
      // eslint-disable-next-line eqeqeq
      return d.id == v;
    });
  };

  render() {
    const {
      onClickRemove,
      onImageFieldChange,
      onTagsFieldChange,
      onFiltersFieldChange,
      onGroupsFieldChange,
      onProductCategoriesFieldChange,
      props: {
        tags,
        filterTags,
        productCategories,
        groups,
        uploadedImagesPreview,
        uploadedImagesPercentage,
        defaultValues,
        highlightActions
      }
    } = this;

    return (
      <Form onSubmit={this.onSubmit} defaultValues={defaultValues}>
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <div className="box">
              <ImageField
                field="cover"
                label="Cover Photo"
                uploadPercentage={uploadedImagesPercentage.cover}
                preview={uploadedImagesPreview.cover}
                onChange={onImageFieldChange("cover")}
                onClickRemove={onClickRemove("cover")}
              />
              <hr />
              <ImageField
                field="logo"
                label="Logo"
                uploadPercentage={uploadedImagesPercentage.logo}
                preview={uploadedImagesPreview.logo}
                onChange={onImageFieldChange("logo")}
                onClickRemove={onClickRemove("logo")}
              />
            </div>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <InputText
                    required
                    field="title"
                    label="Title"
                    placeholder="Title"
                  />
                </div>
                <div className="column">
                  <InputText
                    field="promotion"
                    label="Promotion"
                    type="number"
                    max="100"
                    placeholder="Promotion"
                  />
                </div>
                <div className="column">
                  <InputText
                    field="weight"
                    label="Weight"
                    type="number"
                    min="-32768"
                    max="32767"
                    placeholder="Weight"
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <InputText
                    field="released_at"
                    label="Released at"
                    type="date"
                    placeholder="Released at"
                  />
                </div>
                <div className="column">
                  <InputText
                    field="expired_at"
                    label="Expired at"
                    type="date"
                    placeholder="Expired at"
                  />
                </div>
                <div className="column">
                  <Select
                    field="freezed"
                    placeholder="Is feezed?"
                    label="Freezed"
                    options={[
                      { label: "No", value: 0 },
                      { label: "Yes", value: 1 }
                    ]}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Select
                    field="highlight_action_id"
                    placeholder="Highlight Action"
                    label="Highlight Action"
                    options={highlightActions.data.map(v => ({
                      label: v.name,
                      value: v.id
                    }))}
                    onChange={this.onHighlightActionChange}
                  />
                </div>
              </div>
              {this.action &&
                this.action.action.map((act, key) => (
                  <div className="column" key={key}>
                    <InputText
                      field={`highlight_action.${act}`}
                      label={act}
                      placeholder={act}
                    />
                  </div>
                ))}
              <div className="columns">
                <div className="column">
                  <TagField
                    field="tags"
                    label="Tags"
                    type="text"
                    onChange={onTagsFieldChange}
                    tags={tags}
                    formApi={formApi}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TagField
                    field="filterTags"
                    label="Filters"
                    type="text"
                    onChange={onFiltersFieldChange}
                    tags={filterTags}
                    formApi={formApi}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TagField
                    field="groups"
                    label="Groups"
                    type="text"
                    onChange={onGroupsFieldChange}
                    tags={groups}
                    formApi={formApi}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <CategoryField
                    field="productCategories"
                    label="Product Categories"
                    type="text"
                    onChange={onProductCategoriesFieldChange}
                    tags={productCategories}
                    formApi={formApi}
                    disableNewTag={true}
                  />
                </div>
              </div>              
            </div>
            <div className="box">
              <div className="columns">
                <div className="column is-half-desktop">
                  <LocationField
                    formApi={formApi}
                    field="location"
                    label="Location"
                  />
                </div>
              </div>
            </div>
            <div className="box is-clearfix">
              <Select
                fullwidth={false}
                field="text_first"
                placeholder="Priority"
                label="Priority"
                options={[
                  { label: "Image first", value: 0 },
                  { label: "Text first", value: 1 }
                ]}
              />

              {formApi.values.attachments &&
                formApi.values.attachments.map((attachment, key) => (
                  <NestedField
                    photo={{
                      onImageFieldChange,
                      onImageRemove: onClickRemove,
                      uploadedImagesPreview,
                      uploadedImagesPercentage
                    }}
                    component={AttachmentFieldGroup}
                    formApi={formApi}
                    field={["attachments", key]}
                    key={key}
                  />
                ))}
              <p className="title is-6">
                <a
                  className="button is-info"
                  onClick={() => {
                    const { attachments } = formApi.values;
                    const weight = isEmpty(attachments)
                      ? 1
                      : attachments[attachments.length - 1].weight + 1;
                    formApi.addValue("attachments", { weight });
                  }}
                >
                  <span>Add Text/Photo</span>
                  <span className="icon is-small">
                    <i className="fas fa-plus fa-lg" />
                  </span>
                </a>
              </p>
            </div>

            <button className="button is-fullwidth is-info is-medium">
              Submit
            </button>
          </form>
        )}
      </Form>
    );
  }
}
export default HighlightForm;
