export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const INCREASE_UPLOADED_PERCENTAGE = "INCREASE_UPLOADED_PERCENTAGE";
export const SET_PREVIEW_IMAGE = "SET_PREVIEW_IMAGE";
export const REMOVE_UPLOADED_IMAGE = "REMOVE_UPLOADED_IMAGE";

export function uploadImage(name, data = {}) {
  const dispatchProgress = (percentage = 0) => {
    return {
      type: INCREASE_UPLOADED_PERCENTAGE,
      meta: {
        name
      },
      percentage
    };
  };

  return {
    type: UPLOAD_IMAGE,
    meta: {
      name
    },
    auth: true,
    dispatchProgress,
    payload: {
      url: "service/uploadfile",
      method: "POST",
      data
    }
  };
}

export function setUploadedImagePreview(name, url) {
  return {
    type: SET_PREVIEW_IMAGE,
    meta: {
      name
    },
    url
  };
}

export function removeUploadedImage(name) {
  return {
    type: REMOVE_UPLOADED_IMAGE,
    meta: {
      name
    }
  };
}
