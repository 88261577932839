import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchHighlights, deleteHighlight } from "actions/highlight";
import { toggleconfirmationPopup, toggleNotification } from "actions/toggle";

import Table from "./Table";
import ListTable from "components/ListTable";

class HighlightIndex extends Component {
  excludeRecords = [];
  refreshSearch = false;

  componentDidMount() {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchHighlights({ company_url });
  }

  onPaginationClick = page => event => {
    const { companyURL: company_url } = this.props.match.params;
    this.props.fetchHighlights({ company_url, page });
  };

  onSearchFormSubmit = data => {
    const { companyURL: company_url } = this.props.match.params;
    const search = {};
    if (data.search_term) {
      search[data.search_by] = data.search_term;
    }
    this.props.fetchHighlights({ company_url, ...search });
  };

  handleHighlightDelete = id => event => {
    this.refreshSearch = false;
    const { toggleconfirmationPopup } = this.props;
    toggleconfirmationPopup(true, {
      onClickConfirm: () => {
        this.deleteHighlightProcess(id);
      }
    });
  };

  deleteHighlightProcess(id) {
    const {
      toggleNotification,
      toggleconfirmationPopup,
      deleteHighlight,
      match
    } = this.props;
    const { companyURL: company_url } = match.params;

    deleteHighlight(id, { company_url })
      .then(() => {
        this.props.fetchHighlights({ company_url });
        this.excludeRecords.push(id);
        this.refreshSearch = true;
        toggleNotification(true, {
          infoClassName: "is-success",
          message: "Delete Highlight Success"
        });
        toggleconfirmationPopup(false, {});
      })
      .catch(({ message }) => {
        toggleNotification(true, { infoClassName: "is-danger", message });
        toggleconfirmationPopup(false, {});
      });
  }

  render() {
    const { match, setting, highlights } = this.props;
    const { companyURL } = match.params;
    if (setting.loading) {
      return "loading...";
    }

    return (
      <div className="box">
        <ListTable
          onSearchFormSubmit={this.onSearchFormSubmit}
          onPaginationClick={this.onPaginationClick}
          options={[{ label: "By Title", value: "title" }]}
          data={highlights}
        >
          <Table
            highlights={highlights}
            companyURL={companyURL}
            onClickDelete={this.handleHighlightDelete}
          />
        </ListTable>
      </div>
    );
  }
}

const mapStateToProps = ({
  authUser,
  highlights,
  confirmationPopupActive,
  setting
}) => ({
  authUser,
  highlights,
  confirmationPopupActive,
  setting
});

export default connect(mapStateToProps, {
  fetchHighlights,
  deleteHighlight,
  toggleconfirmationPopup,
  toggleNotification
})(HighlightIndex);
