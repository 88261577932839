import React, { memo } from "react";
export default memo(({ page, refine, isCurrent }) => (
  <li>
    <a
      {...{
        className: isCurrent ? "pagination-link is-current" : "pagination-link",
        onClick: isCurrent ? undefined : () => refine(page)
      }}
    >
      {page}
    </a>
  </li>
));
