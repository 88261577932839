export const FETCH_CONTACTS = "FETCH_CONTACTS";
export const FETCH_CONTACT = "FETCH_CONTACT";
export const CREATE_CONTACT = "CREATE_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

export function fetchContacts(params = {}) {
  params = Object.assign({}, { partials: "attachments", orderBy: "id:desc" }, params);
  return {
    type: FETCH_CONTACTS,
    auth: true,
    payload: {
      url: "resource/contacts",
      method: "GET",
      params
    }
  };
}

export function fetchContact(id, params = {}) {
  params = Object.assign({}, { partials: "attachments" }, params);
  return {
    type: FETCH_CONTACT,
    auth: true,
    payload: {
      url: `resource/contacts/${id}`,
      method: "GET",
      params
    }
  };
}

export function createContact(data = {}) {
  return {
    type: CREATE_CONTACT,
    auth: true,
    payload: {
      url: "resource/contacts",
      method: "POST",
      data
    }
  };
}

export function updateContact(id, data = {}) {
  return {
    type: UPDATE_CONTACT,
    auth: true,
    payload: {
      url: `resource/contacts/${id}`,
      method: "PUT",
      data
    }
  };
}

export function deleteContact(id, data = {}) {
  return {
    type: DELETE_CONTACT,
    auth: true,
    payload: {
      url: `resource/contacts/${id}`,
      method: "DELETE",
      data
    }
  };
}
