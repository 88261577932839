import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import { FETCH_SETTING, UPDATE_SETTING } from "actions/setting";

const SINGLE_DATA_INITIAL_STATE = {
  data: {
    order_status: []
  },
  error: null,
  loading: false
};

export function setting(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_SETTING}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_SETTING}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_SETTING}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateSetting(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_SETTING}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_SETTING}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_SETTING}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
