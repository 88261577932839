import React, { Component, lazy, Suspense } from "react";
import { Form } from "react-form";

import InputText from "components/form-fields/InputText";

class PaymentGatewayForm extends Component {
  onSubmit = data => {
    this.props.handleFormSubmit(data);
  };
  render() {
    const {
      onSubmit,
      props: { defaultValues }
    } = this;

    if (!defaultValues.gateway) {
      return null;
    }

    const PaymentForm = lazy(() =>
      import(
        `./gateways/${
          defaultValues.gateway.online ? defaultValues.gateway.name : "offline"
        }`
      )
    );

    return (
      <Form onSubmit={onSubmit} defaultValues={defaultValues}>
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <div className="box">
              <Suspense fallback={<div>Loading...</div>}>
                <PaymentForm defaultValues={defaultValues} />
              </Suspense>
            </div>

            <button className="button is-fullwidth is-info is-medium">
              Submit
            </button>
          </form>
        )}
      </Form>
    );
  }
}

export default PaymentGatewayForm;
