export const FETCH_VARIANT_OPTIONS = "FETCH_VARIANT_OPTIONS";
export const FETCH_VARIANT_OPTION = "FETCH_VARIANT_OPTION";
export const CREATE_VARIANT_OPTION = "CREATE_VARIANT_OPTION";
export const UPDATE_VARIANT_OPTION = "UPDATE_VARIANT_OPTION";
export const DELETE_VARIANT_OPTION = "DELETE_VARIANT_OPTION";

export function fetchVariantOptions(params = {}) {
  params = Object.assign({}, { orderBy: "id:desc" }, params);
  return {
    type: FETCH_VARIANT_OPTIONS,
    auth: true,
    payload: {
      url: "resource/variant-options",
      method: "GET",
      params
    }
  };
}

export function fetchVariantOption(id, params = {}) {
  return {
    type: FETCH_VARIANT_OPTION,
    auth: true,
    payload: {
      url: `resource/variant-options/${id}`,
      method: "GET",
      params
    }
  };
}

export function createVariantOption(data = {}) {
  return {
    type: CREATE_VARIANT_OPTION,
    auth: true,
    payload: {
      url: "resource/variant-options",
      method: "POST",
      data
    }
  };
}

export function updateVariantOption(id, data = {}) {
  return {
    type: UPDATE_VARIANT_OPTION,
    auth: true,
    payload: {
      url: `resource/variant-options/${id}`,
      method: "PUT",
      data
    }
  };
}

export function deleteVariantOption(id, data = {}) {
  return {
    type: DELETE_VARIANT_OPTION,
    auth: true,
    payload: {
      url: `resource/variant-options/${id}`,
      method: "DELETE",
      data
    }
  };
}
