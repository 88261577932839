import React, { memo } from "react";
import { Link } from "react-router-dom";

export default memo(({ summary, company_url }) => (
  <div className="column">
    {summary.loading ? (
      "loading..."
    ) : (
      <div className="column">
        <table className="table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Totals</th>
            </tr>
          </thead>
          <tbody>
            {summary.data.map((s, i) => {
              return (
                <tr key={i}>
                  <th>
                    <Link
                      to={`/${company_url}/product-orders/status/${s.name}`}
                    >
                      {s.name}
                    </Link>
                  </th>
                  <td>{s.count}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    )}
  </div>
));
