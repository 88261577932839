/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";

import authUtil from "utils/auth";

const LogoutButton = (
  <div className="navbar-item">
    <div className="navbar-item">
      <a
        className="button is-danger"
        onClick={() => {
          authUtil.logout();
        }}
      >
        <span>Logout</span>
        <span className="icon is-small">
          <i className="fas fa-sign-out-alt" />
        </span>
      </a>
    </div>
  </div>
);

export default memo(() => (
  <nav className="navbar is-info">
    <div className="navbar-brand">
      <div className="navbar-item">
        <p className="navbar-item">
          <span className="has-text-light">UTBA Admin Panel</span>
        </p>
      </div>
    </div>
    <div className="navbar-menu is-hidden-touch">
      <div className="navbar-end">{LogoutButton}</div>
    </div>
    <div className="navbar-menu is-active is-hidden-desktop">
      {LogoutButton}
    </div>
  </nav>
));
