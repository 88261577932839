import React, { Component } from "react";
import loadScript from "simple-load-script";

import { Form } from "react-form";
import Details from "./Details";
import ReactToPrint from "react-to-print";

function PrintButton({ componentRef }) {
  return (
    <ReactToPrint
      trigger={() => (
        <button type="button" className="button is-fullwidth is-info is-medium">
          <span className="icon">
            <i className="fas fa-print" />
          </span>
          <span>Print</span>
        </button>
      )}
      content={() => componentRef}
    />
  );
}
export default class ProductOrderShow extends Component {
  constructor(props) {
    super(props);
    this.$map = React.createRef();
  }

  componentDidMount() {
    this.loadMapScript();
  }

  loadMapScript() {
    if (window.google) {
      this.initMap();
    } else {
      const script = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
      loadScript(script)
        .then(() => {
          this.initMap();
        })
        .catch(err => {
          console.log({ message: "unable to load google map", err });
        });
    }
  }

  rawLocation() {
    if (!this.$map.current) {
      return;
    }
    this.$map.current.className = "card-header-title";
    const { loading, data } = this.props.productOrder;
    const text = loading ? "loading..." : data.location;
    this.$map.current.innerHTML = text;
  }

  initMap() {
    if (!this.$map.current) {
      return;
    }

    const { LatLng, Map } = window.google.maps;
    const center = new LatLng(16.8661, 96.1951); // Yangon

    this.$map.current.style.height = "300px";
    this.map = new Map(this.$map.current, {
      zoom: 15,
      center
    });
  }

  componentDidUpdate() {
    const { loading, data } = this.props;
    if (!loading && data.location) {
      window.google
        ? this.addMarker(data.location)
        : this.rawLocation(data.location);
    }
  }

  addMarker(location) {
    const { Marker, Animation, InfoWindow, LatLng } = window.google.maps;
    const position = new LatLng(location.split(",")[0], location.split(",")[1]);
    this.map.setCenter(position);
    const marker = new Marker({
      position,
      map: this.map,
      animation: Animation.DROP
    });

    const info = new InfoWindow({
      content: location
    });

    marker.addListener("click", e => {
      info.open(this.map, marker);
    });
  }

  render() {
    const { data, orderStatuses, companyURL } = this.props;

    return (
      <Form onSubmit={this.props.onSubmit} defaultValues={data}>
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <div className="card">
              <div ref={this.$map} />
              <div className="card-content">
                <PrintButton componentRef={this.componentRef} />
                <Details
                  data={data}
                  ref={el => (this.componentRef = el)}
                  orderStatuses={orderStatuses}
                  companyURL={companyURL}
                />
                <PrintButton componentRef={this.componentRef} />
              </div>
            </div>
          </form>
        )}
      </Form>
    );
  }
}
