import React, { useRef, useState, useMemo } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import DraggableMarker from "./DraggableMarker";
import "leaflet/dist/leaflet.css";
import { Field } from "react-form";

export default function Map({ style, formApi, field }) {
  const markerRef = useRef();
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          formApi.setValue(field, marker.getLatLng());
        }
      },
    }),
    []
  );

  return (
    <Field field={field}>
      {(fieldApi) => (
        <MapContainer
          zoom={13}
          center={fieldApi.value}
          scrollWheelZoom={false}
          style={style}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <DraggableMarker
            center={fieldApi.value}
            eventHandlers={eventHandlers}
            ref={markerRef}
          />
        </MapContainer>
      )}
    </Field>
  );
}
