export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const FETCH_COMPANY = "FETCH_COMPANY";

export function fetchCompanies(params = {}) {
  params = Object.assign({}, {}, params);
  return {
    type: FETCH_COMPANIES,
    auth: true,
    payload: {
      url: "resource/companies",
      method: "GET",
      params
    }
  };
}

export function fetchCompany(id, params = {}) {
  params = Object.assign({}, { partials: "children" }, params);
  return {
    type: FETCH_COMPANY,
    auth: true,
    payload: {
      url: `resource/companies/${id}`,
      method: "GET",
      params
    }
  };
}

export function fetchOpenCompanyByURL(url, params = {}) {
  params = Object.assign({}, { key: "url" }, params);
  return {
    type: FETCH_COMPANY,
    auth: false,
    payload: {
      url: `open/companies/${url}`,
      method: "GET",
      params
    }
  };
}
