import React, { memo } from "react";
import PaginationLink from "./PaginationLink";
import PaginationEllipsis from "./PaginationEllipsis";

export default memo(({ refine, currentRefinement, nbPages, range }) => {
  const list = [];
  range = range || 4;
  const halfOfRange = Math.floor(range / 2);

  list.push(
    <PaginationLink
      key="1"
      page="1"
      refine={refine}
      isCurrent={1 === currentRefinement}
    />
  );

  if (currentRefinement > range && currentRefinement - range !== 1) {
    list.push(<PaginationEllipsis key="start-ellipsis" />);
  }

  for (
    let index = currentRefinement - halfOfRange;
    index <= currentRefinement + halfOfRange;
    index++
  ) {
    if (index > 1 && index < nbPages) {
      list.push(
        <PaginationLink
          key={index}
          page={index}
          refine={refine}
          isCurrent={index === currentRefinement}
        />
      );
    }
  }

  if (
    currentRefinement < nbPages - range + 1 &&
    currentRefinement + range !== nbPages
  ) {
    list.push(<PaginationEllipsis key="end-ellipsis" />);
  }

  list.push(
    <PaginationLink
      key={nbPages}
      page={nbPages}
      refine={refine}
      isCurrent={nbPages === currentRefinement}
    />
  );

  return <ul className="pagination-list">{list}</ul>;
});
