import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_PAYMENT_GATEWAY,
  FETCH_PAYMENT_GATEWAYS,
  DELETE_PAYMENT_GATEWAY,
  CREATE_PAYMENT_GATEWAY,
  UPDATE_PAYMENT_GATEWAY
} from "actions/paymentGateway";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function paymentGateway(
  state = SINGLE_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${FETCH_PAYMENT_GATEWAY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_PAYMENT_GATEWAY}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${FETCH_PAYMENT_GATEWAY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function paymentGateways(
  state = MULTI_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${FETCH_PAYMENT_GATEWAYS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_PAYMENT_GATEWAYS}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_PAYMENT_GATEWAYS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deletepaymentGateway(
  state = SINGLE_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${DELETE_PAYMENT_GATEWAY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_PAYMENT_GATEWAY}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${DELETE_PAYMENT_GATEWAY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createpaymentGateway(
  state = SINGLE_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${CREATE_PAYMENT_GATEWAY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_PAYMENT_GATEWAY}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${CREATE_PAYMENT_GATEWAY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updatepaymentGateway(
  state = SINGLE_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${UPDATE_PAYMENT_GATEWAY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_PAYMENT_GATEWAY}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${UPDATE_PAYMENT_GATEWAY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
