import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_HIGHLIGHT_ACTIONS,
  FETCH_HIGHLIGHT_ACTION,
} from "../actions/highlightAction";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false,
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false,
};

export function highlightActions(
  state = MULTI_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${FETCH_HIGHLIGHT_ACTIONS}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_HIGHLIGHT_ACTIONS}_${FULFILLED}`:
      return Object.assign({}, state, payload, {
        loading: false,
        error: null,
      });
    case `${FETCH_HIGHLIGHT_ACTIONS}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}

export function highlightAction(
  state = SINGLE_DATA_INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case `${FETCH_HIGHLIGHT_ACTION}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_HIGHLIGHT_ACTION}_${FULFILLED}`:
      return Object.assign(
        {},
        state,
        { data: payload.data },
        { loading: false, error: null }
      );
    case `${FETCH_HIGHLIGHT_ACTION}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload,
      });
    default:
      return state;
  }
}
