import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";

export default connectSearchBox(({ currentRefinement, refine }) => (
  <div className="field has-addons">
    <p className="control is-expanded">
      <input
        onChange={e => {
          refine(e.target.value);
        }}
        value={currentRefinement}
        field="search_term"
        placeholder="Search here..."
        className="input"
        required
      />
    </p>
    <p className="control">
      <button
        type="submit"
        className="button is-info"
        onClick={() => {
          refine("");
        }}
      >
        Clear
      </button>
    </p>
  </div>
));
