import React, { forwardRef } from "react";
import Baskets from "./Baskets";
import DateUtils from "utils/date";
import { Select, TextArea } from "react-form";
import { Link } from "react-router-dom";
import DeliveryAddress from "./DeliveryAddress";
import PaymentInfo from "./PaymentInfo";

const getPageMargins = () => {
  return `@page { margin: 20px !important; }`;
};

export default forwardRef(({ data, orderStatuses, companyURL }, ref) => (
  <div ref={ref}>
    <style type="text/css" media="print">
      {getPageMargins()}
    </style>
    <div className="media">
      <div className="media-content">
        <p className="title is-4" style={{ marginTop: 20 }}>
          {data.customer ? (
            <Link
              to={`/${companyURL}/customers/${data.customer_id}/edit`}
              className="is-link is-small"
            >
              {data.customer?.name}
            </Link>
          ) : (
            data.name
          )}
        </p>
        <p className="subtitle is-6">
          {data.phone_number ? data.phone_number : data.customer?.phone}
        </p>
      </div>
    </div>
    <div className="media">
      <div className="media-content">
        <div>
          ID: <b>{data.id}</b>
        </div>
        <div style={{ marginTop: 10 }}>
          Ordered On: <b>{DateUtils.ISOToDateTime(data.created_at)}</b>
        </div>
        <div style={{ marginTop: 10 }}>
          Address: <b>{data.address}</b>
        </div>
        {data.delivery_zone && (
          <div style={{ marginTop: 10 }}>
            <div>
              City: <b>{data.delivery_zone.delivery.city}</b>
            </div>
            <div>
              Township: <b>{data.delivery_zone.delivery.township}</b>
            </div>
            <div>
              Zone: <b>{data.delivery_zone.zone}</b>
            </div>
            <div>
              Delivery Charges: <b>{data.delivery_charges}</b>
            </div>
          </div>
        )}
        <div style={{ marginTop: 10 }}>
          Note: <b>{data.note}</b>
        </div>
        <div style={{ marginTop: 10 }}>
          Updated at: <b>{DateUtils.ISOToDateTime(data.updated_at)}</b>
        </div>
        <div style={{ marginTop: 10 }}>
          Status:{" "}
          <span className="select">
            <Select
              field="order_status_id"
              placeholder="Nothing"
              label="Status"
              fullwidth={false}
              options={orderStatuses.data.map(v => ({
                label: v.status,
                value: v.id
              }))}
            />
          </span>
        </div>
        {data.payment_gateway && (
          <div style={{ marginTop: 10 }}>
            <PaymentInfo data={data} />
          </div>
        )}
        {data.delivery_address && (
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <h6 class="title is-5">Delivery Address: </h6>
            <DeliveryAddress {...data.delivery_address} />
          </div>
        )}
        <div style={{ marginTop: 10 }}>
          Internal Note:{" "}
          <TextArea
            className="textarea"
            field="internal_note"
            placeholder="Internal Note"
            label="Internal Note"
          />
        </div>
      </div>
    </div>
    <button className="button is-fullwidth is-info is-medium">Submit</button>
    <div className="media">
      <div className="media-content">
        <Baskets baskets={data.baskets} />
      </div>
    </div>
  </div>
));
