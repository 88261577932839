import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { startOfWeek, endOfWeek } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

export default function CustomDateRangePicker({
  text = "Filter by Date",
  onChange,
  ...props
}) {
  const defaultRange = {
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
    key: "selection",
  };
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState(defaultRange);

  const onRangeChange = ({ selection }) => setRange(selection);

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  const onApply = () => {
    onChange(range);
    setOpen(false);
  };

  const onReset = () => {
    onChange({
      starteDate: "",
      endDate: "",
    });
    setOpen(false);
  };
  return (
    <>
      <a className="button" onClick={onOpen}>
        <span className="icon">
          <i className={`fas fa-filter`} />
        </span>
        <span>{text}</span>
      </a>
      {open && (
        <>
          <DateRangePicker
            {...props}
            ranges={[range]}
            onChange={onRangeChange}
          />
          <button type="button" className="button is-success" onClick={onApply}>
            Apply
          </button>
          <button type="button" className="button" onClick={onReset}>
            Reset
          </button>
          <button type="button" className="button" onClick={onClose}>
            Close
          </button>
        </>
      )}
    </>
  );
}
