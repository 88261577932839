import React, { Component } from "react";
import { Form } from "react-form";
import { hasFeature } from "utils/feature";

import InputText from "components/form-fields/InputText";
import TextArea from "components/form-fields/TextArea";
import ImageField from "components/form-fields/ImageField";

class SettingForm extends Component {
  constructor(props) {
    super(props);
    this.defaultCoverNLogo = { cover: undefined, logo: undefined };
  }

  onClickRemove = name => event => {
    this.props.removeUploadedImage(name);
    if (name === "logo" || name === "cover") {
      this.defaultCoverNLogo = Object.assign(
        this.defaultCoverNLogo,
        {},
        { [name]: null }
      );
    }
  };

  onImageFieldChange = name => event => {
    const files = event.target.files || event.dataTransfer.files;
    if (!files[0]) return;

    const photo = new Image();
    photo.onload = () => {
      const data = new FormData();
      data.append("file", files[0]);
      this.props.uploadImage(name, data).then(() => {
        this.props.setUploadedImagePreview(name, photo.src);
      });
    };
    photo.src = window.URL.createObjectURL(files[0]);
  };

  onSubmit = data => {
    const { uploadedImages } = this.props;

    if (uploadedImages) {
      const shapedCoverNLogoDataStructure = Object.keys(uploadedImages).reduce(
        (result, key) => {
          result[key] = uploadedImages[key].data.file;
          return result;
        },
        {}
      );
      data = Object.assign(
        {},
        data,
        this.defaultCoverNLogo,
        shapedCoverNLogoDataStructure
      );
    }

    this.props.handleFormSubmit(data);
  };

  render() {
    const {
      onClickRemove,
      onImageFieldChange,
      props: {
        company,
        uploadedImagesPreview,
        uploadedImagesPercentage,
        defaultValues,
        companyProfileTypes
      }
    } = this;

    return (
      <Form onSubmit={this.onSubmit} defaultValues={defaultValues}>
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <div className="box">
              <ImageField
                field="cover"
                label="Cover Photo"
                uploadPercentage={uploadedImagesPercentage.cover}
                preview={uploadedImagesPreview.cover}
                onChange={onImageFieldChange("cover")}
                onClickRemove={onClickRemove("cover")}
              />
              <hr />
              <ImageField
                field="logo"
                label="Logo"
                uploadPercentage={uploadedImagesPercentage.logo}
                preview={uploadedImagesPreview.logo}
                onChange={onImageFieldChange("logo")}
                onClickRemove={onClickRemove("logo")}
              />
            </div>

            <div className="box">
              <div className="columns">
                <div className="column">
                  <InputText
                    required
                    field="about_label"
                    label="About label"
                    placeholder="About label"
                  />
                </div>
                <div className="column">
                  <InputText
                    required
                    field="contact_label"
                    label="Contact label"
                    placeholder="Contact label"
                  />
                </div>
                <div className="column">
                  <InputText
                    required
                    field="product_label"
                    label="Product label"
                    placeholder="Product label"
                  />
                </div>
                <div className="column">
                  <InputText
                    required
                    field="currency_label"
                    label="Currency label"
                    placeholder="Currency label"
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <InputText
                    field="expiry_warning"
                    label="Expiry warning"
                    placeholder="Expiry warning"
                  />
                </div>
              </div>
              {hasFeature(company, "push_notification") && (
                <div className="columns">
                  <div className="column">
                    <InputText
                      field="others.push_notification.package"
                      label="Push Notification Package Name"
                      placeholder="Push Notification Package Name"
                    />
                  </div>
                  <div className="column">
                    <TextArea
                      field="others.push_notification.key"
                      label="Push Notification Key"
                      placeholder="Push Notification Package Name"
                    />
                  </div>
                </div>
              )}

              <div className="columns">
                <div className="column"></div>
              </div>
            </div>
            {companyProfileTypes.data ? (
              <div className="box">
                {companyProfileTypes.data.map((companyProfileType, key) => (
                  <div className="column" key={key}>
                    <InputText
                      field={`profile.${companyProfileType.name}`}
                      label={companyProfileType.label}
                      placeholder={companyProfileType.label}
                    />
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            <button className="button is-fullwidth is-info is-medium">
              Submit
            </button>
          </form>
        )}
      </Form>
    );
  }
}
export default SettingForm;
