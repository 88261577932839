import React from "react";
import formatCurrency from "utils/format-currency";

export default function PurchaseHistory({ orders }) {
  console.log(orders);
  return (
    <table
      className="table is-fullwidth is-striped is-hoverable"
      style={{ marginTop: 15 }}
    >
      <thead>
        <tr>
          <th>ID</th>
          <th>Order Date</th>
          <th>Status</th>
          <th style={{ textAlign: "right" }}>Amount</th>
        </tr>
      </thead>
      <tbody>
        {orders?.map(order => (
          <tr key={order.id}>
            <td>{order.id}</td>
            <td>{order.created_at}</td>
            <td>{order.status}</td>
            <td style={{ textAlign: "right" }}>
              {formatCurrency(order.amount)}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th></th>
          <th></th>
          <th>Total</th>
          <td style={{ textAlign: "right" }}>
            {orders?.length > 0 &&
              formatCurrency(
                orders
                  .map(o => o.amount)
                  .reduce((a, b) => parseFloat(a) + parseFloat(b))
              )}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
