/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Form, NestedField } from "react-form";
import isEmpty from "lodash.isempty";

import InputText from "components/form-fields/InputText";
import Select from "components/form-fields/Select";
import AttachmentFieldGroup from "components/form-fields/AttachmentFieldGroup";
import ImageField from "components/form-fields/ImageField";
import LocationField from "components/form-fields/LocationField";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.removedImages = { cover: undefined, logo: undefined };
  }

  onClickRemove = name => event => {
    this.props.removeUploadedImage(name);
    this.removedImages = Object.assign(
      this.removedImages,
      {},
      { [name]: null }
    );
  };

  onImageFieldChange = name => event => {
    const files = event.target.files || event.dataTransfer.files;
    if (!files[0]) return;

    const photo = new Image();
    photo.onload = () => {
      const data = new FormData();
      data.append("file", files[0]);
      this.props.uploadImage(name, data).then(() => {
        this.props.setUploadedImagePreview(name, photo.src);
      });
    };
    photo.src = window.URL.createObjectURL(files[0]);
  };

  onSubmit = data => {
    const { uploadedImages } = this.props;

    const shapedAttachmentDataStructure =
      data.attachments &&
      data.attachments.map(({ photo, uid, ...rest }) => {
        photo = undefined;
        if (uploadedImages[uid]) {
          photo = uploadedImages[uid].data.file;
        } else if (this.removedImages[uid] === null) {
          photo = null;
        }
        return Object.assign({}, { ...rest, photo });
      });

    if (uploadedImages) {
      const shapedImagesStructure = Object.keys(uploadedImages).reduce(
        (result, key) => {
          result[key] = uploadedImages[key].data.file;
          return result;
        },
        {}
      );
      data = Object.assign({}, data, this.removedImages, shapedImagesStructure);
    }

    data = Object.assign({}, data, {
      attachments: shapedAttachmentDataStructure
    });

    this.props.handleFormSubmit(data);
  };

  render() {
    const {
      onClickRemove,
      onImageFieldChange,
      props: { uploadedImagesPreview, uploadedImagesPercentage, defaultValues }
    } = this;

    return (
      <Form onSubmit={this.onSubmit} defaultValues={defaultValues}>
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <div className="box">
              <ImageField
                field="cover"
                label="Cover Photo"
                uploadPercentage={uploadedImagesPercentage.cover}
                preview={uploadedImagesPreview.cover}
                onChange={onImageFieldChange("cover")}
                onClickRemove={onClickRemove("cover")}
              />
              <hr />
              <ImageField
                field="logo"
                label="Logo"
                uploadPercentage={uploadedImagesPercentage.logo}
                preview={uploadedImagesPreview.logo}
                onChange={onImageFieldChange("logo")}
                onClickRemove={onClickRemove("logo")}
              />
            </div>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <InputText
                    required
                    field="title"
                    label="Title"
                    placeholder="Title"
                  />
                </div>

                <div className="column">
                  <InputText
                    field="weight"
                    label="Weight"
                    type="number"
                    min="-32768"
                    max="32767"
                    placeholder="Weight"
                  />
                </div>
                <div className="column">
                  <Select
                    field="freezed"
                    placeholder="Is feezed?"
                    label="Freezed"
                    options={[
                      { label: "No", value: 0 },
                      { label: "Yes", value: 1 }
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="box">
              <div className="columns">
                <div className="column is-half-desktop">
                  <LocationField
                    formApi={formApi}
                    field="location"
                    label="Location"
                  />
                </div>
              </div>
            </div>
            <div className="box is-clearfix">
              <Select
                fullwidth={false}
                field="text_first"
                placeholder="Priority"
                label="Priority"
                options={[
                  { label: "Image first", value: 0 },
                  { label: "Text first", value: 1 }
                ]}
              />

              {formApi.values.attachments &&
                formApi.values.attachments.map((attachment, key) => (
                  <NestedField
                    photo={{
                      onImageFieldChange,
                      onImageRemove: onClickRemove,
                      uploadedImagesPreview,
                      uploadedImagesPercentage
                    }}
                    component={AttachmentFieldGroup}
                    formApi={formApi}
                    field={["attachments", key]}
                    key={key}
                  />
                ))}
              <p className="title is-6">
                <a
                  className="button is-info"
                  onClick={() => {
                    const { attachments } = formApi.values;
                    const weight = isEmpty(attachments)
                      ? 1
                      : attachments[attachments.length - 1].weight + 1;
                    formApi.addValue("attachments", { weight });
                  }}
                >
                  <span>Add Text/Photo</span>
                  <span className="icon is-small">
                    <i className="fas fa-plus fa-lg" />
                  </span>
                </a>
              </p>
            </div>

            <button className="button is-fullwidth is-info is-medium">
              Submit
            </button>
          </form>
        )}
      </Form>
    );
  }
}
export default ContactForm;
