import React, { memo } from "react";
import { InstantSearch, Configure } from "react-instantsearch-dom";

export default memo(({ children, companyID, ...props }) => (
  <InstantSearch
    appId={process.env.REACT_APP_ALGOLIA_APP_ID}
    apiKey={process.env.REACT_APP_ALGOLIA_API_KEY}
    {...props}
  >
    <Configure hitsPerPage={15} filters={`company_id=${companyID}`} />
    {children.map(child => child)}
  </InstantSearch>
));
