export const FETCH_PUSH_NOTIFICATIONS = "FETCH_PUSH_NOTIFICATIONS";
export const FETCH_PUSH_NOTIFICATION = "FETCH_PUSH_NOTIFICATION";
export const CREATE_PUSH_NOTIFICATION = "CREATE_PUSH_NOTIFICATION";
export const UPDATE_PUSH_NOTIFICATION = "UPDATE_PUSH_NOTIFICATION";
export const DELETE_PUSH_NOTIFICATION = "DELETE_PUSH_NOTIFICATION";

export function fetchPushNotifications(params = {}) {
  params = Object.assign({}, { orderBy: "id:desc" }, params);
  return {
    type: FETCH_PUSH_NOTIFICATIONS,
    auth: true,
    payload: {
      url: "resource/push-notifications",
      method: "GET",
      params
    }
  };
}

export function fetchPushNotification(id, params = {}) {
  return {
    type: FETCH_PUSH_NOTIFICATION,
    auth: true,
    payload: {
      url: `resource/push-notifications/${id}`,
      method: "GET",
      params
    }
  };
}

export function createPushNotification(data = {}) {
  return {
    type: CREATE_PUSH_NOTIFICATION,
    auth: true,
    payload: {
      url: "resource/push-notifications",
      method: "POST",
      data
    }
  };
}

export function updatePushNotification(id, data = {}) {
  return {
    type: UPDATE_PUSH_NOTIFICATION,
    auth: true,
    payload: {
      url: `resource/push-notifications/${id}`,
      method: "PUT",
      data
    }
  };
}

export function deletePushNotification(id, data = {}) {
  return {
    type: DELETE_PUSH_NOTIFICATION,
    auth: true,
    payload: {
      url: `resource/push-notifications/${id}`,
      method: "DELETE",
      data
    }
  };
}
