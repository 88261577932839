import React, { Component } from "react";
import { Form, NestedField } from "react-form";

import InputText from "components/form-fields/InputText";
import TextArea from "components/form-fields/TextArea";
import CustomerAddress from "components/form-fields/CustomerAddress";
import Select from "components/form-fields/Select";
import Map from "components/form-fields/Map";

class CustomerForm extends Component {
  onSubmit = (data) => {
    this.props.handleFormSubmit(data);
  };

  render() {
    const {
      props: { defaultValues, customerTiers },
    } = this;

    return (
      <Form onSubmit={this.onSubmit} defaultValues={defaultValues}>
        {(formApi) => (
          <form onSubmit={formApi.submitForm}>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <Select
                    field="customer_tier_id"
                    placeholder="Customer Tier"
                    label="Customer Tier"
                    options={customerTiers.data.map((v) => ({
                      label: v.name,
                      value: v.id,
                    }))}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <InputText
                    required
                    field="name"
                    label="Name"
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <InputText
                    required
                    field="phone"
                    label="Phone Number"
                    placeholder="Phone Number"
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <InputText
                    field="birthdate"
                    label="Birth Date"
                    placeholder="Birth Date"
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TextArea
                    className="textarea"
                    field="address"
                    label="Address"
                    placeholder="Address"
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Map
                    field="location"
                    label="Location"
                    formApi={formApi}
                    style={{ minHeight: "250px" }}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TextArea
                    className="textarea"
                    field="note"
                    label="Note"
                    placeholder="Note"
                  />
                </div>
              </div>
            </div>
            <div className="box is-clearfix">
              {formApi.values.addresses &&
                formApi.values.addresses.map((variant, key) => (
                  <NestedField
                    component={CustomerAddress}
                    formApi={formApi}
                    field={["addresses", key]}
                    key={key}
                  />
                ))}
              <p className="title is-6">
                <a
                  className="button is-info"
                  onClick={() => {
                    formApi.addValue("addresses", {
                      is_default: (formApi.values.addresses ?? []).length === 0,
                    });
                  }}
                >
                  <span>Add Delivery Address</span>
                  <span className="icon is-small">
                    <i className="fas fa-plus fa-lg" />
                  </span>
                </a>
              </p>
            </div>
            <button className="button is-fullwidth is-info is-medium">
              Submit
            </button>
          </form>
        )}
      </Form>
    );
  }
}
export default CustomerForm;
