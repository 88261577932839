import React from "react";
import { Checkbox } from "react-form";

const InputTextField = ({ label, required, ...rest }) => {
  return (
    <div className="field">

      <p className="control">
        <label className="checkbox">
          <Checkbox className="checkbox" required={required} {...rest} />
          &nbsp;{label}
          {required && <span className="required">*</span>}
        </label>
      </p>
      {/* <p className={`help ${helpClass}`}>{helpMessage}</p> */}
    </div>
  );
};

export default InputTextField;
