import React, { memo } from "react";

export default memo(({ onChange, defaultValue }) => (
  <div className="field">
    <div className="control has-icons-left">
      <div className="select">
        <select onChange={onChange} defaultValue={defaultValue}>
          <option value="1">Archive</option>
          <option value="0">Unarchive</option>
        </select>
      </div>
      <div className="icon is-small is-left">
        <i className="fas fa-filter" />
      </div>
    </div>
  </div>
));
