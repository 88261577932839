/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import InputText from "components/form-fields/InputText";
import Select from "./Select";
import ImageField from "components/form-fields/ImageField";
import uniqid from "uniqid";
import HiddenField from "components/form-fields/HiddenField";
import ProductVariantOption from "./ProductVariantOption";
import CustomerTierPriceGroup from "components/form-fields/CustomerTierPriceGroup";
import { NestedField } from "react-form";

export default memo(
  ({
    formApi,
    fieldApi,
    field,
    promotionTypes,
    setting,
    productStatuses,
    currencies,
    variantOptions,
    customerTiers,
    photo: {
      uploadedImagesPercentage,
      uploadedImagesPreview,
      onImageFieldChange,
      onImageRemove
    }
  }) => {
    const uniqueImageName = fieldApi.getValue("uid");
    const [, key] = field;
    return (
      <div className="box">
        <a
          href="#/"
          className="button is-danger is-outlined is-small is-pulled-right"
          onClick={e => {
            e.preventDefault();
            formApi.removeValue("product_variants", key);
          }}
        >
          <span>Delete</span>
          <span className="icon is-small">
            <i className="fas fa-times fa-lg" />
          </span>
        </a>
        <div className="columns">
          <div className="column">
            <ImageField
              field="cover"
              label="Cover Photo"
              uploadPercentage={
                uploadedImagesPercentage[`${uniqueImageName}_cover`]
              }
              preview={uploadedImagesPreview[`${uniqueImageName}_cover`]}
              onChange={onImageFieldChange(`${uniqueImageName}_cover`)}
              onClickRemove={onImageRemove(`${uniqueImageName}_cover`)}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <ImageField
              field="logo"
              label="Logo"
              uploadPercentage={
                uploadedImagesPercentage[`${uniqueImageName}_logo`]
              }
              preview={uploadedImagesPreview[`${uniqueImageName}_logo`]}
              onChange={onImageFieldChange(`${uniqueImageName}_logo`)}
              onClickRemove={onImageRemove(`${uniqueImageName}_logo`)}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <InputText
              field="title"
              label="Title"
              placeholder="Title"
              required
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {fieldApi.values.variant_options &&
              fieldApi.values.variant_options.map((opt, k) => (
                <NestedField
                  component={ProductVariantOption}
                  variantOptions={variantOptions}
                  field={["variant_options", k]}
                  formApi={formApi}
                  parentFieldApi={fieldApi}
                  key={k}
                />
              ))}
            <p className="title is-6">
              <a
                className="button is-info"
                onClick={() => {
                  fieldApi.addValue("variant_options", {});
                }}
              >
                <span>Add Variant Option</span>
                <span className="icon is-small">
                  <i className="fas fa-plus fa-lg" />
                </span>
              </a>
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <InputText field="sku" label="SKU" placeholder="SKU" required />
          </div>
          <div className="column">
            <InputText
              field="stock"
              type="number"
              label="Stock"
              placeholder="Stock"
              required
            />
          </div>
          <div className="column">
            <InputText
              field="weight"
              label="Weight"
              type="number"
              min="-32768"
              max="32767"
              placeholder="Weight"
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <InputText
              field="promotion"
              label="Promotion"
              type="number"
              max={formApi.values.promotion_type_id === 1 ? 100 : undefined}
              placeholder="Promotion"
            />
          </div>
          <div className="column">
            <InputText
              field="promotion_params"
              label="Promotion Params"
              placeholder="Promotion Params"
            />
          </div>
          <div className="column">
            <Select
              field="promotion_type_id"
              placeholder="Promotion Type"
              label="Promotion Type"
              options={promotionTypes.data.map(v => ({
                label: v.name,
                value: v.id
              }))}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <InputText
              field="price"
              label="Price"
              type="number"
              placeholder="Price"
              required
            />
          </div>
          <div className="column">
            <Select
              field="currency_id"
              required
              placeholder={setting.data.currency_label}
              label={setting.data.currency_label}
              options={currencies.data.map(v => ({
                label: v.name,
                value: v.id
              }))}
            />
          </div>
          <div className="column">
            <Select
              field="status_id"
              placeholder="Nothing"
              label="Status"
              required
              options={productStatuses.data.map(v => ({
                label: v.status,
                value: v.id
              }))}
            />
          </div>
        </div>
        <div className="box is-clearfix">
          {fieldApi.values.product_prices &&
            fieldApi.values.product_prices.map((price, key) => (
              <NestedField
                component={CustomerTierPriceGroup}
                customerTiers={customerTiers}
                currencies={currencies}
                setting={setting}
                formApi={formApi}
                parentFieldApi={fieldApi}
                field={["product_prices", key]}
                key={key}
              />
            ))}
          <p className="title is-6">
            <a
              className="button is-info"
              onClick={() => {
                fieldApi.addValue("product_prices", {});
              }}
            >
              <span>Add Customer Tier Price</span>
              <span className="icon is-small">
                <i className="fas fa-plus fa-lg" />
              </span>
            </a>
          </p>
        </div>
        <HiddenField
          field="uid"
          defaultValue={uniqid.time("product_variant_")}
        />
      </div>
    );
  }
);
