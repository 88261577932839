/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import isEmpty from "lodash.isempty";

const DeliveriesTable = ({ deliveries, companyURL, onClickDelete }) => {
  const TableOrNotFound = () => {
    if (deliveries.loading) {
      return "is Loading";
    }
    if (isEmpty(deliveries.data)) {
      return (
        <p className="is-size-2 has-text-centered has-text-grey">
          Found Nothing
        </p>
      );
    }
    return (
      <table className="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>ID</th>
            <th>City</th>
            <th>Township</th>
            <th>Zones</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {deliveries.data.map(delivery => (
            <tr key={delivery.id}>
              <td>{delivery.id}</td>
              <td>{delivery.city}</td>
              <td>{delivery.township}</td>
              <td>{delivery.zones?.map(z => z.zone).join(", ")}</td>

              <td className="is-icon">
                <p className="buttons">
                  <Link
                    to={`/${companyURL}/deliveries/${delivery.id}/edit`}
                    className="button  is-link is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-edit" />
                    </span>
                    <span>Update</span>
                  </Link>
                  <a
                    onClick={onClickDelete(delivery.id)}
                    className="button is-danger is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-trash" />
                    </span>
                    <span>Delete</span>
                  </a>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <section className="section">
      <TableOrNotFound />
    </section>
  );
};
export default DeliveriesTable;
