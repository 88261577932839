import React, { Component } from "react";
import { Form } from "react-form";

import InputText from "components/form-fields/InputText";
import Select from "components/form-fields/Select";
import ImageField from "components/form-fields/ImageField";

class ProductCategoryForm extends Component {
  onImageRemove = name => event => {
    this.props.removeUploadedImage(name);
    this.removedImages = Object.assign(
      this.removedImages,
      {},
      { [name]: null }
    );
  };

  onImageFieldChange = name => event => {
    const files = event.target.files || event.dataTransfer.files;
    if (!files[0]) return;

    const photo = new Image();
    photo.onload = () => {
      const data = new FormData();
      data.append("file", files[0]);
      this.props.uploadImage(name, data).then(() => {
        this.props.setUploadedImagePreview(name, photo.src);
      });
    };
    photo.src = window.URL.createObjectURL(files[0]);
  };

  onSubmit = data => {
    const { uploadedImages } = this.props;

    if (uploadedImages) {
      const shapedImagesStructure = Object.keys(uploadedImages).reduce(
        (result, key) => {
          result[key] = uploadedImages[key].data.file;
          return result;
        },
        {}
      );
      data = Object.assign({}, data, this.removedImages, shapedImagesStructure);
    }

    this.props.handleFormSubmit(data);
  };
  render() {
    const {
      onSubmit,
      onImageFieldChange,
      onImageRemove,
      props: {
        defaultValues,
        productStatuses,
        productCategories,
        uploadedImagesPercentage,
        uploadedImagesPreview
      }
    } = this;

    return (
      <Form onSubmit={onSubmit} defaultValues={defaultValues}>
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <ImageField
                    field="cover"
                    label="Cover Photo"
                    uploadPercentage={uploadedImagesPercentage.cover}
                    preview={uploadedImagesPreview.cover}
                    onChange={onImageFieldChange("cover")}
                    onClickRemove={onImageRemove("cover")}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <ImageField
                    field="logo"
                    label="Logo"
                    uploadPercentage={uploadedImagesPercentage.logo}
                    preview={uploadedImagesPreview.logo}
                    onChange={onImageFieldChange("logo")}
                    onClickRemove={onImageRemove("logo")}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <InputText
                    required
                    field="name"
                    label="Name"
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Select
                    field="parent_id"
                    placeholder="Parent Category"
                    label="Parent Category"
                    options={productCategories.data.map(v => ({
                      label: v.name,
                      value: v.id
                    }))}
                  />
                </div>
                <div className="column">
                  <InputText
                    required
                    field="weight"
                    placeholder="Weight"
                    label="Weight"
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <InputText
                    field="released_at"
                    label="Released at"
                    type="date"
                    placeholder="Released at"
                  />
                </div>
                <div className="column">
                  <InputText
                    field="expired_at"
                    label="Expired at"
                    type="date"
                    placeholder="Expired at"
                  />
                </div>
                <div className="column">
                  <Select
                    field="status_id"
                    placeholder="Status"
                    label="Status"
                    required
                    options={productStatuses.data.map(v => ({
                      label: v.status,
                      value: v.id
                    }))}
                  />
                </div>
              </div>
            </div>

            <button className="button is-fullwidth is-info is-medium">
              Submit
            </button>
          </form>
        )}
      </Form>
    );
  }
}

export default ProductCategoryForm;
