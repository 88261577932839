export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

export function fetchProducts(params = {}) {
  params = Object.assign(
    {},
    { partials: "attachments", orderBy: "id:desc" },
    params
  );
  return {
    type: FETCH_PRODUCTS,
    auth: true,
    payload: {
      url: "resource/products",
      method: "GET",
      params,
    },
  };
}

export function fetchProduct(id, params = {}) {
  params = Object.assign(
    {},
    {
      partials:
        "attachments|tags|filterTags|groups|productCategories(parent)|productPrices|productVariants(variantOptions|prices)",
    },
    params
  );
  return {
    type: FETCH_PRODUCT,
    auth: true,
    payload: {
      url: `resource/products/${id}`,
      method: "GET",
      params,
    },
  };
}

export function createProduct(data = {}) {
  return {
    type: CREATE_PRODUCT,
    auth: true,
    payload: {
      url: "resource/products",
      method: "POST",
      data,
    },
  };
}

export function updateProduct(id, data = {}) {
  return {
    type: UPDATE_PRODUCT,
    auth: true,
    payload: {
      url: `resource/products/${id}`,
      method: "PUT",
      data,
    },
  };
}

export function deleteProduct(id, data = {}) {
  return {
    type: DELETE_PRODUCT,
    auth: true,
    payload: {
      url: `resource/products/${id}`,
      method: "DELETE",
      data,
    },
  };
}
