import { PENDING, FULFILLED, REJECTED } from "redux-promise-middleware";
import {
  FETCH_PRODUCT_CATEGORY,
  FETCH_PRODUCT_CATEGORIES,
  DELETE_PRODUCT_CATEGORY,
  CREATE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY
} from "actions/productCategory";

const SINGLE_DATA_INITIAL_STATE = {
  data: {},
  error: null,
  loading: false
};
const MULTI_DATA_INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export function productCategory(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_PRODUCT_CATEGORY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_PRODUCT_CATEGORY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${FETCH_PRODUCT_CATEGORY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function productCategories(state = MULTI_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${FETCH_PRODUCT_CATEGORIES}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${FETCH_PRODUCT_CATEGORIES}_${FULFILLED}`:
      return Object.assign({}, state, payload.data, {
        loading: false,
        error: null
      });
    case `${FETCH_PRODUCT_CATEGORIES}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function deleteCategory(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${DELETE_PRODUCT_CATEGORY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${DELETE_PRODUCT_CATEGORY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${DELETE_PRODUCT_CATEGORY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function createCategory(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${CREATE_PRODUCT_CATEGORY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${CREATE_PRODUCT_CATEGORY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${CREATE_PRODUCT_CATEGORY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export function updateCategory(state = SINGLE_DATA_INITIAL_STATE, { type, payload }) {
  switch (type) {
    case `${UPDATE_PRODUCT_CATEGORY}_${PENDING}`:
      return Object.assign({}, state, { loading: true, error: null });
    case `${UPDATE_PRODUCT_CATEGORY}_${FULFILLED}`:
      return Object.assign({}, state, { data: payload.data }, { loading: false, error: null });
    case `${UPDATE_PRODUCT_CATEGORY}_${REJECTED}`:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}
