import React from "react";
import { hasFeature } from "utils/feature";
import { NavLink } from "react-router-dom";

export default function SidebarMenu({
  company,
  label,
  feature,
  link,
  addNew = true,
  addCategorize = false,
}) {
  return (
    <>
      {hasFeature(company, feature) && (
        <React.Fragment>
          <p className="menu-label">{label}</p>
          <ul className="menu-list">
            <li>
              <NavLink
                to={`/${company.url}/${link}`}
                activeClassName="is-active"
                exact
              >
                View All
              </NavLink>
            </li>
            {addNew && (
              <li>
                <NavLink
                  to={`/${company.url}/${link}/create`}
                  activeClassName="is-active"
                  exact
                >
                  Add New
                </NavLink>
              </li>
            )}
            {addCategorize && (
              <li>
                <NavLink
                  to={`/${company.url}/${link}/categorize`}
                  activeClassName="is-active"
                  exact
                >
                  Categorize
                </NavLink>
              </li>
            )}
          </ul>
        </React.Fragment>
      )}
    </>
  );
}
