/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import dateUtils from "utils/date";
import isEmpty from "lodash.isempty";

const HighlightsTable = ({ highlights, companyURL, onClickDelete }) => {
  const TableOrNotFound = () => {
    if (highlights.loading) {
      return "is Loading";
    }
    if (isEmpty(highlights.data)) {
      return (
        <p className="is-size-2 has-text-centered has-text-grey">
          Found Nothing
        </p>
      );
    }
    return (
      <table className="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Promotion</th>
            <th>Weight</th>
            <th>Freezed</th>
            <th>Released at</th>
            <th>Expired at</th>
            <th>Attachment Count</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {highlights.data.map(highlight => (
            <tr key={highlight.id}>
              <td>{highlight.id}</td>
              <td>{highlight.title}</td>
              <td>{highlight.promotion}</td>
              <td>{highlight.weight}</td>
              <td>{highlight.freezed ? "Yes" : "No"}</td>
              <td>{dateUtils.phpFormatToJS(highlight.released_at)}</td>
              <td>{dateUtils.phpFormatToJS(highlight.expired_at)}</td>
              <td>
                {highlight.attachments ? highlight.attachments.length : 0}
              </td>

              <td className="is-icon">
                <p className="buttons">
                  <Link
                    to={`/${companyURL}/highlights/${highlight.id}/edit`}
                    className="button  is-link is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-edit" />
                    </span>
                    <span>Edit</span>
                  </Link>
                  <a
                    onClick={onClickDelete(highlight.id)}
                    to={`/${companyURL}/highlights/${highlight.id}/delete`}
                    className="button is-danger is-small"
                  >
                    <span className="icon">
                      <i className="fas fa-trash" />
                    </span>
                    <span>Delete</span>
                  </a>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <section className="section">
      <TableOrNotFound />
    </section>
  );
};
export default HighlightsTable;
