import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchDelivery, updateDelivery } from "actions/delivery";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "../Form";

class DeliveryEdit extends Component {
  componentDidMount() {
    const { activeCompany, match, fetchDelivery } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.deliveryId;
    fetchDelivery(id, { company_url });
  }

  updateDelivery(id, data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      updateDelivery,
      history
    } = this.props;

    toggleFullPageSpinner(true);

    updateDelivery(id, data)
      .then(() => {
        toggleNotification(true, {
          message: "Updated Delivery Success",
          infoClassName: "is-success"
        });
        history.push(`/${activeCompany.url}/deliveries`);
      })
      .catch(e => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger"
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = data => {
    const { activeCompany, match } = this.props;
    const id = match.params.deliveryId;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.updateDelivery(id, data);
  };

  shapedDefaultValuesStructure() {
    const { zones, ...rest } = this.props.delivery.data;
    return Object.assign(
      {},
      {
        ...rest,
        zones: zones || []
      }
    );
  }

  render() {
    const { delivery } = this.props;

    if (delivery.loading) {
      return "loading";
    }
    const defaultValues = this.shapedDefaultValuesStructure();
    return (
      <Form
        defaultValues={defaultValues}
        handleFormSubmit={this.handleFormSubmit}
      />
    );
  }
}

const mapStateToProps = ({ delivery }) => ({
  delivery
});

export default connect(mapStateToProps, {
  fetchDelivery,
  updateDelivery,
  toggleFullPageSpinner,
  toggleNotification
})(DeliveryEdit);
