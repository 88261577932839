import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchCustomer, updateCustomer } from "actions/customer";
import { fetchCustomerTiers } from "actions/customerTier";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "pages/Customers/Form";

class CustomerEdit extends Component {
  componentDidMount() {
    const {
      activeCompany,
      match,
      fetchCustomer,
      fetchCustomerTiers
    } = this.props;
    const company_url = activeCompany.url;
    const id = match.params.customerId;
    fetchCustomer(id, { company_url });
    fetchCustomerTiers({ company_url, "no-paginate": 1 });
  }

  shapedDefaultValuesStructure() {
    return this.props.customer.data;
  }

  updateCustomer(id, data) {
    const {
      toggleNotification,
      activeCompany,
      toggleFullPageSpinner,
      updateCustomer,
      history
    } = this.props;

    toggleFullPageSpinner(true);

    updateCustomer(id, data)
      .then(() => {
        toggleNotification(true, {
          message: "Updated Customer Success",
          infoClassName: "is-success"
        });
        history.push(`/${activeCompany.url}/customers`);
      })
      .catch(e => {
        toggleNotification(true, {
          message: e.message,
          infoClassName: "is-danger"
        });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  handleFormSubmit = data => {
    const { activeCompany, match } = this.props;
    const id = match.params.customerId;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.updateCustomer(id, data);
  };

  render() {
    const { customer, activeCompany, setting, customerTiers } = this.props;

    if (customer.loading) {
      return "loading";
    }
    const defaultValues = this.shapedDefaultValuesStructure();
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        defaultValues={defaultValues}
        setting={setting}
        activeCompany={activeCompany}
        customerTiers={customerTiers}
      />
    );
  }
}

const mapStateToProps = ({ customer, setting, customerTiers }) => ({
  customer,
  customerTiers,
  setting
});

export default connect(mapStateToProps, {
  fetchCustomer,
  fetchCustomerTiers,
  updateCustomer,
  toggleFullPageSpinner,
  toggleNotification
})(CustomerEdit);
