import React from "react";
import { Select } from "react-form";

const SelectField = ({ label, fullwidth, required, ...rest }) => (
  <div className="field">
    <label className="label">
      {label}
      {required && <span className="required">*</span>}
    </label>
    <div className="control">
      <div className={`select ${fullwidth !== false ? "is-fullwidth" : ""}`}>
        <Select {...rest} />
      </div>
    </div>
  </div>
);
export default SelectField;
