export const FETCH_CURRENCIES = "FETCH_CURRENCIES";
export const FETCH_CURRENCY = "FETCH_CURRENCY";
export const CREATE_CURRENCY = "CREATE_CURRENCY";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const DELETE_CURRENCY = "DELETE_CURRENCY";

export function fetchCurrencies(params = {}) {
  params = Object.assign({}, { orderBy: "id:desc" }, params);
  return {
    type: FETCH_CURRENCIES,
    auth: true,
    payload: {
      url: "resource/currencies",
      method: "GET",
      params
    }
  };
}

export function fetchCurrency(id, params = {}) {
  return {
    type: FETCH_CURRENCY,
    auth: true,
    payload: {
      url: `resource/currencies/${id}`,
      method: "GET",
      params
    }
  };
}

export function createCurrency(data = {}) {
  return {
    type: CREATE_CURRENCY,
    auth: true,
    payload: {
      url: "resource/currencies",
      method: "POST",
      data
    }
  };
}

export function updateCurrency(id, data = {}) {
  return {
    type: UPDATE_CURRENCY,
    auth: true,
    payload: {
      url: `resource/currencies/${id}`,
      method: "PUT",
      data
    }
  };
}

export function deleteCurrency(id, data = {}) {
  return {
    type: DELETE_CURRENCY,
    auth: true,
    payload: {
      url: `resource/currencies/${id}`,
      method: "DELETE",
      data
    }
  };
}
