import auth from "utils/auth";
import axios from "axios";
export * from "./fetchExportStatus";

const headers = () => {
  return {
    Accept: "application/json",
    Authorization: `Bearer ${auth.getToken()}`,
  };
};

export const exportCustomerOrders = (params) => {
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/resource/customers/${params.customer_id}/process/export`,
    {},
    {
      params,
      headers: headers(),
    }
  );
};
