/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, memo } from "react";
import ProcessingIcon from "./ProcessingIcon";

export default memo(({ params, onProcess, label, icon }) => {
  const [processing, setProcessing] = useState(false);
  const [processorId, setProcessorId] = useState(0);

  const onClick = e => {
    onProcess(params).then(r => {
      setProcessorId(r.data.id);
    });
    setProcessing(true);
  };

  return (
    <a
      className={`button ${processing ? "disabled" : ""}`}
      onClick={onClick}
    >
      {processing ? (
        <ProcessingIcon
          processorId={processorId}
          setProcessorId={setProcessorId}
          params={params}
          processing={processing}
          setProcessing={setProcessing}
        />
      ) : (
        <span className="icon">
          <i className={`fas ${icon}`} />
        </span>
      )}
      <span>{label}</span>
    </a>
  );
});
