import React, { memo } from "react";
// import { Editor } from "@tinymce/tinymce-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Field } from "react-form";
import isEmpty from "lodash.isempty";

export default memo(({ validator, field, label, required, placeholder }) => {
  const validate = value => ({
    error:
      required && !value
        ? "Please fill out this field."
        : validator && validator(value).error
  });

  return (
    <Field validate={validate} field={field}>
      {fieldApi => (
        <div className="field">
          <label className="label">{label}</label>
          <div className="control">
            <CKEditor
              editor={ClassicEditor}
              data={isEmpty(fieldApi.value) ? "" : fieldApi.value}
              onChange={(event, editor) => {
                const data = editor.getData();
                fieldApi.setValue(data);
              }}
              onInit={(editor) => {
                if (fieldApi.value) {
                  editor.setData(fieldApi.value);
                }
                editor.editing.view.change((writer) => {
                  console.log("asdasdonInit")
      
                  writer.setStyle(
                    "word-break",
                    "break-word",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}              
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "link",
                  "|",
                  "undo",
                  "redo"
                ],
                placeholder
              }}
            />
            {/* <Editor
              apiKey="91a40abjwzkjqa7hk0ojpkuzk0hnyz78yvq9h8v9gr359va2"
              height={500}
              initialValue={isEmpty(fieldApi.value) ? "" : fieldApi.value}
              className="textarea"
              init={{
                // menubar: false,
                plugins:
                  "print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount imagetools textpattern help",
                toolbar:
                  "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat"
              }}
              onEditorChange={(content, editor) => {
                fieldApi.setValue(content);
              }}
              onBlur={e => {
                fieldApi.setTouched();
              }}
            /> */}
          </div>
          {fieldApi.error && <p className="help is-danger">{fieldApi.error}</p>}
        </div>
      )}
    </Field>
  );
});
