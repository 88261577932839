import React, { Component } from "react";
import { connect } from "react-redux";

import { createContact } from "actions/contact";
import { uploadImage, setUploadedImagePreview, removeUploadedImage } from "actions/image";
import { toggleFullPageSpinner, toggleNotification } from "actions/toggle";

import Form from "../Form";

class ContactCreate extends Component {
  componentWillUnmount() {
    this.props.removeUploadedImage("cover");
    this.props.removeUploadedImage("logo");
  }
  handleFormSubmit = data => {
    const { activeCompany } = this.props;

    data = Object.assign({}, data, { company_url: activeCompany.url });
    this.createContact(data);
  };

  createContact(data) {
    const { toggleNotification, activeCompany, toggleFullPageSpinner, createContact, history } = this.props;
    toggleFullPageSpinner(true);
    createContact(data)
      .then(() => {
        toggleNotification(true, { message: "Created Contact Success", infoClassName: "is-success" });
        history.push(`/${activeCompany.url}/contacts`);
      })
      .catch(e => {
        toggleNotification(true, { message: e.message, infoClassName: "is-danger" });
      })
      .then(() => toggleFullPageSpinner(false));
  }

  render() {
    const {
      uploadedImagesPreview,
      uploadedImagesPercentage,
      uploadedImages,
      removeUploadedImage,
      uploadImage,
      setUploadedImagePreview
    } = this.props;
    return (
      <Form
        handleFormSubmit={this.handleFormSubmit}
        uploadImage={uploadImage}
        uploadedImages={uploadedImages}
        setUploadedImagePreview={setUploadedImagePreview}
        removeUploadedImage={removeUploadedImage}
        uploadedImagesPreview={uploadedImagesPreview}
        uploadedImagesPercentage={uploadedImagesPercentage}
      />
    );
  }
}

const mapStateToProps = ({ uploadedImages, uploadedImagesPercentage, uploadedImagesPreview }) => ({
  uploadedImages,
  uploadedImagesPercentage,
  uploadedImagesPreview
});

export default connect(
  mapStateToProps,
  {
    createContact,
    uploadImage,
    removeUploadedImage,
    setUploadedImagePreview,
    toggleFullPageSpinner,
    toggleNotification
  }
)(ContactCreate);
