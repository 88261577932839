import React, { memo } from "react";
export default memo(({ currentRefinement, refine, nbPages }) => (
  <a
    {...{
      className: "pagination-previous",
      disabled: currentRefinement <= 1,
      onClick:
        currentRefinement <= 1 ? undefined : () => refine(--currentRefinement)
    }}
  >
    Previous page
  </a>
));
