import axios from "axios";
import auth from "utils/auth";

const fetchApiMiddleware = store => next => action => {
  if (!action.payload || !action.payload.url) return next(action);

  return new Promise(resolve => {
    const headers = {
      Accept: "application/json"
    };
    if (false !== action.auth) {
      const token = auth.getToken();
      headers.Authorization = `Bearer ${token}`;
    }

    let apiRequest = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers,
      onUploadProgress:
        action.dispatchProgress && false !== action.dispatchProgress
          ? progressEvent => {
              const uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total),
                10
              );
              store.dispatch(action.dispatchProgress(uploadPercentage));
            }
          : undefined
    });

    if (action.payload.method === "POST" || action.payload.method === "PUT") {
      action.payload.data = transformPayloadData(action.payload.data);
    }
    const payload = apiRequest(action.payload);
    const newAction = Object.assign({}, action, {
      payload,
      auth: undefined,
      dispatchProgress: undefined
    });
    resolve(next(newAction));
  });
};

const transformPayloadData = data => {
  if (!isFormData(data)) {
    const replacer = (key, value) => {
      if (value === undefined) {
        return;
      }
      if (
        typeof value === "number" ||
        typeof value === "boolean" ||
        (value && value.length !== 0)
      ) {
        return value;
      }
      return null;
    };
    return JSON.parse(JSON.stringify(data, replacer));
  }
  return data;
};

const isFormData = data => {
  return typeof FormData !== "undefined" && data instanceof FormData;
};
export default fetchApiMiddleware;
