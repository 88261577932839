import React from "react";
import { Form, Select, Text } from "react-form";

export default ({ onSearchFormSubmit, placeholder = "Find by", options }) => {
  return (
    <Form
      onSubmit={onSearchFormSubmit}
      defaultValues={{ search_by: options?.[0]?.value }}
    >
      {formApi => (
        <form method="post" onSubmit={formApi.submitForm}>
          <p className="control is-fullwidth is-hidden-tablet">
            <span className="select is-fullwidth">
              <Select field="search_by" placeholder=" " options={options} />
            </span>
          </p>
          <div className="field has-addons">
            <p className="control is-hidden-mobile">
              <span className="select">
                <Select field="search_by" placeholder=" " options={options} />
              </span>
            </p>
            <p className="control is-expanded">
              <Text
                field="search_term"
                placeholder={placeholder}
                className="input"
              />
            </p>
            <p className="control">
              <button type="submit" className="button is-info">
                Search
              </button>
            </p>
          </div>
        </form>
      )}
    </Form>
  );
};
