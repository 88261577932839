import React, { forwardRef } from "react";
import { Marker } from "react-leaflet";
import L from "leaflet";
import iconShadow from "./markers/marker-shadow.png";
import defaultIcon from "./markers/marker.png";
import defaultIconRetina from "./markers/marker-2x.png";

const DefaultMarker = L.icon({
  iconUrl: defaultIcon,
  iconRetinaUrl: defaultIconRetina,
  shadowUrl: iconShadow,
});

export default forwardRef(function DraggableMarker(
  { eventHandlers, center },
  ref
) {
  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={center}
      ref={ref}
      icon={DefaultMarker}
    />
  );
});
